import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { getMultiValuesFromString } from 'utils/sonraiUtils'

import { QUERY_FILTER_OP } from 'appConstants'

const styles = {
  regex: {
    fontFamily: 'monospace',
    fontStyle: 'normal',
    backgroundColor: '#eee',
    display: 'block',
    overflow: 'auto',
    whiteSpace: 'pre',
  },
}

export class StringDisplay extends React.Component {
  formatArgumentValue = () => {
    const rawValue = this.getArgVal().get('value')
    if (rawValue === null) {
      return 'null'
    }

    if (
      this.getArgVal().get('op') === QUERY_FILTER_OP.IN_LIST ||
      this.getArgVal().get('op') === QUERY_FILTER_OP.NOT_IN_LIST
    ) {
      return this.getArgVal()
        .get('value')
        .replace(/\n/g, ',')
        .replace(',', ', ')
    }

    if (this.getArgVal().get('op') === QUERY_FILTER_OP.REGEX) {
      return <span style={styles.regex}>{rawValue}</span>
    }

    if (this.getArgVal().get('noCsv')) {
      return `"${rawValue}"`
    }

    const parsed = getMultiValuesFromString(rawValue)

    if (parsed.length > 1) {
      return parsed.map((val, index) => {
        if (index !== parsed.length - 1) {
          return <span>&quot;{val}&quot; OR </span>
        } else {
          return <span>&quot;{val}&quot;</span>
        }
      })
    } else {
      return `"${rawValue}"`
    }
  }

  getArgVal = () => {
    return this.props.arg.first()
  }

  render() {
    const argVals = this.formatArgumentValue()

    return (
      <span>
        {argVals}
        {this.getArgVal().get('caseSensitive') && ' (case sensitive)'}
      </span>
    )
  }
}

StringDisplay.propTypes = {
  arg: ImmutablePropTypes.mapOf(
    ImmutablePropTypes.contains({
      id: PropTypes.string.isRequired,
      value: PropTypes.any,
      op: PropTypes.string,
    })
  ),
}

export default StringDisplay
