import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Map, isImmutable } from 'immutable'
import _ from 'lodash'

import CloudAccount from 'components/CloudAccount'
import { selectAccounts } from 'containers/SonraiData/selectors'
import StringDisplay from './StringDisplay'

const styles = {
  badgeStyle: {
    fontStyle: 'normal',
    padding: '0 0.4em',
  },
}
export class AccountDisplay extends React.Component {
  renderCloudBadge = accountId => {
    const account = this.getAccountForAccountId(accountId.toLowerCase())

    if (account && account.size > 0) {
      return (
        <CloudAccount
          hasTooltip
          style={styles.badgeStyle}
          accountSrn={account.get('srn')}
        />
      )
    } else {
      return (
        <StringDisplay
          arg={this.props.arg.setIn(['account', 'value'], accountId)}
        />
      )
    }
  }

  render() {
    let rawValue = this.getArgVal().get('value')

    if (rawValue === null) {
      return 'null'
    }

    if (!Array.isArray(rawValue) && rawValue.includes(',')) {
      rawValue = rawValue.split(',')
    }

    if (_.isString(rawValue)) {
      rawValue = [rawValue]
    }

    if (this.getArgVal().get('noCsv')) {
      return this.renderCloudBadge(rawValue)
    }

    const parsed = isImmutable(rawValue) ? rawValue.toJS() : rawValue

    if (parsed.length > 1) {
      return parsed.map((val, index) => {
        if (index !== parsed.length - 1) {
          return <span>{this.renderCloudBadge(val)} OR </span>
        } else {
          return this.renderCloudBadge(val)
        }
      })
    } else {
      return this.renderCloudBadge(_.first(parsed))
    }
  }

  getArgVal = () => {
    return this.props.arg.first()
  }

  getAccountForAccountId = id => {
    return this.props.accounts.find(a => a.get('srn').endsWith(id), null, Map())
  }
}

AccountDisplay.propTypes = {
  accounts: ImmutablePropTypes.iterable.isRequired,
  arg: ImmutablePropTypes.mapOf(
    ImmutablePropTypes.contains({
      id: PropTypes.string.isRequired,
      value: PropTypes.any,
      op: PropTypes.string,
    })
  ),
}

const mapStateToProps = createStructuredSelector({
  accounts: selectAccounts,
})

export default connect(mapStateToProps)(AccountDisplay)
