import React from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap'
import { FormattedMessage } from 'react-intl'

import FormLabel from 'components/FormLabel'
import TextLink from 'components/TextLink'
import Button from 'components/Button'

import messages from './messages'

export class ModalSaveAs extends React.Component {
  state = {
    newName: '',
  }

  componentDidUpdate = oldProps => {
    if (oldProps.isOpen !== this.props.isOpen) {
      this.setState({
        newName: '',
      })
    }
  }

  updateName = e => {
    this.setState({
      newName: e.target.value,
    })
  }

  onSave = () => {
    this.props.doSave(this.state.newName)
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle}>
        <ModalHeader toggle={this.props.toggle}>Save As</ModalHeader>
        <ModalBody>
          <FormLabel required>Name</FormLabel>
          <Input value={this.state.newName} onChange={this.updateName} />
        </ModalBody>
        <ModalFooter>
          <TextLink color="primary" onClick={this.props.toggle}>
            <FormattedMessage {...messages.rename.cancelButton} />
          </TextLink>
          <Button color="primary" onClick={this.onSave}>
            Save
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

ModalSaveAs.propTypes = {
  doSave: PropTypes.func,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
}

export default ModalSaveAs
