import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { isImmutable } from 'immutable'

import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import messages from 'globalTranslations'

export class EnumDisplay extends React.Component {
  formatArgumentValue = () => {
    const argumentVal = this.getArgVal().get('value')

    if (isImmutable(argumentVal)) {
      return argumentVal
        .toJS()
        .map((val, i) => (
          <DynamicFormattedMessage
            key={`${val}-${i}`}
            defaultMessage={val}
            {...messages.enumValues[val]}
          />
        ))
    } else {
      return (
        <DynamicFormattedMessage
          defaultMessage={argumentVal}
          {...messages.enumValues[argumentVal]}
        />
      )
    }
  }

  getArgVal = () => {
    return this.props.arg.first()
  }

  render() {
    const argVals = this.formatArgumentValue()

    if (Array.isArray(argVals)) {
      return argVals.map((val, index) => {
        if (index !== argVals.length - 1) {
          return <span>&quot;{val}&quot; OR </span>
        } else {
          return <span>&quot;{val}&quot;</span>
        }
      })
    } else {
      return <span>&quot;{argVals}&quot;</span>
    }
  }
}

EnumDisplay.propTypes = {
  arg: ImmutablePropTypes.mapOf(
    ImmutablePropTypes.contains({
      id: PropTypes.string.isRequired,
      value: PropTypes.any,
      op: PropTypes.string,
    })
  ),
}

export default EnumDisplay
