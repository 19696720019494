import React from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import TextLink from 'components/TextLink'
import Button from 'components/Button'

export class ModalConfirmEditUsed extends React.Component {
  render() {
    return (
      <Modal isOpen={this.props.isOpen}>
        <ModalHeader toggle={this.props.toggle}>Confirm overwrite</ModalHeader>
        <ModalBody>
          The search you are trying to save has been modified by an other user.
          Continuing will over write their changes.
        </ModalBody>
        <ModalFooter>
          <TextLink color="secondary" onClick={this.props.toggle}>
            Cancel
          </TextLink>
          <Button color="primary" onClick={this.props.doSave}>
            Continue and Overwrite
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

ModalConfirmEditUsed.propTypes = {
  doSave: PropTypes.func,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
}

export default ModalConfirmEditUsed
