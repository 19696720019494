import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import _ from 'lodash'

import { flattenData, exists } from 'utils/sonraiUtils'
import BorderedCard from 'components/BorderedCard'

import ResultTableWithActions from './ResultTableWithActions'

const styles = {
  container: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    gridTemplateAreas: '"header" "table"',
    height: '100%',
  },
  headerContainer: {
    gridArea: 'header',
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    marginBottom: '5px',
  },
  header: {
    margin: 0,
  },
}

class FlattenedResults extends React.PureComponent {
  onClickFlattenedNodeView = clickedRowData => {
    const rootDataType = this.props.results
      .first()
      .get('items')
      .first()
      .get('__typename')

    const rowRootDataSrn = clickedRowData[`${rootDataType}_srn`]

    if (rowRootDataSrn) {
      this.props.handleRowDoubleClick(rowRootDataSrn, rootDataType)
    }
  }

  /**
   * Try to find the most deeply nested field in the query that has
   * effectivePermission
   */
  findMostDeeplyNestedWithEP = () => {
    let parentField
    let fieldsWithEP = []
    const query = this.props.query.toJS()

    // this code block does 3 things:
    // 1. build the list of fields with EP
    // 2. link the parents to the children as an array called .children = []
    // 3. identify the parent field in the query
    Object.values(query).forEach(field => {
      // if the field has EP, add to list
      if (field.effectivePermissions) {
        fieldsWithEP.push(field)
      }

      if (!field.parentId) {
        parentField = field // we found the parent
      } else {
        const parent = query[field.parentId]
        if (!parent) {
          // child has no parent?? - this is error
          // eslint-disable-next-line no-console
          console.warn(
            'invalid query, field ' + field + ' had non-existant parent id'
          )
          return null
        } else {
          // set container for children on parent
          if (!parent.children) {
            parent.children = []
          }
          // add child to parent
          parent.children.push(field)
        }
      }
    })

    if (fieldsWithEP.length === 0) {
      // eslint-disable-next-line no-console
      console.warn('we tried to find EP on a query w/out EP')
      return null
    }

    if (!parentField) {
      // eslint-disable-next-line
      console.warn('could not find parent field in query', query)
      return null
    }
    parentField.depth = 0
    let children = parentField.children || []
    let depth = 1

    // prepare to iterate over all the children and assign them a depth
    // field, which will be used to find deepest EP field after
    while (children.length > 0) {
      let innerChildren = []
      children.forEach(child => {
        child.depth = depth
        if (child.children) {
          child.children.forEach(innerChild => innerChildren.push(innerChild))
        }
      })
      depth++
      children = innerChildren
    }

    // now seach for the deepest EP field
    let maxDepthEPField = null
    fieldsWithEP.forEach(field => {
      if (maxDepthEPField == null) {
        maxDepthEPField = field
      }
      if (field.depth > maxDepthEPField.depth) {
        maxDepthEPField = field
      }
    })

    return maxDepthEPField
  }

  /**
   * flatten the data and then move all the effective permission columns
   * to the right of the most deeply nested field that has an EP card attached
   * to it.
   *
   * It relys on the flattening code, which has a bug CRC-1101, which will
   * only return EP columns for the most deeply nested field with EP.
   *
   * Because of that, the logic in this method will not work correctly under
   * two circumstances:
   *
   * 1. if more than one field in the query has effective perms
   * 2. if the type with EP is present more than once in the query
   *    - i.e. Group (w/ EP) -> hasMember Identity -> isMemberOf Group
   */
  getItemsForFlattenedEffectivePermTable = () => {
    const items = flattenData(this.props.results.toJS())
    const row1 = items[0]
    if (!row1) {
      return items // if there's no rows don't do anything
    }

    const maxDepthEPField = this.findMostDeeplyNestedWithEP()
    if (maxDepthEPField == null) {
      // error must have happened .. don't try to reorder columns
      return items
    }
    const epParentType = maxDepthEPField.definition.type.name.replace(
      'EdgeRelation',
      ''
    )

    // want to insert the EP keys right after the parent keys
    const allKeys = Object.keys(row1)
    const parentKeys = allKeys
      .filter(k => k.startsWith(epParentType))
      .filter(k => !k.endsWith('__typename'))
    const lastParentKey = _.last(parentKeys)
    const epKeys = allKeys.filter(k => k.startsWith('EffectivePermission'))

    // now insert them
    const keys = allKeys.filter(k => !k.startsWith('EffectivePermission'))
    const insertAtBase = keys.indexOf(lastParentKey)
    for (var i = 0; i < epKeys.length; i++) {
      keys.splice(insertAtBase + i, 0, epKeys[i])
    }

    // now we have a list of the fields in correct order, map our items to
    // the correct order
    return items.map(item => _.pick(item, keys))
  }

  hasEffectivePermissionBlock = () => {
    const hasEffectivePermissionBlock = exists(
      this.props.query.find(item => item.get('effectivePermissions'))
    )

    return hasEffectivePermissionBlock
  }

  getItemsForTable = () => {
    const { results } = this.props

    if (this.hasEffectivePermissionBlock()) {
      return this.getItemsForFlattenedEffectivePermTable()
    }

    return results.toJS()
  }

  render() {
    const items = this.getItemsForTable()

    return (
      <BorderedCard style={styles.container}>
        <div style={styles.headerContainer}>
          <div>
            <h4 style={styles.header}>Results</h4>
          </div>
        </div>

        <ResultTableWithActions
          data={items}
          onClickFlattenedNodeView={this.onClickFlattenedNodeView}
          onLayoutChange={this.props.saveLayout}
          options={this.props.layout.toJS()}
          flatten={true}
          exportFileName={this.props.exportFileName}
          query={this.props.query}
        />
      </BorderedCard>
    )
  }
}

FlattenedResults.propTypes = {
  exportFileName: PropTypes.string,
  handleRowDoubleClick: PropTypes.func.isRequired,
  layout: ImmutablePropTypes.contains({
    gridState: ImmutablePropTypes.map,
  }).isRequired,
  query: ImmutablePropTypes.mapOf(
    ImmutablePropTypes.contains({
      definition: ImmutablePropTypes.contains({
        name: PropTypes.string.isRequired,
        type: ImmutablePropTypes.contains({
          name: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
      parentId: PropTypes.string,
    })
  ).isRequired,
  results: ImmutablePropTypes.map.isRequired,
  saveLayout: PropTypes.func.isRequired,
}

export default FlattenedResults
