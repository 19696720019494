import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Badge } from 'reactstrap'

import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import FilterDisplay from './FilterDisplay'

class FilterDisplayGroup extends React.Component {
  constructor(props) {
    super(props)

    this.styles = {
      container: {
        marginBottom: '0.5em',
      },
      categoryLabel: {
        color: props.theme.neutralDark,
      },
      categoryTitle: {
        marginBottom: '0.3em',
      },
      categoryBadge: {
        width: '30px',
      },
    }
  }
  renderTextDescription = args => {
    return args.map(arg => {
      return (
        <FilterDisplay
          key={arg.first().get('id')}
          onSubmit={this.props.onSubmit}
          types={this.props.types}
          arg={arg}
          removeArgument={this.props.removeArgument}
          card={this.props.field}
          opGroup={this.props.opGroup}
          deleteFilter={this.props.deleteFilter}
          moveFilter={this.props.moveFilter}
        />
      )
    })
  }

  render() {
    return (
      <div style={this.styles.container}>
        <div style={this.styles.categoryTitle}>
          <Badge style={this.styles.categoryBadge}>
            {this.props.badgeLabel}
          </Badge>{' '}
          - <span style={this.styles.categoryLabel}>{this.props.label}</span>
        </div>
        <div>{this.renderTextDescription(this.props.args)}</div>
      </div>
    )
  }
}

FilterDisplayGroup.propTypes = {
  args: ImmutablePropTypes.list,
  deleteFilter: PropTypes.func.isRequired,
  badgeLabel: PropTypes.string,
  onSubmit: PropTypes.func,
  field: ImmutablePropTypes.contains({
    arguments: ImmutablePropTypes.map,
    id: PropTypes.string.isRequired,
    definition: ImmutablePropTypes.contains({
      name: PropTypes.string,
      type: ImmutablePropTypes.contains({
        name: PropTypes.string,
      }).isRequired,
      args: ImmutablePropTypes.list,
    }),
  }).isRequired,
  label: PropTypes.string,
  moveFilter: PropTypes.func.isRequired,
  opGroup: PropTypes.string,
  removeArgument: PropTypes.func,
  theme: themeShape,
  types: ImmutablePropTypes.contains({
    inputFields: ImmutablePropTypes.listOf(
      ImmutablePropTypes.contains({
        name: PropTypes.string,
        type: ImmutablePropTypes.contains({
          name: PropTypes.string,
        }),
      })
    ),
  }).isRequired,
}

export default themeable(FilterDisplayGroup)
