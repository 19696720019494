import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { createStructuredSelector } from 'reselect'
import { compose } from 'redux'
import { List } from 'immutable'
import { OrganizationChart } from 'primereact/organizationchart'

import { selectQueryTypes } from 'containers/SonraiData/selectors'
import {
  selectRootQuery,
  selectQueryStatements,
} from 'containers/SearchQuery/selectors'

import RelationCard from './RelationCard'
import SearchCard from './SearchCard'
import './orgchartstyles.css'

const styles = {
  builderWrapper: {
    padding: '2em 0 1em 0',
    overflow: 'auto',
  },
}

export class SearchBuilder extends React.Component {
  nodeTemplate = ({ queryField, fieldType, parentField, fieldId }) => {
    if (queryField) {
      return (
        <div>
          <SearchCard field={queryField} queryTypes={this.props.queryTypes} />
        </div>
      )
    }

    return (
      <RelationCard
        parentField={parentField}
        fieldType={fieldType}
        fieldId={fieldId}
      />
    )
  }

  getTree = queryNode => {
    const { queryStatements } = this.props

    return {
      expanded: true,
      queryField: queryNode,
      children: queryNode
        .get('selectionSet', List())
        .toJS()
        .map(childId => {
          const fieldType = queryStatements.getIn([
            childId,
            'definition',
            'name',
          ])

          return {
            fieldType,
            parentField: queryNode,
            fieldId: childId,
            expanded: true,
            children: [this.getTree(queryStatements.get(childId))],
          }
        }),
    }
  }

  render() {
    const orgChartData = [this.getTree(this.props.rootQuery)]

    return (
      <div
        className="sonrai-search-builder-orgchart"
        style={styles.builderWrapper}
      >
        <OrganizationChart
          value={orgChartData}
          nodeTemplate={this.nodeTemplate}
        />
      </div>
    )
  }
}

SearchBuilder.propTypes = {
  queryStatements: ImmutablePropTypes.iterable,
  queryTypes: ImmutablePropTypes.contains({
    fields: ImmutablePropTypes.listOf(
      ImmutablePropTypes.contains({
        name: PropTypes.string,
      })
    ),
  }).isRequired,
  rootQuery: ImmutablePropTypes.map.isRequired,
}

const mapStateToProps = createStructuredSelector({
  queryTypes: selectQueryTypes,
  rootQuery: selectRootQuery,
  queryStatements: selectQueryStatements,
})

const withConnect = connect(mapStateToProps)

export default withRouter(compose(withConnect)(SearchBuilder))
