import React from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { FormattedMessage } from 'react-intl'

import TextLink from 'components/TextLink'
import Button from 'components/Button'

import messages from './messages'

export class ModalConfirmEditUsed extends React.Component {
  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle}>
        <ModalHeader toggle={this.props.toggle}>
          <FormattedMessage {...messages.confirmSave.title} />
        </ModalHeader>
        <ModalBody>
          <p>
            <FormattedMessage {...messages.confirmSave.intro} />
            <strong>
              <FormattedMessage {...messages.confirmSave.strong} />
            </strong>
          </p>
          <p>
            <FormattedMessage {...messages.confirmSave.confirm} />
          </p>
        </ModalBody>
        <ModalFooter>
          <TextLink color="primary" onClick={this.props.toggle}>
            <FormattedMessage {...messages.confirmSave.cancelButton} />
          </TextLink>
          <Button color="primary" onClick={this.props.doSave}>
            <FormattedMessage {...messages.confirmSave.saveButton} />
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

ModalConfirmEditUsed.propTypes = {
  doSave: PropTypes.func,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
}

export default ModalConfirmEditUsed
