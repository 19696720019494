import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { isImmutable } from 'immutable'
import _ from 'lodash'
import SwimlaneDisplay from 'components/SwimlaneDisplay'
import StringDisplay from 'components/FilterStringDisplay/StringDisplay'
class SwimlaneFilterDisplay extends React.Component {
  getArgVal = () => {
    return this.props.arg.first()
  }

  render() {
    let rawValue = this.getArgVal().get('value')

    if (isImmutable(rawValue)) {
      rawValue = rawValue.toJS()
    }

    if (rawValue === null || rawValue === 'null') {
      return 'null'
    }

    if (Array.isArray(rawValue) && rawValue.length > 1) {
      return rawValue.map((val, index) => {
        if (index !== rawValue.length - 1) {
          return (
            <span>
              <SwimlaneDisplay key={index} srn={val} hasLink /> OR{' '}
            </span>
          )
        } else {
          return <SwimlaneDisplay key={index} srn={val} hasLink />
        }
      })
    } else if (_.isString(rawValue)) {
      return <StringDisplay arg={this.props.arg} />
    } else {
      return (
        <SwimlaneDisplay
          srn={Array.isArray(rawValue) ? _.first(rawValue) : rawValue}
          hasLink
        />
      )
    }
  }
}

SwimlaneFilterDisplay.propTypes = {
  arg: ImmutablePropTypes.mapOf(
    ImmutablePropTypes.contains({
      id: PropTypes.string.isRequired,
      value: PropTypes.any,
      op: PropTypes.string,
    })
  ),
}

export default SwimlaneFilterDisplay
