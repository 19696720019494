import React from 'react'
import reverse from 'lodash/reverse'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import _ from 'lodash'
import { Map, List, OrderedMap } from 'immutable'
import { getAliasForField } from 'query-builder'

import { useFlags } from 'containers/FeatureFlags'
import BorderedCard from 'components/BorderedCard'
import SonraiCountDisplay from 'components/SonraiCountDisplay'

import ResultTableWithActions from './ResultTableWithActions'

const styles = {
  container: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    gridTemplateAreas: '"header" "table"',
    height: '100%',
  },
  headerContainer: {
    gridArea: 'header',
    display: 'grid',
    gridTemplateColumns: '1fr auto',
  },
  header: {
    margin: 0,
  },
  resultsGrid: {
    display: 'grid',
    gridRowGap: 16,
    gridArea: 'content',
    overflowY: 'auto',
  },
}

//eslint-disable-next-line
const SonraiCountDisplayWithFlag = ({ itemCount, items }) => {
  const flags = useFlags()
  const hasSearchLimit = _.get(flags, ['searchLimit'])

  return (
    <SonraiCountDisplay
      hasSearchLimit={hasSearchLimit}
      count={itemCount === undefined ? items.size : itemCount}
    />
  )
}

class MultiTableResults extends React.PureComponent {
  //TODO: controls to modify which result cards are visible
  removeResultView = resultView => {
    this.props.removeResultView({
      fromFieldId: resultView.get('fieldId'),
      id: resultView.get('id'),
    })
  }

  getCountOfItems = field => {
    const { results, rootDefinitionName } = this.props
    const path = this.getPathToResultSet(field)
    path.push('count')
    const itemCount = results
      .get(rootDefinitionName, Map())
      .get('items', List()).size
    return itemCount || results.get(rootDefinitionName, Map()).getIn(path)
  }

  getItemsForTable = field => {
    const { results, rootDefinitionName } = this.props

    const path = this.getPathToResultSet(field)
    path.push('items')

    const orderedResults = results
      .get(rootDefinitionName, OrderedMap())
      .getIn(path, List())

    return orderedResults
  }

  hasSiblingOfSameType = (parent, field) => {
    if (!field.get('parentId')) {
      return false
    }

    const entityName = field.getIn(['definition', 'name'])
    const matchingSibling = parent
      .get('selectionSet', List())
      .filterNot(childId => childId === field.get('id'))
      .find(siblingId => {
        const sibling = this.props.query.get(siblingId)
        if (!sibling) {
          return false
        }

        const siblingEntityName = sibling.getIn(['definition', 'name'])
        return siblingEntityName === entityName
      })

    return !!matchingSibling
  }

  getPathToResultSet = field => {
    const { query } = this.props

    let path = []

    let currentField = field
    while (currentField && currentField.get('parentId')) {
      const relationName = currentField.getIn(['definition', 'name'], '')
      const parent = query.get(currentField.get('parentId'))

      if (this.hasSiblingOfSameType(parent, currentField)) {
        //value is aliased
        path.push(getAliasForField(currentField.get('id'), relationName))
      } else {
        path.push(relationName)
      }
      path.push('aggregation')
      currentField = query.get(currentField.get('parentId'))
    }
    path = reverse(path)

    return path
  }

  renderCard = resultView => {
    const field = this.props.query.get(resultView.get('fieldId'), Map())
    const items = this.getItemsForTable(field)
    const itemCount = this.getCountOfItems(field)
    return (
      <BorderedCard style={styles.container} key={resultView.get('id')}>
        <div style={styles.headerContainer}>
          <div>
            <h4 style={styles.header}>
              {field.get('name', 'Results')}
              &nbsp;(
              <SonraiCountDisplayWithFlag items={items} itemCount={itemCount} />
              )
            </h4>
          </div>
        </div>

        <div>
          <ResultTableWithActions
            data={items.toJS()}
            field={field}
            onClickNodeView={this.props.handleRowDoubleClick}
            onLayoutChange={this.props.saveLayout.bind(
              null,
              resultView.get('id')
            )}
            options={resultView.toJS()}
            flatten={false}
            exportFileName={`${this.props.exportFileName} - ${field.get(
              'name'
            )} `}
            query={this.props.query}
            pageSize={this.props.queryResultCards.size > 1 ? 4 : undefined}
          />
        </div>
      </BorderedCard>
    )
  }

  render() {
    const gridTemplateRows =
      this.props.queryResultCards.size > 1
        ? _.repeat(`450px `, this.props.queryResultCards.size)
        : '1fr'
    const style = { gridTemplateRows, ...styles.resultsGrid }

    return (
      <div className="query-builder-results-container" style={style}>
        {this.props.queryResultCards.map(this.renderCard)}
      </div>
    )
  }
}

MultiTableResults.propTypes = {
  exportFileName: PropTypes.string,
  handleRowDoubleClick: PropTypes.func.isRequired,
  query: ImmutablePropTypes.mapOf(
    ImmutablePropTypes.contains({
      definition: ImmutablePropTypes.contains({
        name: PropTypes.string.isRequired,
        type: ImmutablePropTypes.contains({
          name: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
      parentId: PropTypes.string,
    })
  ).isRequired,
  results: ImmutablePropTypes.map.isRequired,
  queryResultCards: ImmutablePropTypes.list.isRequired,
  removeResultView: PropTypes.func.isRequired,
  rootDefinitionName: PropTypes.string.isRequired,
  saveLayout: PropTypes.func.isRequired,
}

export default MultiTableResults
