import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List, Map } from 'immutable'

import BorderlessButton from 'components/BorderlessButton'
import Icon from 'components/Icon'
import Popover, { PopoverBody, PopoverAnchor } from 'components/Popover'
import Hoverable from 'components/Hoverable'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import FilterStringDisplay from 'components/FilterStringDisplay'
import { Badge } from 'reactstrap'

import EditFilter from './EditFilter'

const styles = {
  filterFieldContainer: {
    padding: '0.3em',
    minHeight: '30px',
  },
  filterFieldEntry: {
    display: 'grid',
    gridTemplateColumns: '30px 1fr auto',
    gridColumnGap: '0.5em',
    marginLeft: '0.5em',
  },
  actions: {
    display: 'flex',
  },
  action: {
    margin: '0 0.4em',
    fontSize: '1em',
    height: '20px',
    overflow: 'hidden',
  },
}

export class FilterDisplay extends React.Component {
  state = {
    showEditPopover: false,
  }

  toggleEditPopover = () => {
    this.setState(currentState => ({
      showEditPopover: !currentState.showEditPopover,
    }))
  }

  onEditButtonToggle = newVisibility => {
    this.setState({
      showEditPopover: newVisibility,
    })
  }

  handleClearFilter = () => {
    this.setState({
      showEditPopover: false,
    })

    this.props.removeArgument({
      fromFieldId: this.props.card.get('id'),
      id: this.getArgVal().get('id'),
    })
  }

  onSubmit = vals => {
    this.setState({
      showEditPopover: false,
    })

    this.props.onSubmit(vals)
  }

  getArgVal = () => {
    return this.props.arg.first()
  }

  getArgName = () => {
    return this.props.arg.keySeq().first()
  }

  onMoveGroup = () => {
    this.props.moveFilter(this.getArgVal().get('id'))
  }

  onDelete = () => {
    this.props.deleteFilter(this.getArgVal().get('id'))
  }

  render() {
    const fieldName = this.getArgName()
    const whereArgType = this.props.card
      .getIn(['definition', 'args'], List())
      .find(arg => arg.get('name') === 'where', null, List())
    const whereArgDefinition = this.props.types.get(
      whereArgType.getIn(['type', 'name']),
      Map()
    )

    const supportedWhereFilters = whereArgDefinition
      .get('inputFields', List())
      .map(def => def.get('name'))

    const supportsOr = supportedWhereFilters.includes('or')
    return (
      <Hoverable
        style={styles.filterFieldContainer}
        hoverStyle={{
          backgroundColor: this.props.theme.highlight,
        }}
        renderContent={({ hovered, endHover }) => (
          <div style={styles.filterFieldEntry}>
            <div>
              {this.props.arg.getIn([fieldName, 'notFilter'], false) ? (
                <Badge color="danger">NOT</Badge>
              ) : (
                <div>&nbsp;</div>
              )}
            </div>
            <FilterStringDisplay
              arg={this.props.arg}
              card={this.props.card}
              types={this.props.types}
            />
            <div style={styles.actions}>
              {hovered && (
                <Fragment>
                  {supportsOr && (
                    <BorderlessButton
                      onClick={this.onMoveGroup}
                      color="primary"
                      style={styles.action}
                      title={
                        this.props.opGroup === 'and'
                          ? 'Move to OR group'
                          : 'Move to AND group'
                      }
                    >
                      {this.props.opGroup === 'and' ? (
                        <span>
                          <Icon fa name="expandy-down" />
                          &nbsp;OR
                        </span>
                      ) : (
                        <span>
                          <Icon fa name="collapsy" />
                          &nbsp;AND
                        </span>
                      )}
                    </BorderlessButton>
                  )}

                  <BorderlessButton
                    onClick={this.onDelete}
                    color="primary"
                    style={styles.action}
                    title="Remove"
                  >
                    <Icon fa name="trash" transform="shrink-2" />
                  </BorderlessButton>
                </Fragment>
              )}

              <Popover
                isOpen={this.state.showEditPopover}
                position="right"
                onToggle={this.onEditButtonToggle}
              >
                <PopoverAnchor>
                  <BorderlessButton
                    color="primary"
                    onClick={this.toggleEditPopover}
                    style={styles.action}
                    title="Edit"
                  >
                    {hovered || this.state.showEditPopover ? (
                      <Icon fa name="pencil-alt" />
                    ) : (
                      <div style={{ width: '30px' }} />
                    )}
                  </BorderlessButton>
                </PopoverAnchor>
                <PopoverBody>
                  <EditFilter
                    endHover={endHover}
                    onSubmit={this.onSubmit}
                    types={this.props.types}
                    field={this.props.card}
                    removeArgument={this.handleClearFilter}
                    value={this.getArgVal()}
                    propertyName={fieldName}
                    toggle={this.toggleEditPopover}
                    opGroup={this.props.opGroup}
                  />
                </PopoverBody>
              </Popover>
            </div>
          </div>
        )}
      />
    )
  }
}

FilterDisplay.propTypes = {
  arg: ImmutablePropTypes.contains({
    createdDate: ImmutablePropTypes.contains({
      caseSensitive: PropTypes.bool,
      dateOffset: PropTypes.object,
      id: PropTypes.string,
      op: PropTypes.string,
      value: PropTypes.string,
    }),
  }),
  card: ImmutablePropTypes.contains({
    id: PropTypes.string.isRequired,
    definition: ImmutablePropTypes.contains({
      name: PropTypes.string,
      type: ImmutablePropTypes.contains({
        name: PropTypes.string,
      }).isRequired,
      args: ImmutablePropTypes.list,
    }),
  }),
  deleteFilter: PropTypes.func.isRequired,
  moveFilter: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  opGroup: PropTypes.string,
  removeArgument: PropTypes.func,
  theme: themeShape,
  types: ImmutablePropTypes.iterable.isRequired,
}

export default themeable(FilterDisplay)
