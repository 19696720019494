import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import BorderedCard from 'components/BorderedCard'
import BorderlessButton from 'components/BorderlessButton'
import TextLink from 'components/TextLink'
import Icon from 'components/Icon'
import Popover, { PopoverBody, PopoverAnchor } from 'components/Popover'
import FilterStringDisplay from 'components/FilterStringDisplay'
import Hoverable from 'components/Hoverable'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import EditFilter from './EditFilter'
import { fromJS, Map } from 'immutable'
class EffectivePermissionCard extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      addingPermission: false,
      editingPermission: Map(),
    }

    this.styles = {
      header: {
        fontSize: '1em',
        display: 'grid',
        gridTemplateColumns: '1fr auto',
      },
      container: {
        marginLeft: '5px',
        marginRight: '5px',
      },
      epContainer: {
        display: 'grid',
        gridTemplateColumns: '200px 1fr 1fr',
        marginLeft: '5px',
        marginRight: '5px',
      },
      textContainer: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
      },
      permissionContainer: {
        textAlign: 'left',
      },
      permissionText: {},
      connectGang: {
        fontSize: '0.8em',
        fontStyle: 'italic',
      },
      filterFieldContainer: {
        padding: '0.3em',
        minHeight: '30px',
      },
      filterFieldEntry: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        gridColumnGap: '0.5em',
      },
      actions: {
        display: 'flex',
      },
      action: {
        margin: '0 0.3em',
        fontSize: '1em',
        height: '20px',
        overflow: 'hidden',
      },
    }
  }

  setEditingPermission = id => {
    this.setState(prevState => {
      return { editingPermission: prevState.editingPermission.set(id, true) }
    })
  }

  setNotEditingPermission = id => {
    this.setState(prevState => {
      return { editingPermission: prevState.editingPermission.delete(id) }
    })
  }

  togglePopover = () => {
    this.setState(prevState => {
      return { addingPermission: !prevState.addingPermission }
    })
  }

  onAddPermission = opts => {
    const propName = Object.keys(opts.value)[0]
    const propVals = opts.value[propName]
    this.props.onAddPermission({
      qepId: this.props.qepId,
      type: this.props.type,
      permission: {
        operation: propVals.op.toLowerCase(),
        value: propVals.value,
        opType: opts.type.def.type.name.replace('Operator', ''),
        arg: opts.value,
        type: opts.type,
      },
    })

    this.setState({
      addingPermission: false,
    })
  }

  onUpdatePermission = params => {
    this.props.updateEffectivePermission({
      type: this.props.type,
      qepId: this.props.qepId,
      fieldId: this.props.field.get('id'),
      opType: params.opType,
      value: params.value,
      id: params.id,
    })
    this.setNotEditingPermission(params.id)
  }

  onRemovePermission = pId => {
    this.props.onRemovePermission({
      qepId: this.props.qepId,
      type: this.props.type,
      pId: pId,
    })
    this.setNotEditingPermission(pId)
  }

  renderPermissions = scalerFieldsFiltered => {
    return (
      <div>
        <div style={this.styles.permissionContainer}>
          {this.props.permissions.map((permission, index) => {
            const fieldType = Object.keys(permission.arg)[0]
            let valProps = {
              value: permission.value,
            }
            if (permission.arg[fieldType].values) {
              valProps.values = permission.arg[fieldType].values
              valProps.value = ''
            }
            if (permission.arg[fieldType].dateOffset) {
              valProps.dateOffset = permission.arg[fieldType].dateOffset
            }
            const value = fromJS({
              id: permission.id,
              op: permission.operation.toUpperCase(),
              ...valProps,
              opType: permission.opType,
              type: permission.type,
            })

            return (
              <div key={permission.id}>
                <Hoverable
                  style={this.styles.filterFieldContainer}
                  hoverStyle={{
                    backgroundColor: this.props.theme.highlight,
                  }}
                  renderContent={({ hovered }) => (
                    <div style={this.styles.filterFieldEntry}>
                      <FilterStringDisplay
                        filterFieldType="EffectivePermissionFilterType"
                        types={this.props.types}
                        arg={fromJS(permission.arg)}
                        card={Map({ id: this.props.qepId })}
                        moreStyles={{ whiteSpace: 'nowrap' }}
                      />
                      <div style={this.styles.actions}>
                        {hovered && (
                          <BorderlessButton
                            style={this.styles.action}
                            onClick={() => {
                              this.onRemovePermission(permission.id)
                            }}
                          >
                            <Icon fa name="trash" transform="shrink-2" />
                          </BorderlessButton>
                        )}
                        <Popover
                          isOpen={this.state.editingPermission.get(
                            permission.id
                          )}
                          position="top-right-start"
                        >
                          <PopoverAnchor>
                            <BorderlessButton
                              style={this.styles.action}
                              onClick={() => {
                                this.setEditingPermission(permission.id)
                              }}
                              color="primary"
                            >
                              {hovered ||
                              this.state.editingPermission.get(
                                permission.id
                              ) ? (
                                <Icon fa name="pencil-alt" />
                              ) : (
                                <div style={{ width: '30px' }} />
                              )}
                            </BorderlessButton>
                          </PopoverAnchor>
                          <PopoverBody>
                            <EditFilter
                              types={this.props.types}
                              filterFieldType={'EffectivePermissionFilterType'}
                              value={value}
                              propertyName={permission.type.value}
                              field={this.props.field}
                              onSubmit={this.onUpdatePermission}
                              toggle={() => {
                                this.setNotEditingPermission(permission.id)
                              }}
                              scalerFieldsFiltered={scalerFieldsFiltered}
                            />
                          </PopoverBody>
                        </Popover>
                      </div>
                    </div>
                  )}
                />
                {index !== this.props.permissions.length - 1 && (
                  <Fragment>
                    <div style={this.styles.connectGang}>AND</div>
                  </Fragment>
                )}
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  render() {
    const filterArgType = this.props.types.get('EffectivePermissionFilterType')

    const scalerFieldsFiltered = filterArgType
      .get('inputFields')
      .filter(
        inputField =>
          inputField.getIn(['type', 'name']) !== null &&
          inputField.get('name') !== 'tags' &&
          inputField.getIn(['type', 'name']).endsWith('Operator')
      )

    return (
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr auto',
          marginBottom: '0.5em',
        }}
      >
        <BorderedCard style={this.styles.container}>
          <div>
            <div style={this.styles.epContainer}>
              <Popover
                isOpen={this.state.addingPermission}
                position="right-start"
              >
                <PopoverAnchor>
                  <TextLink onClick={this.togglePopover} color="primary">
                    <Icon name="plus" fa />
                    &nbsp;Add Filter
                  </TextLink>
                </PopoverAnchor>
                <PopoverBody>
                  <EditFilter
                    addNew
                    types={this.props.types}
                    filterFieldType={'EffectivePermissionFilterType'}
                    field={Map({ id: this.props.qepId })}
                    onSubmit={this.onAddPermission}
                    toggle={this.togglePopover}
                    scalerFieldsFiltered={scalerFieldsFiltered}
                  />
                </PopoverBody>
              </Popover>
            </div>

            <div style={this.styles.header} />
            <div>{this.renderPermissions(scalerFieldsFiltered)}</div>
          </div>
        </BorderedCard>
        <div
          style={{
            paddingLeft: '0.5em',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <BorderlessButton
            onClick={this.props.removeCard}
            style={{ fontSize: '0.8em' }}
          >
            <Icon name="times" fa />
          </BorderlessButton>
        </div>
      </div>
    )
  }
}

EffectivePermissionCard.propTypes = {
  onAddPermission: PropTypes.func,
  onRemovePermission: PropTypes.func,
  qepId: PropTypes.string,
  type: PropTypes.string,
  types: ImmutablePropTypes.map,
  theme: themeShape,
  permissions: ImmutablePropTypes.list,
  removeCard: PropTypes.func,
  updateEffectivePermission: PropTypes.func,
  field: ImmutablePropTypes.map,
}

export default themeable(EffectivePermissionCard)
