import { createSelector } from 'reselect'
import { Map, List } from 'immutable'

export const selectQuery = state => state.get('query') || Map()

export const selectQueryStatements = createSelector(
  selectQuery,
  query => query.get('query') || Map()
)

export const selectResultViews = createSelector(
  selectQuery,
  query => query.get('resultViews') || Map()
)

export const selectSearchLimit = createSelector(selectQuery, state =>
  state.get('limit')
)

export const selectConvertedFromLegacy = createSelector(selectQuery, query =>
  query.get('convertedFromLegacy')
)

export const selectRootQuery = createSelector(selectQueryStatements, query =>
  query.find(statement => !statement.get('parentId'), null, Map())
)

const getOrderedResultsViews = (field, queryStatements, resultViews) => {
  if (!field || field.isEmpty()) {
    return List()
  }

  const resultViewIds = field.get('resultViews', List())

  const currentCards = resultViewIds.map(id =>
    resultViews.get(id, Map()).set('fieldId', field.get('id'))
  )

  const childrenIds = field.get('selectionSet', List())
  const childrenCards = childrenIds.map(childId =>
    getOrderedResultsViews(
      queryStatements.get(childId, Map()),
      queryStatements,
      resultViews
    )
  )

  return currentCards.concat(childrenCards.flatten(1))
}

export const selectResultCards = createSelector(
  [selectRootQuery, selectQueryStatements, selectResultViews],
  getOrderedResultsViews
)
