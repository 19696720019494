import { fromJS, List, Map } from 'immutable'
import _ from 'lodash'
import { handleActions } from 'redux-actions'
import { deleteFieldAndChildren, RESULT_VIEW_TYPES } from 'query-builder'
import { getValidAliasName } from 'utils/sonraiUtils'
import { DEFAULT_SEARCH_LIMIT } from 'appConstants'

import { getNewUUID, newQueryResultId, getRelationArgumentName } from './utils'
import {
  ADD_ARGUMENT,
  ADD_RESULT_VIEW,
  CHANGE_ARGUMENT,
  CHANGE_FIELD_NAME,
  REMOVE_FIELD,
  INSERT_FIELD,
  REMOVE_RESULT_VIEW,
  REMOVE_ARGUMENT,
  LOAD_SAVED_QUERY,
  RESET_QUERY,
  UPDATE_RESULT_VIEW,
  SET_DEFAULT_VIEW,
  MOVE_ARGUMENT,
  CHANGE_CHILD_OPERATOR,
  ADD_PROPERTY,
  REMOVE_PROPERTY,
  ADD_COMMENT,
  REMOVE_COMMENT,
  SET_QUERY_PROPERTY_ORDER,
  SET_QUERY_COLUMN_ALIAS,
  REMOVE_QUERY_COLUMN_ALIAS,
  ADD_EFFECTIVE_PERMISSION_INCLUDE_GROUP,
  ADD_EFFECTIVE_PERMISSION_EXCLUDE_GROUP,
  REMOVE_EFFECTIVE_PERMISSION_GROUP,
  ADD_EFFECTIVE_PERMISSION,
  REMOVE_EFFECTIVE_PERMISSION,
  REMOVE_EFFECTIVE_PERMISSION_BLOCK,
  SET_EFFECTIVE_PERMISSION_PROPERTY_ORDER,
  ADD_EP_DISPLAY_COLUMN,
  REMOVE_EP_DISPLAY_COLUMN,
  SET_IS_DISTINCT_EP,
  UPDATE_EFFECTIVE_PERMISSION,
  SET_EP_DISPLAY_COLUMN_ALIAS,
  REMOVE_EP_COLUMN_ALIAS,
  SET_SEARCH_LIMIT,
  CLEAR_SEARCH_LIMIT,
} from './constants'

export const initialState = fromJS({
  query: {},
  resultViews: {},
  convertedFromLegacy: false,
  limit: DEFAULT_SEARCH_LIMIT,
})

const queryReducer = handleActions(
  {
    [ADD_ARGUMENT]: (state, { payload }) => {
      if (Array.isArray(payload) && !_.isEmpty(payload)) {
        const fieldId = _.first(payload).fieldId
        const opGroup = _.first(payload).opGroup
        const values = fromJS(payload.map(x => x.value))
        const args = state
          .getIn(['query', fieldId, 'arguments', opGroup], List())
          .merge(values)
        if (fieldId) {
          return state.setIn(['query', fieldId, 'arguments', opGroup], args)
        }
      }

      if (payload.opGroup) {
        return state.updateIn(
          ['query', payload.fieldId, 'arguments', payload.opGroup],
          List(),
          args => args.push(fromJS(payload.value))
        )
      } else {
        return state.setIn(
          ['query', payload.fieldId, 'arguments', payload.name],
          fromJS(payload.value)
        )
      }
    },
    [ADD_EFFECTIVE_PERMISSION_INCLUDE_GROUP]: (state, { payload }) => {
      if (!state.getIn(['query', payload.fieldId, 'effectivePermissions'])) {
        return state
          .setIn(
            ['query', payload.fieldId, 'effectivePermissions'],
            fromJS({
              include: { [getNewUUID('qep', 8)]: [] },
              exclude: {},
              isDistinct: false,
            })
          )
          .setIn(['resultViews', 'defaultView'], RESULT_VIEW_TYPES.FLATTENED)
      } else {
        return state.setIn(
          [
            'query',
            payload.fieldId,
            'effectivePermissions',
            'include',
            getNewUUID('qep', 8),
          ],
          List()
        )
      }
    },
    [ADD_EFFECTIVE_PERMISSION_EXCLUDE_GROUP]: (state, { payload }) => {
      if (!state.getIn(['query', payload.fieldId, 'effectivePermissions'])) {
        return state
          .setIn(
            ['query', payload.fieldId, 'effectivePermissions'],
            fromJS({
              exclude: { [getNewUUID('qep', 8)]: [] },
              include: {},
              isDistinct: false,
            })
          )
          .setIn(['resultViews', 'defaultView'], RESULT_VIEW_TYPES.FLATTENED)
      } else {
        return state.setIn(
          [
            'query',
            payload.fieldId,
            'effectivePermissions',
            'exclude',
            getNewUUID('qep', 8),
          ],
          List()
        )
      }
    },
    [REMOVE_EFFECTIVE_PERMISSION_BLOCK]: (state, { payload }) => {
      return state.deleteIn(['query', payload.fieldId, 'effectivePermissions'])
    },
    [REMOVE_EFFECTIVE_PERMISSION_GROUP]: (state, { payload }) => {
      return state.deleteIn([
        'query',
        payload.fieldId,
        'effectivePermissions',
        payload.type,
        payload.qepId,
      ])
    },
    [ADD_EFFECTIVE_PERMISSION]: (state, { payload }) => {
      return state.updateIn(
        [
          'query',
          payload.fieldId,
          'effectivePermissions',
          payload.type,
          payload.qepId,
        ],
        qep => {
          return qep.push(
            fromJS({ id: getNewUUID('p', 8), ...payload.permission })
          )
        }
      )
    },
    [REMOVE_EFFECTIVE_PERMISSION]: (state, { payload }) => {
      return state.updateIn(
        [
          'query',
          payload.fieldId,
          'effectivePermissions',
          payload.type,
          payload.qepId,
        ],
        qep => qep.filter(q => q.get('id') !== payload.pId)
      )
    },
    [INSERT_FIELD]: (state, { payload }) => {
      return state.setIn(['query', payload.get('id')], payload)
    },
    [ADD_PROPERTY]: (state, { payload }) => {
      if (!state.getIn(['query', payload.fieldId, 'displayFields'])) {
        return state.setIn(
          ['query', payload.fieldId, 'displayFields'],
          fromJS([payload.property])
        )
      } else {
        if (
          !state
            .getIn(['query', payload.fieldId, 'displayFields'])
            .includes(payload.property)
        ) {
          return state.updateIn(
            ['query', payload.fieldId, 'displayFields'],
            props => props.push(payload.property)
          )
        } else {
          return state
        }
      }
    },
    [REMOVE_PROPERTY]: (state, { payload }) => {
      return state.setIn(
        ['query', payload.fieldId, 'displayFields'],
        state
          .getIn(['query', payload.fieldId, 'displayFields'])
          .filter(prop => prop !== payload.property)
      )
    },
    [ADD_COMMENT]: (state, { payload }) => {
      const { fieldId, comment } = payload
      if (!state.getIn(['query', fieldId, 'comments'])) {
        return state.setIn(['query', fieldId, 'comments'], fromJS([comment]))
      } else {
        const comments = state.getIn(['query', fieldId, 'comments'])
        if (!comments.includes(comment)) {
          return state.updateIn(['query', fieldId, 'comments'], arr =>
            arr.push(comment)
          )
        } else {
          return state
        }
      }
    },
    [REMOVE_COMMENT]: (state, { payload }) => {
      const { fieldId, comment } = payload
      return state.setIn(
        ['query', fieldId, 'comments'],
        state
          .getIn(['query', fieldId, 'comments'])
          .filter(arr => arr !== comment)
      )
    },
    [ADD_RESULT_VIEW]: (state, { payload }) => {
      if (state.getIn(['query', payload.to])) {
        const newId = newQueryResultId()
        return state
          .updateIn(
            ['query', payload.to, 'resultViews'],
            (resultViews = List()) => resultViews.push(newId)
          )
          .setIn(
            ['resultViews', newId],
            fromJS({
              id: newId,
              viewType: payload.viewType,
            })
          )
      } else {
        return state
      }
    },
    [CHANGE_ARGUMENT]: (state, { payload }) => {
      if (payload.opGroup) {
        const existingArgsForOp = state.getIn(
          ['query', payload.fieldId, 'arguments', payload.opGroup],
          List()
        )

        const index = existingArgsForOp.findIndex(
          arg => arg.first().get('id') === payload.id
        )

        if (index >= 0) {
          return state.setIn(
            ['query', payload.fieldId, 'arguments', payload.opGroup, index],
            fromJS(payload.value)
          )
        }
      } else {
        return state.setIn(
          ['query', payload.fieldId, 'arguments', payload.name],
          fromJS(payload.value)
        )
      }

      return state
    },
    [CHANGE_CHILD_OPERATOR]: (state, { payload }) => {
      const field = state.getIn(['query', payload.field.id])

      if (field) {
        return state.setIn(
          ['query', payload.field.id, 'childOperator'],
          payload.op
        )
      } else return state
    },
    [CHANGE_FIELD_NAME]: (state, { payload }) => {
      const field = state.getIn(['query', payload.field.id])

      if (field) {
        return state.setIn(
          ['query', payload.field.id],
          field.set('name', payload.name)
        )
      } else return state
    },

    [LOAD_SAVED_QUERY]: (state, { payload }) => {
      const savedQuery = payload.savedSearch
      if (!savedQuery || savedQuery.isEmpty()) {
        return state
      }

      const queryMods = payload.queryMods || Map()
      const argFormatted = queryMods.map(arg => Map({ arguments: arg }))

      const finalQuery = (
        savedQuery.getIn(['query', 'fields']) || Map()
      ).mergeDeep(argFormatted)
      return state
        .set('query', finalQuery)
        .set('resultViews', savedQuery.getIn(['query', 'resultViews']) || Map())
        .set('convertedFromLegacy', payload.convertedFromLegacy)
        .set(
          'limit',
          finalQuery.first(Map()).get('limit') || DEFAULT_SEARCH_LIMIT
        )
    },
    [MOVE_ARGUMENT]: (state, { payload }) => {
      const argAndIndex = state
        .getIn(['query', payload.fieldId, 'arguments', 'and'])
        .findIndex(arg => arg.first().get('id') === payload.id)

      if (argAndIndex > -1) {
        const arg = state.getIn([
          'query',
          payload.fieldId,
          'arguments',
          'and',
          argAndIndex,
        ])

        if (arg) {
          return state
            .deleteIn([
              'query',
              payload.fieldId,
              'arguments',
              'and',
              argAndIndex,
            ])
            .updateIn(
              ['query', payload.fieldId, 'arguments', 'or'],
              List(),
              args => args.push(arg)
            )
        }
      }
      const argOrIndex = state
        .getIn(['query', payload.fieldId, 'arguments', 'or'])
        .findIndex(arg => arg.first().get('id') === payload.id)

      if (argOrIndex > -1) {
        const arg = state.getIn([
          'query',
          payload.fieldId,
          'arguments',
          'or',
          argOrIndex,
        ])

        if (arg) {
          return state
            .deleteIn(['query', payload.fieldId, 'arguments', 'or', argOrIndex])
            .updateIn(
              ['query', payload.fieldId, 'arguments', 'and'],
              List(),
              args => args.push(arg)
            )
        }
      }

      return state
    },
    [REMOVE_ARGUMENT]: (state, { payload }) => {
      const args = state.getIn(['query', payload.fromFieldId, 'arguments'])
      const orMatchIndex = args
        .get('or', List())
        .findIndex(arg => arg.first().get('id') === payload.id)

      if (orMatchIndex >= 0) {
        return state.deleteIn([
          'query',
          payload.fromFieldId,
          'arguments',
          'or',
          orMatchIndex,
        ])
      }

      const andMatchIndex = args
        .get('and', List())
        .findIndex(arg => arg.first().get('id') === payload.id)

      if (andMatchIndex >= 0) {
        return state.deleteIn([
          'query',
          payload.fromFieldId,
          'arguments',
          'and',
          andMatchIndex,
        ])
      }

      return state
    },
    [REMOVE_FIELD]: (state, { payload }) => {
      let currentField = state.getIn(['query', payload.fieldId])
      if (!currentField) {
        return state
      }

      let newState = state

      //Update the field's parent to remove the args and selectionset
      if (currentField.get('parentId')) {
        const argumentId = getRelationArgumentName(
          newState,
          currentField.toJS()
        )

        newState = newState.updateIn(
          ['query', currentField.get('parentId')],
          parent =>
            parent
              .update('selectionSet', selectionSet =>
                selectionSet.filter(value => value !== currentField.get('id'))
              )
              .deleteIn(['arguments', argumentId])
        )
      }

      //Remove the child tree as well the currentfield
      const newQueryState = deleteFieldAndChildren(
        currentField,
        newState.get('query')
      )

      return newState.set('query', newQueryState)
    },
    [REMOVE_RESULT_VIEW]: (state, { payload }) => {
      const resultViewIndex = state
        .getIn(['query', payload.fromFieldId, 'resultViews'], List())
        .indexOf(payload.id)

      return state.deleteIn([
        'query',
        payload.fromFieldId,
        'resultViews',
        resultViewIndex,
      ])
    },
    [RESET_QUERY]: state => state.set('query', Map()).set('resultViews', Map()),
    [SET_DEFAULT_VIEW]: (state, { payload }) =>
      state.setIn(['resultViews', 'defaultView'], payload),

    [UPDATE_RESULT_VIEW]: (state, { payload }) =>
      state.setIn(
        ['resultViews', payload.resultViewId, 'gridState'],
        fromJS(payload.gridState)
      ),
    [SET_QUERY_PROPERTY_ORDER]: (state, { payload }) => {
      return state.setIn(
        ['query', payload.fieldId, 'displayFields'],
        fromJS(payload.order)
      )
    },
    [SET_QUERY_COLUMN_ALIAS]: (state, { payload }) => {
      if (!state.getIn(['query', payload.fieldId, 'aliases'])) {
        return state.setIn(
          ['query', payload.fieldId, 'aliases'],
          fromJS({
            [payload.alias.column]: getValidAliasName(payload.alias.name),
          })
        )
      } else {
        return state.setIn(
          ['query', payload.fieldId, 'aliases', payload.alias.column],
          getValidAliasName(payload.alias.name)
        )
      }
    },
    [REMOVE_QUERY_COLUMN_ALIAS]: (state, { payload }) =>
      state.deleteIn(['query', payload.fieldId, 'aliases', payload.column]),
    [SET_EFFECTIVE_PERMISSION_PROPERTY_ORDER]: (state, { payload }) => {
      return state.setIn(
        ['query', payload.fieldId, 'effectivePermissions', 'order'],
        fromJS(payload.order)
      )
    },
    [ADD_EP_DISPLAY_COLUMN]: (state, { payload }) => {
      return state.updateIn(
        ['query', payload.fieldId, 'effectivePermissions', 'order'],
        order => order.push(payload.property)
      )
    },
    [REMOVE_EP_DISPLAY_COLUMN]: (state, { payload }) => {
      return state.updateIn(
        ['query', payload.fieldId, 'effectivePermissions', 'order'],
        order => order.filter(item => item !== payload.item)
      )
    },
    [SET_IS_DISTINCT_EP]: (state, { payload }) => {
      return state.setIn(
        ['query', payload.fieldId, 'effectivePermissions', 'isDistinct'],
        payload.isDistinct
      )
    },
    [SET_EP_DISPLAY_COLUMN_ALIAS]: (state, { payload }) => {
      if (
        !state.getIn([
          'query',
          payload.fieldId,
          'effectivePermissions',
          'aliases',
        ])
      ) {
        return state.setIn(
          ['query', payload.fieldId, 'effectivePermissions', 'aliases'],
          fromJS({
            [payload.alias.column]: getValidAliasName(payload.alias.name),
          })
        )
      } else {
        return state.setIn(
          [
            'query',
            payload.fieldId,
            'effectivePermissions',
            'aliases',
            payload.alias.column,
          ],
          getValidAliasName(payload.alias.name)
        )
      }
    },
    [REMOVE_EP_COLUMN_ALIAS]: (state, { payload }) =>
      state.deleteIn([
        'query',
        payload.fieldId,
        'effectivePermissions',
        'aliases',
        payload.column,
      ]),
    [UPDATE_EFFECTIVE_PERMISSION]: (state, { payload }) => {
      const updateMe = state
        .getIn([
          'query',
          payload.fieldId,
          'effectivePermissions',
          payload.type,
          payload.qepId,
        ])
        .filter(i => i.get('id') === payload.id)
        .first()
      const fieldType = Object.keys(payload.value)[0]
      let newGuy = updateMe
        .set('operation', payload.value[fieldType].op)
        .set('value', payload.value[fieldType].value)
        .setIn(['arg', fieldType, 'op'], payload.value[fieldType].op)
        .setIn(['arg', fieldType, 'value'], payload.value[fieldType].value)
      if (payload.value[fieldType].dateOffset) {
        newGuy = newGuy.setIn(
          ['arg', fieldType, 'dateOffset'],
          payload.value[fieldType].dateOffset
        )
      } else {
        newGuy = newGuy.setIn(['arg', fieldType, 'dateOffset'], null)
      }
      if (payload.value[fieldType].values) {
        newGuy = newGuy.setIn(
          ['arg', fieldType, 'values'],
          payload.value[fieldType].values
        )
      }
      const iamnewstate = state.updateIn(
        [
          'query',
          payload.fieldId,
          'effectivePermissions',
          payload.type,
          payload.qepId,
        ],
        arr =>
          arr.map(permission => {
            if (permission.get('id') === payload.id) {
              return newGuy
            } else {
              return permission
            }
          })
      )
      return iamnewstate
    },
    [SET_SEARCH_LIMIT]: (state, { payload }) => {
      const fieldIds = state.get('query').keySeq().toArray()

      let updatedQueryState = state.get('query')
      fieldIds.forEach(fieldId => {
        updatedQueryState = updatedQueryState.setIn(
          [fieldId, 'limit'],
          !_.isNumber(payload) ? parseInt(payload) : payload
        )
      })

      return state
        .set('query', updatedQueryState)
        .set('limit', !_.isNumber(payload) ? parseInt(payload) : payload)
    },
    [CLEAR_SEARCH_LIMIT]: state => state.set('limit', DEFAULT_SEARCH_LIMIT),
  },
  initialState
)

export default queryReducer
