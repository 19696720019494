import PropTypes from 'prop-types'

//eslint-disable-next-line no-unused-vars
const TabbedScrollableSection = ({ sectionKey, displayLabel, children }) => {
  return children
}

TabbedScrollableSection.propTypes = {
  displayLabel: PropTypes.node,
  sectionKey: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default TabbedScrollableSection
