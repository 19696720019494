import { fromJS, OrderedMap } from 'immutable'

import { LOAD_SAVED_QUERY } from 'containers/SearchQuery/constants'
import {
  RUN_QUERY,
  QUERY_ERROR_HAPPEN,
  SET_QUERY_RESULTS,
  SET_SEARCH_MODIFIED_CONCURRENTLY,
  SAVE_SEARCH_WITH_CONCURRENT_CHECK,
  SAVE_SEARCH,
  SAVE_SEARCH_SUCCESS,
  SAVE_SEARCH_ERROR,
  SET_SEARCH_NAME,
  SET_MONITORING_ON_RESOURCES,
  SET_MONITORING_ON_RESOURCES_SUCCESS,
  SET_MONITORING_ON_RESOURCES_ERROR,
  SET_MONITORING_RESOURCES_EXCEEDED,
  SET_IMPORTANCE_MULTI_MODAL,
  SET_IMPORTANCE_MULTI,
  SET_IMPORTANCE_MULTI_SUCCESS,
  ADD_TAGS_ON_SELECTED_RESOURCES,
  ADD_TAGS_ON_SELECTED_RESOURCES_SUCCESS,
  TOGGLE_COMMENTS,
  CLEAR_QUERY_ERROR,
  CLEAR_SAVE_SEARCH_ERROR,
  RESET_EDITOR,
  SAVE_SEARCH_DESCRIPTION,
  SAVE_SEARCH_DESCRIPTION_SUCCESS,
  SET_NODETYPE_COUNTS,
  GET_NODETYPE_COUNTS,
} from './constants'
import { handleActions } from 'redux-actions'
import { SEARCH_SAVE_STATE } from 'appConstants'
import { createNestedOrderMap } from 'utils/sonraiUtils'
export const initialState = fromJS({
  error: null,
  loadedSavedSearch: null,
  queryLoading: false,
  results: {},
  savingSearch: SEARCH_SAVE_STATE.ALLOW,
  searchModifiedConcurrently: false,
  resultRelations: null,
  searchName: '',
  enablingMonitoring: false,
  enableMinotoringError: null,
  monitoringResourcesExceeded: null,
  isMultiImportanceModalOpen: false,
  isUpdatingTags: false,
  saveSearchError: null,
  hasCommentsHidden: false,
  savingSearchDescription: false,
  nodeTypeCounts: {},
  nodeTypeCountsLoading: false,
  enableMonitoringError: null,
})

const searchReducer = handleActions(
  {
    [RESET_EDITOR]: state =>
      initialState.set('nodeTypeCounts', state.get('nodeTypeCounts')),
    [TOGGLE_COMMENTS]: state =>
      state.set('hasCommentsHidden', !state.get('hasCommentsHidden')),
    [RUN_QUERY]: state => state.set('queryLoading', true).set('error', null),
    [LOAD_SAVED_QUERY]: (state, { payload }) => {
      if (payload.skipRun) {
        return state
      }

      return state
        .set('error', null)
        .set('loadedSavedSearch', payload.searchId)
        .set('results', OrderedMap())
        .set('searchModifiedConcurrently', false)
        .set('saveSearchError', null)
    },
    [QUERY_ERROR_HAPPEN]: (state, { payload }) =>
      state.set('error', payload).set('saveSearchError', null),
    [CLEAR_QUERY_ERROR]: state => state.set('error', null),
    [SAVE_SEARCH]: state => {
      return state
        .set('searchModifiedConcurrently', false)
        .set(
          'savingSearch',
          state.get('savingSearch') === SEARCH_SAVE_STATE.SAVING_CONCURRENT
            ? SEARCH_SAVE_STATE.SAVING_CONCURRENT
            : SEARCH_SAVE_STATE.SAVING
        )
    },
    [SAVE_SEARCH_WITH_CONCURRENT_CHECK]: state =>
      state.set('savingSearch', SEARCH_SAVE_STATE.SAVING_CONCURRENT),
    [SAVE_SEARCH_ERROR]: (state, { payload }) =>
      state
        .set('saveSearchError', payload)
        .set('error', null)
        .set('savingSearch', SEARCH_SAVE_STATE.ALLOW),
    [CLEAR_SAVE_SEARCH_ERROR]: state => state.set('saveSearchError', null),
    [SET_SEARCH_NAME]: (state, { payload }) => state.set('searchName', payload),
    [SET_MONITORING_ON_RESOURCES]: state =>
      state.set('enablingMonitoring', true),
    [SET_MONITORING_RESOURCES_EXCEEDED]: (state, { payload }) =>
      state
        .set('monitoringResourcesExceeded', payload.hasExceeded)
        .set(
          'enablingMonitoring',
          payload ? false : state.get('enablingMonitoring')
        )
        .set('enableMonitoringError', payload.message),
    [SET_MONITORING_ON_RESOURCES_SUCCESS]: state =>
      state.set('enablingMonitoring', false).set('enableMonitoringError', null),

    [SET_MONITORING_ON_RESOURCES_ERROR]: (state, { payload }) =>
      state
        .set('enableMonitoringError', payload)
        .set(
          'enablingMonitoring',
          !payload ? false : state.get('enablingMonitoring')
        ),
    [SAVE_SEARCH_SUCCESS]: state =>
      state
        .set('savingSearch', SEARCH_SAVE_STATE.ALLOW)
        .set('saveSearchError', null),
    [SAVE_SEARCH_DESCRIPTION]: state =>
      state.set('savingSearchDescription', true),
    [SAVE_SEARCH_DESCRIPTION_SUCCESS]: state =>
      state.set('savingSearchDescription', false),
    [SET_QUERY_RESULTS]: (state, { payload }) =>
      state
        .set('error', null)
        .set('queryLoading', false)
        .set('results', createNestedOrderMap(payload.results)),
    [SET_SEARCH_MODIFIED_CONCURRENTLY]: (state, { payload }) =>
      state.set('searchModifiedConcurrently', payload),

    [SET_IMPORTANCE_MULTI_MODAL]: (state, { payload }) =>
      state.set('isMultiImportanceModalOpen', payload),
    [SET_IMPORTANCE_MULTI]: state =>
      state.set('isMultiImportanceLoading', true),
    [SET_IMPORTANCE_MULTI_SUCCESS]: state =>
      state
        .set('isMultiImportanceModalOpen', false)
        .set('isMultiImportanceLoading', false),
    [ADD_TAGS_ON_SELECTED_RESOURCES]: state =>
      state.set('isUpdatingTags', true),
    [ADD_TAGS_ON_SELECTED_RESOURCES_SUCCESS]: state =>
      state.set('isUpdatingTags', false),
    [GET_NODETYPE_COUNTS]: state => state.set('nodeTypeCountsLoading', true),
    [SET_NODETYPE_COUNTS]: (state, { payload }) =>
      state.set('nodeTypeCounts', payload).set('nodeTypeCountsLoading', false),
  },
  initialState
)

export default searchReducer
