import React from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap'
import { FormattedMessage } from 'react-intl'

import TextLink from 'components/TextLink'
import Button from 'components/Button'

import messages from './messages'

export class ModalSearchDescription extends React.Component {
  state = {
    description: this.props.description || '',
  }

  updateDescription = e => {
    this.setState({
      description: e.target.value,
    })
  }

  onSave = () => {
    this.props.doSave(this.state.description)
  }

  render() {
    const { description, savingSearchDescription } = this.props
    const isDisabled =
      !description && description !== ''
        ? this.state.description === ''
        : description === this.state.description
    return (
      <Modal isOpen={this.props.isOpen}>
        <ModalHeader toggle={this.props.toggle}>
          {description ? 'Edit Search Description' : 'Add Search Description'}
        </ModalHeader>
        <ModalBody>
          <Input
            placeholder="Add a description to your search"
            type="textarea"
            value={this.state.description}
            onChange={this.updateDescription}
          />
        </ModalBody>
        <ModalFooter>
          <TextLink color="primary" onClick={this.props.toggle}>
            <FormattedMessage {...messages.rename.cancelButton} />
          </TextLink>
          <Button
            color="primary"
            disabled={savingSearchDescription || isDisabled}
            onClick={this.onSave}
          >
            {savingSearchDescription
              ? description
                ? 'Updating...'
                : 'Saving...'
              : description
              ? 'Update'
              : 'Save'}
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

ModalSearchDescription.propTypes = {
  doSave: PropTypes.func,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  description: PropTypes.string,
  savingSearchDescription: PropTypes.bool,
}

export default ModalSearchDescription
