import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { selectSwimlanes } from 'containers/SonraiData/selectors'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import SelectBar from 'components/SelectBar'

class SwimlaneSelector extends React.PureComponent {
  getOptions = () =>
    this.props.swimlanes
      .toList()
      .map(swimlane => ({
        label: swimlane.get('title') || swimlane.get('srn'),
        value: swimlane.get('srn'),
      }))
      .toJS()

  getLabel = srn => {
    const options = this.getOptions()
    const swimlane = options.find(option => option.value === srn) || {}

    return swimlane.label || srn
  }

  getValue = () => {
    const { value } = this.props
    if (!value) {
      return null
    }
    return value.map(value => ({ label: this.getLabel(value), value }))
  }
  render() {
    return (
      <SelectBar
        {...this.props}
        value={this.getValue()}
        onChange={this.props.onChange}
        isMulti={this.props.isMulti}
        options={this.getOptions()}
      />
    )
  }
}

SwimlaneSelector.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  swimlanes: ImmutablePropTypes.iterable.isRequired,
}

const mapStateToProps = createStructuredSelector({
  swimlanes: selectSwimlanes,
})

const withConnect = connect(mapStateToProps)

export default withConnect(SwimlaneSelector)
