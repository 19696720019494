import { defineMessages } from 'react-intl'

export default defineMessages({
  savedSearchesHeader: {
    id: 'app.containers.Search.savedSearchesHeader',
    defaultMessage: 'Saved Searches',
  },
  loadSearch: {
    id: 'app.containers.Search.loadSearch',
    defaultMessage: 'Load a Saved Search',
  },
  manageSearches: {
    id: 'app.containers.Search.manageSearches',
    defaultMessage: 'Manage Saved Searches',
  },
  clearSearch: {
    id: 'app.containers.Search.clearSearch',
    defaultMessage: 'Clear Search',
  },
  advanced: {
    id: 'app.containers.Search.advanced',
    defaultMessage: 'Advanced',
  },
  searchname: {
    id: 'app.containers.Search.searchname',
    defaultMessage: 'Name',
  },
})
