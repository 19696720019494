/*
 * QueryResultTable Messages
 *
 * This contains all the text for the QueryResultTable component.
 */
import { defineMessages } from 'react-intl'

export default defineMessages({
  count: {
    id: 'app.components.QueryResultTable.count',
    defaultMessage: 'Count: ',
  },
  sortASC: {
    id: 'app.components.QueryResultTable.sortASC',
    defaultMessage: 'Sort Asceniding',
  },
  sortDESC: {
    id: 'app.components.QueryResultTable.sortDESC',
    defaultMessage: 'Sort Descending',
  },
  hideColumn: {
    id: 'app.components.QueryResultTable.sortDESC',
    defaultMessage: 'Hide Column',
  },
  noResults: {
    id: 'app.components.QueryResultTable.noResults',
    defaultMessage: 'No Results',
  },
  loadingTenSeconds: {
    id: 'app.components/QueryResults.loadingTenSeconds',
    defaultMessage: `We apologize for the delay retreiving your results, they are coming soon.`,
  },
  loadingIsSaved: {
    id: 'app.components/QueryResults.loadingIsSaved',
    defaultMessage: `Unfortunately your search has timed out. Don’t worry, we are running it in the background and the caching the results.`,
  },
  loadingIsNotSaved: {
    id: 'app.components/QueryResults.loadingIsNotSaved',
    defaultMessage: `Unfortunately, your search has timed out.  If you name and save this search, the system will run your saved search in the background and cache the results.`,
  },
  loadingIsNotSavedTwo: {
    id: 'app.components/QueryResults.loadingIsNotSavedTwo',
    defaultMessage: `Once cached, the results for a saved search will be available immediately.`,
  },
  warning: {
    id: 'app.components/QueryResults.warning',
    defaultMessage: 'Warning',
  },
  warningModalBody: {
    id: 'app.components/QueryResults.warningModalBody',
    defaultMessage:
      'Your search results contain more than 100 nodes and could take a while to format properly in the Explorer view. Do you wish to continue?',
  },
  cancel: {
    id: 'app.components/QueryResults.cancel',
    defaultMessage: 'Cancel',
  },
  continue: {
    id: 'app.components/QueryResults.continue',
    defaultMessage: 'Continue',
  },
})
