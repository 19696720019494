import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class FilterFieldErrorBoundry extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      error: null,
      info: null,
    }
  }

  componentDidCatch(error, info) {
    // eslint-disable-next-line no-console
    this.setState({
      hasError: true,
      error,
      info,
    })
  }

  render() {
    // just don't show the thing on the screen we were not able to render
    // we'll know there was a problem caused it logged above
    if (this.state.hasError) {
      return <span />
    }

    return this.props.children
  }
}

FilterFieldErrorBoundry.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]).isRequired,
}
