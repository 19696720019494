import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import TextLink from 'components/TextLink'
import BorderlessButton from 'components/BorderlessButton'
import Icon from 'components/Icon'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'

import messages from './messages'

export const GlobalSearchActions = ({
  loadSearchInAdvanced,
  theme,
  resetQuery,
}) => {
  const styles = {
    toolbar: {
      display: 'grid',
      gridTemplateColumns: '1fr auto',
      gridTemplateAreas: '"load advanced"',
      gridArea: 'toolbar',
      paddingBottom: '0.3em',
    },
    divider: {
      margin: '0 0.5em',
      borderRight: `1px solid ${theme.neutralDark}`,
      display: 'inline-block',
      height: '15px',
    },
    advanced: {
      gridArea: 'advanced',
    },
  }
  return (
    <div style={styles.toolbar}>
      <div>
        <TextLink
          to={{
            pathname: '/App/SearchManager',
          }}
          color="secondary"
        >
          <FormattedMessage {...messages.manageSearches} />
        </TextLink>
        {resetQuery && (
          <Fragment>
            <div style={styles.divider} />
            <TextLink
              color="secondary"
              style={styles.textButton}
              onClick={resetQuery}
            >
              <FormattedMessage {...messages.clearSearch} />
            </TextLink>
          </Fragment>
        )}
      </div>

      <BorderlessButton
        style={styles.advanced}
        onClick={() => loadSearchInAdvanced()}
        title="Advanced Search"
      >
        <Icon fa name="advancedSearch" />
        &nbsp; <FormattedMessage {...messages.advanced} />
      </BorderlessButton>
    </div>
  )
}

GlobalSearchActions.propTypes = {
  loadSearchInAdvanced: PropTypes.func.isRequired,
  resetQuery: PropTypes.func,
  theme: themeShape,
}

export default themeable(GlobalSearchActions)
