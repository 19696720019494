import { defineMessages } from 'react-intl'

export default {
  ...defineMessages({
    filter: {
      id: 'app.components.QueryCard.filter',
      defaultMessage: 'Filter',
    },
    nextCardSelectorTitle: {
      id: 'app.components.QueryCard.nextCardSelexctor.title',
      defaultMessage: 'Choose Relation',
    },
    all: {
      id: 'app.components.QueryCard.fieldCardBody.all',
      defaultMessage: 'All',
    },
  }),
}
