import { defineMessages } from 'react-intl'

export default defineMessages({
  filterPlaceholder: {
    id: 'app.components.SearchLanding.filterPlaceholder',
    defaultMessage: 'Filter by resource type, cloud or service name',
  },
  noMatch: {
    id: 'app.components.SearchLanding.noMatch',
    defaultMessage: 'No resource types match your filter',
  },
  openInBuilder: {
    id: 'app.components.SearchLanding.openInBuilder',
    defaultMessage: 'Open in Builder',
  },
  identity: {
    id: 'app.components.SearchLanding.identity',
    defaultMessage: 'Identities',
  },
  data: {
    id: 'app.components.SearchLanding.data',
    defaultMessage: 'Data',
  },
  infrastructure: {
    id: 'app.components.SearchLanding.infrastructure',
    defaultMessage: 'Infrastructure',
  },
  protection: {
    id: 'app.components.SearchLanding.protection',
    defaultMessage: 'Protections',
  },
  action: {
    id: 'app.components.SearchLanding.action',
    defaultMessage: 'Actions',
  },
  classification: {
    id: 'app.components.SearchLanding.classification',
    defaultMessage: 'Classifications',
  },
  permission: {
    id: 'app.components.SearchLanding.permission',
    defaultMessage: 'Permissions',
  },
  location: {
    id: 'app.components.SearchLanding.location',
    defaultMessage: 'Locations',
  },
  account: {
    id: 'app.components.SearchLanding.account',
    defaultMessage: 'Accounts',
  },
  other: {
    id: 'app.components.SearchLanding.other',
    defaultMessage: 'Other',
  },
})
