import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl, intlShape } from 'react-intl'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { compose } from 'redux'
import _ from 'lodash'

import ImmutablePureComponent from 'components/ImmutablePureComponent'
import SelectBar from 'components/SelectBar'
import FormLabel from 'components/FormLabel'
import Button from 'components/Button'
import TextLink from 'components/TextLink'
import messages from 'globalTranslations'

class EditProperty extends ImmutablePureComponent {
  styles = {
    container: {
      width: '400px',
    },
    footer: {
      marginTop: '1em',
      display: 'flex',
      alignItems: 'center',
    },
    submitButton: {
      marginLeft: 'auto',
    },
    cancelButton: {
      marginRight: '0.5em',
    },
  }

  constructor(props) {
    super(props)
    this.state = {
      propertyName: this.props.propertyName,
    }
  }

  submit = () => {
    this.props.onSubmit({
      property: this.state.propertyName,
      fieldId: this.props.field.get('id'),
    })
  }

  onSelectProperty = selectedValue => {
    if (selectedValue) {
      this.setState({
        propertyName: selectedValue.value,
      })
    } else {
      this.setState({
        propertyName: null,
      })
    }
  }
  render() {
    return (
      <div style={this.styles.container}>
        <FormLabel label="Property" />
        <SelectBar
          autoFocus
          value={this.props.propertyName}
          options={this.props.scalerFieldsFiltered
            .toJS()
            .map(inputFieldDef => {
              return {
                def: inputFieldDef,
                value: inputFieldDef.name,
                label: this.props.intl.formatMessage(
                  messages.fields[inputFieldDef.name] || {
                    id: 'unknown',
                    defaultMessage: _.startCase(inputFieldDef.name),
                  }
                ),
              }
            })
            .sort((a, b) => a.label.localeCompare(b.label))}
          onChange={this.onSelectProperty}
        />

        <div style={this.styles.footer}>
          <div style={this.styles.submitButton}>
            <TextLink
              color="primary"
              onClick={this.props.toggle}
              style={this.styles.cancelButton}
            >
              Cancel
            </TextLink>
            <Button
              color="primary"
              onClick={this.submit}
              disabled={!this.state.propertyName}
            >
              Add
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

EditProperty.propTypes = {
  field: ImmutablePropTypes.contains({
    id: PropTypes.string,
  }),
  intl: intlShape,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
  scalerFieldsFiltered: ImmutablePropTypes.list,
}

export default compose(injectIntl)(EditProperty)
