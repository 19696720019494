import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List, isImmutable, fromJS } from 'immutable'
import Explorer from 'containers/Explorer'
import { FormattedMessage } from 'react-intl'
import messages from './messages'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import TextLink from 'components/TextLink'
import Button from 'components/Button'

export class ExplorerResults extends React.Component {
  state = {
    awareOfSlowLayout: false,
  }

  processNode = (node, isSourceNode) => {
    let rootNodeConfig = fromJS({
      __typename: node.get('__typename'),
      srn: node.get('srn'),
      name: node.get('name'),
      isSourceNode: isSourceNode,
      relations: {},
      filters: {},
    })

    let nodes = List()

    node.forEach((property, edgeName) => {
      if (
        property !== undefined &&
        isImmutable(property) &&
        !property.get('items', List()).isEmpty()
      ) {
        let childNode = property.get('items', List()).first()

        let childNodeType =
          childNode !== undefined ? childNode.get('__typename') : undefined

        property.get('items', List()).forEach(childNode => {
          const existingRelations = rootNodeConfig.getIn(
            ['relations', childNodeType],
            List()
          )

          rootNodeConfig = rootNodeConfig
            .setIn(
              ['relations', childNodeType],
              existingRelations.push(
                fromJS({
                  item: {
                    srn: childNode.get('srn'),
                    __typename: childNode.get('__typename'),
                    name: childNode.get('name'),
                  },
                  parent: node.get('srn'),
                  relation: {
                    direction: 'OUT',
                    name: edgeName,
                  },
                })
              )
            )
            .setIn(['filters', childNodeType], fromJS({ selected: true }))

          nodes = nodes.concat(this.processNode(childNode, false))
        })
      }
    })

    return nodes.push(rootNodeConfig)
  }

  getExplorerData = results => {
    if (!results.isEmpty()) {
      const sourceNodes = results.first().get('items', List())

      let nodes = List()

      sourceNodes.forEach(node => {
        const processedResult = this.processNode(node, true)
        nodes = nodes.concat(processedResult)
      })

      return nodes
    }
  }

  renderConfirmationModal = () => (
    <Modal isOpen={true}>
      <ModalHeader toggle={this.props.onCancel}>
        <FormattedMessage {...messages.warning} />
      </ModalHeader>
      <ModalBody>
        <FormattedMessage {...messages.warningModalBody} />
      </ModalBody>
      <ModalFooter>
        <TextLink color="secondary" onClick={this.props.onCancel}>
          <FormattedMessage {...messages.cancel} />
        </TextLink>
        <Button
          color="primary"
          onClick={() => this.setState({ awareOfSlowLayout: true })}
        >
          <FormattedMessage {...messages.continue} />
        </Button>
      </ModalFooter>
    </Modal>
  )

  render() {
    const explorerData = this.getExplorerData(this.props.results) || List()

    if (!explorerData.isEmpty() && explorerData.size <= 100) {
      return <Explorer data={explorerData} />
    } else if (explorerData.size > 100 && !this.state.awareOfSlowLayout) {
      return this.renderConfirmationModal()
    } else if (explorerData.size > 100 && this.state.awareOfSlowLayout) {
      return <Explorer data={explorerData} />
    }
  }
}

ExplorerResults.propTypes = {
  results: ImmutablePropTypes.map.isRequired,
  onCancel: PropTypes.func,
}

export default ExplorerResults
