import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { injectIntl } from 'react-intl'
import { compose } from 'redux'
import { Map } from 'immutable'

import SelectBar from 'components/SelectBar'

import messages from './messages'

class BooleanOperator extends React.Component {
  onValueChange = val => {
    const value = val.value
    this.props.onChange(Map({ value }))
  }

  render() {
    const booleanOptions = [
      {
        label: this.props.intl.formatMessage(messages.booleanValues.true),
        value: true,
      },
      {
        label: this.props.intl.formatMessage(messages.booleanValues.false),
        value: false,
      },
    ]

    let value = null
    if (
      this.props.argumentValue.get('value') === 'true' ||
      this.props.argumentValue.get('value') === true
    ) {
      value = true
    } else if (
      this.props.argumentValue.get('value') === 'false' ||
      this.props.argumentValue.get('value') === false
    ) {
      value = false
    }

    return (
      <div style={{ marginBottom: '0.5em' }}>
        <SelectBar
          options={booleanOptions}
          value={value}
          onChange={this.onValueChange}
          isClearable={false}
        />
      </div>
    )
  }
}

BooleanOperator.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  argumentValue: ImmutablePropTypes.map.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default compose(injectIntl)(BooleanOperator)
