import React from 'react'
import PropTypes from 'prop-types'
import { List, Map } from 'immutable'
import ImmutablePropTypes from 'react-immutable-proptypes'
import BorderlessButton from 'components/BorderlessButton'
import Icon from 'components/Icon'
import Pill from 'components/Badge/Pill'
import { camelCase, startCase } from 'lodash'
import { injectIntl, intlShape } from 'react-intl'
import messages from 'globalTranslations'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import Expandable from 'components/Expandable'
import { Input } from 'reactstrap'
import Popover, { PopoverAnchor, PopoverBody } from 'components/Popover'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'

const styles = {
  container: {
    maxHeight: '110px',
    overflow: 'hidden',
    marginBottom: '0.5em',
  },
  noColumns: {
    paddingLeft: '30px',
  },
  fullContainer: {
    marginBottom: '0.5em',
  },
  pillbox: {
    marginRight: '2px',
    marginLeft: '2px',
    marginTop: '5px',
    color: '#737373',
  },
  defaultPillboi: {
    marginRight: '2px',
    marginLeft: '2px',
    marginTop: '5px',
    color: '#737373',
    backgroundColor: '#FFFFFF',
    border: 'solid 1px #737373',
  },
  expandButton: {
    fontSize: '1em',
    marginTop: '1em',
    display: 'block',
    color: '#737373',
  },
  label: {
    whiteSpace: 'noWrap',
  },
  inputContainer: {
    display: 'flex',
    height: '25px',
  },
}

const SortableItem = SortableElement(
  ({
    col,
    isDefault,
    removeProperty,
    intl,
    editAlias,
    editingAlias,
    alias,
    updateAlias,
    activeAliasText,
    submitAliasUpdate,
    theme,
    removeAlias,
  }) => {
    let displayName = alias
      ? alias
      : messages.fields[col]
      ? intl.formatMessage(messages.fields[col])
      : col

    const update = newName => {
      updateAlias(newName === displayName ? null : newName)
    }

    return (
      <Pill style={isDefault ? styles.defaultPillboi : styles.pillbox}>
        {startCase(displayName)}{' '}
        {editingAlias !== col ? (
          <BorderlessButton
            onClick={() => {
              editAlias(col)
            }}
          >
            <Icon fa name={'pencil-alt'} transform="shrink-8" />
          </BorderlessButton>
        ) : (
          <Popover isOpen={editingAlias === col} position="right">
            <PopoverAnchor>
              <BorderlessButton
                color="primary"
                onClick={() => {
                  editAlias(col)
                }}
                title="Edit"
              >
                {editingAlias === col ? (
                  <Icon fa name="pencil-alt" transform="shrink-8" />
                ) : (
                  <div style={{ width: '30px' }} />
                )}
              </BorderlessButton>
            </PopoverAnchor>
            <PopoverBody>
              <div style={styles.inputContainer}>
                <Input
                  style={{ height: '100%', marginRight: '5px' }}
                  onChange={e => update(e.target.value)}
                  value={activeAliasText}
                  onKeyPress={e =>
                    e.key === 'Enter' && submitAliasUpdate(col, activeAliasText)
                  }
                />
                <BorderlessButton
                  onClick={() => submitAliasUpdate(col, activeAliasText)}
                  title="Submit Alias"
                  style={{ color: theme.primary, marginRight: '5px' }}
                >
                  <Icon fa name="plus" transform="shrink-4" />
                </BorderlessButton>
                <BorderlessButton
                  onClick={() => {
                    editAlias(col)
                    removeAlias(col)
                  }}
                  title="Cancel"
                  style={{ color: theme.fail }}
                >
                  <Icon fa name="times" transform="shrink-4" />
                </BorderlessButton>
              </div>
            </PopoverBody>
          </Popover>
        )}
        <BorderlessButton
          onClick={() => {
            removeProperty(col)
          }}
          title="Remove Column"
        >
          <Icon fa name="times" transform="shrink-6" />
        </BorderlessButton>
      </Pill>
    )
  }
)
const SortableList = SortableContainer(
  ({
    cols,
    defaults,
    removeProperty,
    intl,
    editAlias,
    editingAlias,
    aliases,
    updateAlias,
    activeAliasText,
    submitAliasUpdate,
    theme,
    removeAlias,
  }) => {
    return (
      <div>
        {cols.map((value, index) => {
          return (
            <SortableItem
              key={`item-${value}`}
              index={index}
              col={value}
              removeProperty={removeProperty}
              isDefault={defaults.includes(value)}
              intl={intl}
              editAlias={editAlias}
              editingAlias={editingAlias}
              alias={aliases[value]}
              updateAlias={updateAlias}
              activeAliasText={activeAliasText}
              submitAliasUpdate={submitAliasUpdate}
              theme={theme}
              removeAlias={removeAlias}
            />
          )
        })}
      </div>
    )
  }
)

class PropertySelect extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showMore: false,
      editingAlias: null,
      aliasText: '',
    }
  }

  changeAliasText = thingy => {
    this.setState({
      aliasText: thingy,
    })
  }

  editAlias = col => {
    if (col === this.state.editingAlias) {
      this.setState({
        editingAlias: null,
      })
      this.changeAliasText('')
    } else {
      if (this.props.field.getIn(['aliases'], Map()).toJS()[col]) {
        this.changeAliasText(
          this.props.field.getIn(['aliases'], Map()).toJS()[col]
        )
      } else {
        if (messages.fields[col]) {
          this.changeAliasText(
            this.props.intl.formatMessage(messages.fields[col])
          )
        } else {
          this.changeAliasText(camelCase(col))
        }
      }
      this.setState({
        editingAlias: col,
      })
    }
  }

  toggleExpand = () => {
    this.setState(currentState => ({
      showMore: !currentState.showMore,
    }))
  }

  changeOrder = options => {
    let order = this.props.field.getIn(['displayFields'], List()).toJS()
    const moveMe = order.splice(options.oldIndex, 1)
    order.splice(options.newIndex, 0, moveMe[0])
    this.props.setQueryPropertyOrder({
      order: order,
      fieldId: this.props.field.get('id'),
    })
  }

  submitAliasUpdate = () => {
    this.editAlias(this.state.editingAlias)
    this.props.setQueryColumnAlias({
      fieldId: this.props.field.get('id'),
      alias: { column: this.state.editingAlias, name: this.state.aliasText },
    })
  }

  removeAlias = col => {
    this.props.removeQueryColumnAlias({
      column: col,
      fieldId: this.props.field.get('id'),
    })
  }

  renderProperties = () => {
    const displayColumns = this.props.field
      .getIn(['displayFields'], List())
      .toJS()
    const aliases = this.props.field.getIn(['aliases'], Map()).toJS()

    if (displayColumns.length > 0) {
      return (
        <SortableList
          cols={this.props.displayColumns}
          defaults={this.props.defaultColumns}
          distance={1}
          removeProperty={this.props.removeProperty}
          lockToContainerEdges={true}
          axis={'xy'}
          onSortEnd={this.changeOrder}
          intl={this.props.intl}
          editAlias={this.editAlias}
          editingAlias={this.state.editingAlias}
          aliases={aliases}
          updateAlias={this.changeAliasText}
          activeAliasText={this.state.aliasText}
          submitAliasUpdate={this.submitAliasUpdate}
          theme={this.props.theme}
          removeAlias={this.removeAlias}
        />
      )
    } else {
      return <div style={styles.noColumns}>{`[ No columns selected ]`}</div>
    }
  }

  render() {
    return (
      <div>
        <Expandable scale={true} autoUpdate={true} height={'110px'}>
          {this.renderProperties()}
        </Expandable>
      </div>
    )
  }
}

PropertySelect.propTypes = {
  displayColumns: ImmutablePropTypes.list,
  removeProperty: PropTypes.func,
  field: ImmutablePropTypes.contains({
    id: PropTypes.string,
  }),
  defaultColumns: PropTypes.array,
  setQueryPropertyOrder: PropTypes.func.isRequired,
  intl: intlShape,
  setQueryColumnAlias: PropTypes.func,
  removeQueryColumnAlias: PropTypes.func,
  theme: themeShape,
}

export default themeable(injectIntl(PropertySelect))
