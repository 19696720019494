/*
 * QueryCardFieldFilter Messages
 *
 * This contains all the text for the QueryCardFieldFilter component.
 */
import { defineMessages } from 'react-intl'

export default {
  ...defineMessages({
    caseSensitive: {
      id: 'app.components.QueryCardFilter.caseSensitive',
      defaultMessage: 'Case Sensitive',
    },
    isMulti: {
      id: 'app.components.QueryCardFilter.isMulti',
      defaultMessage: 'Multi Value',
    },
    csv: {
      id: 'app.components.QueryCardFilter.csv',
      defaultMessage: 'Auto-detect delimiter',
    },
  }),
  booleanValues: {
    ...defineMessages({
      any: {
        id: 'app.components.QueryCardFilter.booleanValues.any',
        defaultMessage: 'All',
      },
      true: {
        id: 'app.components.QueryCardFilter.booleanValues.true',
        defaultMessage: 'True',
      },
      false: {
        id: 'app.components.QueryCardFilter.booleanValues.false',
        defaultMessage: 'False',
      },
    }),
  },
  relativeDates: {
    ...defineMessages({
      fixed: {
        id: 'app.components.QueryCardFilter.relativeDates.fixed',
        defaultMessage: 'Fixed Date...',
      },
      pastHeader: {
        id: 'app.components.QueryCardFilter.relativeDates.past',
        defaultMessage: 'In the past',
      },
      futureHeader: {
        id: 'app.components.QueryCardFilter.relativeDates.future',
        defaultMessage: 'In the future',
      },
      // past times.. like yesterday.. not a hobby
      // past within
      inPastHour1: {
        id: 'app.components.QueryCardFilter.relativeDates.inPastHour1',
        defaultMessage: 'Within the past hour',
      },
      inPastHour3: {
        id: 'app.components.QueryCardFilter.relativeDates.inPastHour3',
        defaultMessage: 'Within the past 3 hours',
      },
      inPastHour12: {
        id: 'app.components.QueryCardFilter.relativeDates.inPastHour12',
        defaultMessage: 'Within the past 12 hours',
      },
      inPastDay1: {
        id: 'app.components.QueryCardFilter.relativeDates.inPastDay1',
        defaultMessage: 'Within the past 24 hours',
      },
      inPastDay7: {
        id: 'app.components.QueryCardFilter.relativeDates.inPastDay7',
        defaultMessage: 'Within the past 7 days',
      },
      inPastDay30: {
        id: 'app.components.QueryCardFilter.relativeDates.inPastDay30',
        defaultMessage: 'Within the past 30 days',
      },
      inPastDay90: {
        id: 'app.components.QueryCardFilter.relativeDates.inPastDay90',
        defaultMessage: 'Within the past 90 days',
      },
      inPastMonth6: {
        id: 'app.components.QueryCardFilter.relativeDates.inPastMonth6',
        defaultMessage: 'Within the past 6 months',
      },
      inPastMonth9: {
        id: 'app.components.QueryCardFilter.relativeDates.inPastMonth9',
        defaultMessage: 'Within the past 9 months',
      },
      inPastMonth12: {
        id: 'app.components.QueryCardFilter.relativeDates.inPastMonth12',
        defaultMessage: 'Within the past 12 months',
      },
      // past more than
      outPastHour1: {
        id: 'app.components.QueryCardFilter.relativeDates.outPastHour1',
        defaultMessage: 'More than an hour ago',
      },
      outPastHour3: {
        id: 'app.components.QueryCardFilter.relativeDates.outPastHour3',
        defaultMessage: 'More than 3 hours ago',
      },
      outPastHour12: {
        id: 'app.components.QueryCardFilter.relativeDates.outPastHour12',
        defaultMessage: 'More than 12 hours ago',
      },
      outPastDay1: {
        id: 'app.components.QueryCardFilter.relativeDates.outPastDay1',
        defaultMessage: 'More than 24 hours ago',
      },
      outPastDay7: {
        id: 'app.components.QueryCardFilter.relativeDates.outPastDay7',
        defaultMessage: 'More than 7 days ago',
      },
      outPastDay30: {
        id: 'app.components.QueryCardFilter.relativeDates.outPastDay30',
        defaultMessage: 'More than 30 days ago',
      },
      outPastDay90: {
        id: 'app.components.QueryCardFilter.relativeDates.outPastDay90',
        defaultMessage: 'More than 90 days ago',
      },
      outPastMonth6: {
        id: 'app.components.QueryCardFilter.relativeDates.outPastMonth6',
        defaultMessage: 'More than 6 months ago',
      },
      outPastMonth9: {
        id: 'app.components.QueryCardFilter.relativeDates.outPastMonth9',
        defaultMessage: 'More than 9 months ago',
      },
      outPastMonth12: {
        id: 'app.components.QueryCardFilter.relativeDates.outPastMonth12',
        defaultMessage: 'More than 12 months ago',
      },
      // future times (like synthwave)
      // future within
      inFutureHour1: {
        id: 'app.components.QueryCardFilter.relativeDates.inFutureHour1',
        defaultMessage: 'More than an hour from now',
      },
      inFutureHour3: {
        id: 'app.components.QueryCardFilter.relativeDates.inFutureHour3',
        defaultMessage: 'More than 3 hours from now',
      },
      inFutureHour12: {
        id: 'app.components.QueryCardFilter.relativeDates.inFutureHour12',
        defaultMessage: 'More than 12 hours from now',
      },
      inFutureDay1: {
        id: 'app.components.QueryCardFilter.relativeDates.inFutureDay1',
        defaultMessage: 'More than 24 hours from now',
      },
      inFutureDay7: {
        id: 'app.components.QueryCardFilter.relativeDates.inFutureDay7',
        defaultMessage: 'More than 7 days from now',
      },
      inFutureDay30: {
        id: 'app.components.QueryCardFilter.relativeDates.inFutureDay30',
        defaultMessage: 'More than 30 days from now',
      },
      inFutureDay90: {
        id: 'app.components.QueryCardFilter.relativeDates.inFutureDay90',
        defaultMessage: 'More than 90 days from now',
      },
      inFutureMonth6: {
        id: 'app.components.QueryCardFilter.relativeDates.inFutureMonth6',
        defaultMessage: 'More than 6 months from now',
      },
      inFutureMonth9: {
        id: 'app.components.QueryCardFilter.relativeDates.inFutureMonth9',
        defaultMessage: 'More than 9 months from now',
      },
      inFutureMonth12: {
        id: 'app.components.QueryCardFilter.relativeDates.inFutureMonth12',
        defaultMessage: 'More than 12 months from now',
      },
      // future more than
      outFutureHour1: {
        id: 'app.components.QueryCardFilter.relativeDates.outFutureHour1',
        defaultMessage: 'Within the next hour',
      },
      outFutureHour3: {
        id: 'app.components.QueryCardFilter.relativeDates.outFutureHour3',
        defaultMessage: 'Within the next 3 hours',
      },
      outFutureHour12: {
        id: 'app.components.QueryCardFilter.relativeDates.outFutureHour12',
        defaultMessage: 'Within the next 12 hours',
      },
      outFutureDay1: {
        id: 'app.components.QueryCardFilter.relativeDates.outFutureDay1',
        defaultMessage: 'Within the next 24 hours',
      },
      outFutureDay7: {
        id: 'app.components.QueryCardFilter.relativeDates.outFutureDay7',
        defaultMessage: 'Within the next 7 days',
      },
      outFutureDay30: {
        id: 'app.components.QueryCardFilter.relativeDates.outFutureDay30',
        defaultMessage: 'Within the next 30 days',
      },
      outFutureDay90: {
        id: 'app.components.QueryCardFilter.relativeDates.outFutureDay90',
        defaultMessage: 'Within the next 90 days',
      },
      outFutureMonth6: {
        id: 'app.components.QueryCardFilter.relativeDates.outFutureMonth6',
        defaultMessage: 'Within the next 6 months',
      },
      outFutureMonth9: {
        id: 'app.components.QueryCardFilter.relativeDates.outFutureMonth9',
        defaultMessage: 'Within the next 9 months',
      },
      outFutureMonth12: {
        id: 'app.components.QueryCardFilter.relativeDates.outFutureMonth12',
        defaultMessage: 'Within the next 12 months',
      },
    }),
  },
}
