import uuid from 'uuid/v4'
import { fromJS, Map, isImmutable } from 'immutable'
import { getItemsType, getUniqueRelationName } from 'query-builder'
import _ from 'lodash'

let queryResultViewSequence = 0
export const newQueryResultId = () => 'resultview-' + queryResultViewSequence++

export const getNewUUID = (prefix, length) => {
  return `${prefix}-` + uuid().replace('-', '').substring(0, length)
}

export const createQueryField = (definition, state, types) =>
  fromJS({
    id: getNewUUID('qf', 10),
    arguments: {
      or: [],
      and: [],
    },
    definition,
    name: nameNewQueryField({ definition }, state, types),
    resultViews: [],
    selectionSet: [],
    childOperator: 'or',
  })

const nameNewQueryField = (newField, state, types) => {
  const newFieldItemsType = getItemsType(fromJS(newField), types)
  const fieldName =
    (isImmutable(newField.definition)
      ? newField.definition.get('displayName')
      : _.get(newField, ['definition', 'displayName'])) || newFieldItemsType

  const numberOfSameType = state
    .get('query', Map())
    .filter(selection => getItemsType(selection, types) === newFieldItemsType)
    .size

  if (numberOfSameType === 0) {
    return fieldName
  } else {
    return `${fieldName} (${numberOfSameType})`
  }
}

export const getNewRelationArgumentName = (state, field) => {
  const parentField = state.getIn(['query', field.parentId], Map())
  if (parentField.isEmpty()) {
    return
  }

  const definitionName = field.definition.name
  return parentField.hasIn(['arguments', definitionName])
    ? getUniqueRelationName(definitionName, field.id)
    : definitionName
}

export const getRelationArgumentName = (state, field) => {
  const parentField = state.getIn(['query', field.parentId], Map())
  if (parentField.isEmpty()) {
    return
  }

  const definitionName = field.definition.name
  const keyedName = getUniqueRelationName(definitionName, field.id)

  return parentField.hasIn(['arguments', keyedName])
    ? keyedName
    : definitionName
}
