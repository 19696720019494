import { all, put, takeLatest, select } from 'redux-saga/effects'
import { fromJS, Map } from 'immutable'
import uuid from 'uuid/v4'

import {
  QueryBuilder,
  RESULT_VIEW_TYPES,
  getItemsType,
  getDefaultColumnsForField,
} from 'query-builder'
import { selectQueryTypes } from 'containers/SonraiData/selectors'

import { selectQuery } from './selectors'
import {
  loadSavedQuery,
  insertField,
  addResultView,
  setDefaultView,
} from './actions'
import {
  LOAD_AND_CONVERT_SAVED_QUERY,
  START_QUERY,
  ADD_FIELD,
} from './constants'
import { createQueryField, getNewRelationArgumentName } from './utils'

function* loadAndConvertSavedQuery(action) {
  let savedSearch = action.payload.savedSearch

  if (!savedSearch) {
    return
  }

  const queryTypes = yield select(selectQueryTypes)

  const queryBuilder = new QueryBuilder({
    query: savedSearch.getIn(['query', 'fields']),
    resultViews: savedSearch.getIn(['query', 'resultViews']),
    types: queryTypes,
  })

  //QueryBuilder converts legacy fields automatically in the constructor
  const convertedFromLegacy = !queryBuilder.fields.equals(
    savedSearch.getIn(['query', 'fields'])
  )
  savedSearch = savedSearch.setIn(['query', 'fields'], queryBuilder.fields)

  yield put(
    loadSavedQuery({
      ...action.payload,
      savedSearch: savedSearch,
      convertedFromLegacy: convertedFromLegacy,
    })
  )
}

const addFilters = (field, filters) => {
  if (
    field.get('arguments').get('and').size > 0 ||
    field.get('arguments').get('or').size > 0
  ) {
    return field
  }

  const newArgs = filters
    .map((filter, key) => {
      if (key === 'label') {
        return null
      }

      return fromJS({
        [key]: {
          caseSensitive: false,
          dateOffset: filter.get('dateOffset'),
          op: filter.get('op'),
          id: uuid(),
          value: filter.get('value'),
        },
      })
    })
    .toList()
    .filter(arg => arg !== null)

  return field.setIn(['arguments', 'and'], newArgs)
}

function* initField({ payload }) {
  const state = yield select(selectQuery)
  const queryTypes = yield select(selectQueryTypes)

  //Set up the new field
  let newField = createQueryField(payload.definition, state, queryTypes)

  if (payload.filters) {
    newField = addFilters(newField, fromJS(payload.filters))
  }

  const fieldTypeName = getItemsType(
    fromJS({ definition: payload.definition }),
    queryTypes
  )
  const newFieldDefinition = queryTypes.get(fieldTypeName)
  const defaultCols = getDefaultColumnsForField(
    newFieldDefinition,
    fieldTypeName,
    queryTypes
  )

  newField = newField.set('displayFields', fromJS(defaultCols))

  //Do updates for the parent, if needed
  if (payload.to) {
    const parentField = state.getIn(['query', payload.to], Map())

    if (parentField.isEmpty()) {
      return
    }

    newField = newField.set('parentId', parentField.get('id'))

    const argName = getNewRelationArgumentName(state, newField.toJS())
    const updatedParent = parentField
      .update('selectionSet', selectionSet =>
        selectionSet.push(newField.get('id'))
      )
      .setIn(['arguments', argName], fromJS({ count: { op: 'GT', value: 0 } }))

    yield put(insertField(updatedParent))
  }

  //Commit the changes
  yield put(insertField(newField))
  yield put(
    addResultView({
      to: newField.get('id'),
      viewType: RESULT_VIEW_TYPES.TABLE,
    })
  )
  yield put(setDefaultView(RESULT_VIEW_TYPES.TABLE))
}

function* searchSaga() {
  yield all([
    takeLatest(LOAD_AND_CONVERT_SAVED_QUERY, loadAndConvertSavedQuery),
    takeLatest(START_QUERY, initField),
    takeLatest(ADD_FIELD, initField),
  ])
}

export default searchSaga
