import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { bindActionCreators, compose } from 'redux'
import { push } from 'connected-react-router'
import { RESULT_VIEW_TYPES } from 'query-builder'
import qs from 'query-string'
import { withRouter } from 'react-router'
import { Map, List } from 'immutable'
import { FormattedMessage } from 'react-intl'
import CenterContent from 'components/CenterContent'
import EmptySection from 'components/EmptySection'
import TabBar from 'components/TabBar'
import Icon from 'components/Icon'
import { getNodeViewPushParams } from 'utils/sonraiUtils'
import Error from 'components/Error'
import BorderedCard from 'components/BorderedCard'
import {
  selectResultViews,
  selectQueryStatements,
  selectRootQuery,
} from 'containers/SearchQuery/selectors'
import { setDefaultView } from 'containers/SearchQuery/actions'

import {
  selectQueryRelations,
  selectTags,
} from 'containers/SonraiData/selectors'
import { fetchTags } from 'containers/SonraiData/actions'
import {
  removeResultView,
  updateResultView,
} from 'containers/SearchQuery/actions'
import {
  selectError,
  selectResultCards,
  selectQueryResults,
  selectQueryLoading,
  selectResultView,
  selectSearchName,
} from 'containers/Search/selectors'
import { runQuery } from 'containers/Search/actions'

import MultiTableResults from './MultiTableResults'
import ExplorerResults from './ExplorerResults'
import FlattenedResults from './FlattenedResults'
import TimeoutLoader from 'components/TimeoutLoader'
import messages from './messages'
import permissionChecker from 'containers/PermissionChecker'

export class SearchResults extends React.Component {
  constructor(props) {
    super(props)
    if (props.tagOptions.isEmpty()) {
      //Tags are used to populate a dropdown in AddTagsModal
      props.fetchTags()
    }

    this.styles = {
      container: {
        height: '100%',
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        gridTemplateAreas: '"tabs" "content"',
        paddingTop: '0.5em',
      },
    }

    this.viewTabs = [
      {
        id: RESULT_VIEW_TYPES.TABLE,
        text: (
          <span>
            <Icon fa name="object-ungroup" /> Cards (multiple tables)
          </span>
        ),
      },
      {
        id: RESULT_VIEW_TYPES.FLATTENED,
        text: (
          <span>
            <Icon fa name="table" /> Combined (single table)
          </span>
        ),
      },
      {
        id: RESULT_VIEW_TYPES.EXPLORER,
        text: (
          <span>
            <Icon fa name="chart-network" /> Relations Explorer
          </span>
        ),
      },
    ]
  }

  handleRowDoubleClick = (srn, type) => {
    this.props.push(getNodeViewPushParams(srn, type))
  }

  saveTableLayout = (resultViewId, newLayout) => {
    this.props.updateResultView({
      resultViewId: resultViewId,
      gridState: newLayout.gridState,
    })
  }

  handleCancelExplorer = () => {
    this.setView(RESULT_VIEW_TYPES.TABLE)
  }

  setView = view => {
    this.props.setDefaultView(view)

    const search = qs.parse(this.props.location.search)
    this.props.push({
      search: qs.stringify({
        ...search,
        resultView: view,
      }),
    })

    //TODO: only rerun if switching between table view and another
    this.props.runQuery({
      flatten: view !== RESULT_VIEW_TYPES.TABLE,
    })
  }

  changeTab = index => {
    const selectedTab = this.viewTabs[index]
    this.setView(selectedTab.id)
  }

  renderContent() {
    const canView = this.props.userHasPermission({
      permissionName: 'view.data',
    })

    if (this.props.error) {
      let error = this.props.error
      if (error.includes('status code 504')) {
        error =
          'Unfortunately, your search has timed out. Please try again later.'
      }
      if (canView) {
        return (
          <BorderedCard>
            <Error
              errorMessage={error}
              description="There was an error loading the data"
            />
          </BorderedCard>
        )
      } else {
        return (
          <BorderedCard>
            <Error
              errorMessage={`Insufficent Permissions`}
              description="Requires view.data permission"
            />
          </BorderedCard>
        )
      }
    }

    if (this.props.queryLoading) {
      return (
        <CenterContent>
          <div>
            <div>
              {this.props.searchName
                ? this.props.searchName
                : this.props.rootQuery.getIn(['name'])}
            </div>
            <TimeoutLoader
              firstMessage="Working on your query..."
              finalMessage="Unfortunately, your search has timed out. Please try again later."
            />
          </div>
        </CenterContent>
      )
    }

    if (this.props.queryResults.first(Map()).get('items', List()).isEmpty()) {
      return (
        <BorderedCard>
          <CenterContent style={{ height: '100%' }}>
            <EmptySection
              style={{ fontSize: '1em', fontWeight: '400' }}
              icon={<Icon name="cloud" fa transform="grow-10" />}
            >
              <p style={{ marginTop: '0.5em' }}>
                <FormattedMessage {...messages.noResults} />
              </p>
            </EmptySection>
          </CenterContent>
        </BorderedCard>
      )
    }

    if (this.props.defaultView == RESULT_VIEW_TYPES.EXPLORER) {
      return (
        <ExplorerResults
          onCancel={this.handleCancelExplorer}
          results={this.props.queryResults}
        />
      )
    }

    if (this.props.defaultView === RESULT_VIEW_TYPES.FLATTENED) {
      return (
        <FlattenedResults
          exportFileName={
            this.props.searchName
              ? `${this.props.searchName} results`
              : 'Search Results'
          }
          handleRowDoubleClick={this.handleRowDoubleClick}
          layout={this.props.resultViews.get('flattened') || Map()}
          query={this.props.queryStatements}
          relations={this.props.relations}
          results={this.props.queryResults}
          saveLayout={this.saveTableLayout.bind(this, 'flattened')}
        />
      )
    }

    return (
      <MultiTableResults
        saveLayout={this.saveTableLayout}
        queryResultCards={this.props.queryResultCards}
        handleRowDoubleClick={this.handleRowDoubleClick}
        query={this.props.queryStatements}
        relations={this.props.relations}
        results={this.props.queryResults}
        rootDefinitionName={this.props.rootQuery.getIn(['definition', 'name'])}
        exportFileName={
          this.props.searchName
            ? `${this.props.searchName} results`
            : 'Search Results'
        }
        removeResultView={this.props.removeResultView}
      />
    )
  }

  render() {
    const activeTabIndex = this.viewTabs.findIndex(
      tab => tab.id === this.props.defaultView
    )

    return (
      <div style={this.styles.container}>
        <div style={{ marginBottom: '0.3em' }}>
          <TabBar
            small
            changeTab={this.changeTab}
            activeTab={activeTabIndex}
            tabs={this.viewTabs}
          />
        </div>
        {this.renderContent()}
      </div>
    )
  }
}

SearchResults.propTypes = {
  defaultView: PropTypes.string,
  error: PropTypes.string,
  push: PropTypes.func,
  queryLoading: PropTypes.bool,
  queryResultCards: ImmutablePropTypes.listOf(
    ImmutablePropTypes.contains({
      fieldId: PropTypes.string,
      id: PropTypes.string,
    })
  ).isRequired,
  queryResults: ImmutablePropTypes.map.isRequired,
  queryStatements: ImmutablePropTypes.mapOf(
    ImmutablePropTypes.contains({
      fieldId: PropTypes.string,
      id: PropTypes.string,
    })
  ).isRequired,
  relations: ImmutablePropTypes.iterable.isRequired,
  removeResultView: PropTypes.func.isRequired,
  resultViews: ImmutablePropTypes.map.isRequired,
  rootQuery: ImmutablePropTypes.map.isRequired,
  runQuery: PropTypes.func.isRequired,
  setDefaultView: PropTypes.func.isRequired,
  updateResultView: PropTypes.func.isRequired,
  fetchTags: PropTypes.func.isRequired,
  searchName: PropTypes.string,
  tagOptions: ImmutablePropTypes.list,
  location: PropTypes.object.isRequired,
  userHasPermission: PropTypes.func,
}

const mapStateToProps = createStructuredSelector({
  error: selectError,
  defaultView: selectResultView,
  queryStatements: selectQueryStatements,
  queryLoading: selectQueryLoading,
  queryResultCards: selectResultCards,
  queryResults: selectQueryResults,
  rootQuery: selectRootQuery,
  resultViews: selectResultViews,
  relations: selectQueryRelations,
  searchName: selectSearchName,
  tagOptions: selectTags,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeResultView,
      runQuery,
      updateResultView,
      push,
      setDefaultView,
      fetchTags,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(
  withRouter,
  withConnect,
  permissionChecker
)(SearchResults)
