import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose } from 'redux'
import { Map, List } from 'immutable'
import _ from 'lodash'
import { injectIntl, FormattedMessage, intlShape } from 'react-intl'

import CenterContent from 'components/CenterContent'
import FilterInput from './FilterInput'
import {
  selectQueryTypes,
  selectAllowedRootQueries,
  selectSavedSearches,
} from 'containers/SonraiData/selectors'
import {
  selectNodeTypeCounts,
  selectNodeTypeCountsLoading,
} from 'containers/Search/selectors'
import TabbedScrollable, {
  TabbedScrollableSection,
} from 'components/TabbedScrollable'
import mappings from 'searchMappings.json'
import { keywordMatch, stripExtraChars } from 'utils/sonraiUtils'

import {
  getMatchStrength,
  getCloudMatch,
  validateMappings,
} from './filterUtils'
import NodeType from './NodeType'
import messages from './messages'

export class SearchLanding extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      searchFilter: '',
      searchFilterInput: '',
    }

    this.styles = {
      container: {
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        gridTemplateAreas: '"searchbox" "cards"',
        height: '100%',
      },
      filter: {
        paddingBottom: '1em',
      },
      cards: {
        gridArea: 'cards',
        overflow: 'hidden',
      },
      sectionHeader: {
        paddingBottom: '0.2em',
        borderBottom: '1px solid #eee',
        marginBottom: '1em',
      },
    }

    this.mappings = validateMappings(this.getQueries(), props.queryTypes)
  }

  setFilterText = value => {
    this.setState({
      searchFilter: value,
    })
  }

  getQueries = () => {
    // get the queries it's allowed to make
    return this.props.queryTypes
      .get('fields')
      .filter(field =>
        this.props.allowedRootQueries.includes(field.get('name'))
      )
      .reduce(
        (queryFields, queryField) =>
          queryFields.set(queryField.get('name'), queryField),
        Map()
      )
  }

  getSearchValue = () => {
    return this.state.searchFilter.trim().toLowerCase() || ''
  }

  getSearchesByNodeType = searches => {
    return searches.reduce((nodeTypes, search) => {
      const rootCard =
        search
          .getIn(['query', 'fields'], Map())
          .find(field => !field.get('parentId')) || Map()
      const rootCardType = rootCard
        .getIn(['definition', 'type', 'name'], '')
        .replace('EdgeRelation', '')

      return nodeTypes.update(rootCardType.toLowerCase(), List(), list =>
        list.push(search)
      )
    }, Map())
  }

  getSavedSearchMatchStrength = (search, savedSearch) => {
    let strength = 0
    const name = stripExtraChars(savedSearch.get('name') || '')
    const description = stripExtraChars(savedSearch.get('description') || '')
    const allComments = savedSearch
      .getIn(['query', 'fields'], Map())
      .toList()
      .map(card => (card.get('comments') ? card.get('comments').join() : ' '))
      .filter(val => !!val)
      .join(' ')
    const comments = stripExtraChars(allComments)

    //High value exact match
    if (search === name) {
      strength += 1000
    }

    //Medium value contains match
    if (name.includes(search)) {
      strength += 100
    }

    //Loose match on some words
    if (keywordMatch(name, search)) {
      strength += 1
    }

    //Medium value contains match on descriptions, which are visible in a tooltip
    if (description && description.includes(search)) {
      strength += 100
    }

    //Loose match on description, which are visible in a tooltip
    if (description && keywordMatch(description, search)) {
      strength += 1
    }

    //Comments aren't visible from the search screen, so we make these a weak match
    if (
      comments &&
      (comments.includes(search) || keywordMatch(comments, search))
    ) {
      strength += 1
    }

    return strength
  }

  getSavedSearchMatches = search => {
    if (!search) {
      return Map()
    }

    const strippedSearch = stripExtraChars(search)

    const matchingSavedSearches = this.props.savedSearches
      .map(savedSearch => {
        const strength = this.getSavedSearchMatchStrength(
          strippedSearch,
          savedSearch
        )

        return savedSearch.set('strength', strength)
      })
      .filter(search => search.get('strength') > 0)

    const searchesByNodeType = this.getSearchesByNodeType(matchingSavedSearches)

    return searchesByNodeType
  }

  getAllContent = () => {
    const savedSearchesByNodeType = this.getSearchesByNodeType(
      this.props.savedSearches
    )

    return this.mappings.map(node => {
      const savedSearchMatches =
        savedSearchesByNodeType.get((node.nodeType || '').toLowerCase()) ||
        List()

      return {
        ...node,
        savedSearches: savedSearchMatches.toJS(),
      }
    })
  }

  getCloudsForSearch = search => {
    const keywords = search.replace(/\s\s+/g, ' ').split(' ') || []
    const clouds = []
    keywords.forEach(keyword => {
      const cloudMatch = getCloudMatch(keyword)
      if (cloudMatch) {
        clouds.push(cloudMatch)
      }
    })

    return clouds
  }

  getSearchesForNodeType = (nodeType, savedSearches) => {
    let savedSearchMatches =
      savedSearches.get((nodeType || '').toLowerCase()) || List()

    return savedSearchMatches
      .sortBy(search => search.get('strength'))
      .reverse()
      .toJS()
  }

  getFilteredMappings = () => {
    const search = this.getSearchValue()

    if (!search) {
      return this.getAllContent()
    }

    let strengths = []
    const savedSearchesByNodetype = this.getSavedSearchMatches(search)

    const clouds = this.getCloudsForSearch(search)
    const hasCloud = clouds.length > 0

    this.mappings.forEach(node => {
      const augmentedNode = { ...node }

      augmentedNode.savedSearches = this.getSearchesForNodeType(
        node.nodeType,
        savedSearchesByNodetype
      )

      const nodeMatchStrength = getMatchStrength(node, search)
      const savedSearchMatchStrength = augmentedNode.savedSearches.reduce(
        (sum, search) => sum + search.strength,
        0
      )

      let subtypeStrength = 0
      const subTypes = (node.subTypes || []).map(subtype => {
        subtype.strength = getMatchStrength(subtype, search)
        subtypeStrength += subtype.strength / 4
        return subtype
      })

      let totalStrength =
        nodeMatchStrength + savedSearchMatchStrength + subtypeStrength

      //If we have no cloud matches and we have a top-level node match, show all subtypes
      if (nodeMatchStrength > 0 && !hasCloud) {
        strengths.push({
          node: augmentedNode,
          strength: totalStrength,
        })

        return
      } else if (nodeMatchStrength >= 10000 && hasCloud) {
        //If we have a STRONG top-level node match and a cloud, show only the subtypes that match the cloud(s)
        augmentedNode.subTypes = (node.subTypes || []).filter(
          subtype => subtype.cloud && clouds.includes(subtype.cloud)
        )

        strengths.push({
          node: augmentedNode,
          strength: totalStrength,
        })

        return
      }

      augmentedNode.subTypes = subTypes.filter(subtype => {
        return subtype.strength > 0
      })

      if (totalStrength > 0) {
        strengths.push({
          node: augmentedNode,
          strength: totalStrength,
        })
      }
    })

    strengths = _.orderBy(strengths, 'strength', 'desc')
    return strengths.map(entry => entry.node)
  }

  renderNodeTypes = () => {
    const search = this.getSearchValue()
    const queryDefs = this.getQueries()
    const filteredMappings = this.getFilteredMappings()

    if (filteredMappings.length === 0) {
      return (
        <CenterContent style={{ height: '100%' }}>
          <FormattedMessage {...messages.noMatch} />
        </CenterContent>
      )
    }
    const groupings = mappings.topGroupings
    if (search) {
      return (
        <div style={{ overflow: 'auto', height: '100%', paddingTop: '10px' }}>
          {filteredMappings.map(type => (
            <NodeType
              nodeTypeMap={this.props.nodeTypeCounts}
              key={type.key}
              type={type}
              definition={
                queryDefs.get(_.get(type, ['filters', 'label'])) ||
                queryDefs.get('Entities')
              }
              startQuery={this.props.startQuery}
              onSelectSavedSearch={this.props.onSelectSavedSearch}
            />
          ))}
        </div>
      )
    } else {
      return (
        <TabbedScrollable>
          {groupings.map(group => {
            const types = filteredMappings.filter(
              type => type.topGrouping === group.key
            )
            if (types.length === 0) {
              return null
            }
            return (
              <TabbedScrollableSection
                sectionKey={group.key}
                key={group.key}
                displayLabel={
                  <span>
                    <FormattedMessage {...messages[group.key]} />
                  </span>
                }
              >
                <div style={this.styles.sectionHeader}>
                  <FormattedMessage {...messages[group.key]} />
                </div>
                {types.map(type => (
                  <NodeType
                    key={type.key}
                    nodeTypeMap={this.props.nodeTypeCounts}
                    nodeTypeCountsLoading={this.props.nodeTypeCountsLoading}
                    type={type}
                    search={search}
                    definition={
                      queryDefs.get(_.get(type, ['filters', 'label'])) ||
                      queryDefs.get('Entities')
                    }
                    startQuery={this.props.startQuery}
                    onSelectSavedSearch={this.props.onSelectSavedSearch}
                  />
                ))}
              </TabbedScrollableSection>
            )
          })}
        </TabbedScrollable>
      )
    }
  }

  render() {
    return (
      <div style={this.styles.container}>
        <div style={this.styles.filter}>
          <FilterInput
            onChange={this.setFilterText}
            placeholder={this.props.intl.formatMessage(
              messages.filterPlaceholder
            )}
          />
        </div>
        <div style={this.styles.cards}>{this.renderNodeTypes()}</div>
      </div>
    )
  }
}

SearchLanding.propTypes = {
  allowedRootQueries: ImmutablePropTypes.list.isRequired,
  intl: intlShape,
  onSelectSavedSearch: PropTypes.func.isRequired,
  queryTypes: ImmutablePropTypes.contains({
    fields: ImmutablePropTypes.listOf(
      ImmutablePropTypes.contains({
        name: PropTypes.string,
      })
    ),
  }).isRequired,
  savedSearches: ImmutablePropTypes.iterable.isRequired,
  startQuery: PropTypes.func.isRequired,
  nodeTypeCounts: ImmutablePropTypes.iterable.isRequired,
  nodeTypeCountsLoading: PropTypes.bool,
}

const mapStateToProps = createStructuredSelector({
  allowedRootQueries: selectAllowedRootQueries,
  queryTypes: selectQueryTypes,
  savedSearches: selectSavedSearches,
  nodeTypeCounts: selectNodeTypeCounts,
  nodeTypeCountsLoading: selectNodeTypeCountsLoading,
})

const withConnect = connect(mapStateToProps)

export default compose(withConnect, injectIntl)(SearchLanding)
