import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Input } from 'reactstrap'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List, Map } from 'immutable'
import SelectBar from 'components/SelectBar'
import { injectIntl } from 'react-intl'
import { compose } from 'redux'
import globalMessages from 'globalTranslations'
import messages from './messages'
import { QUERY_FILTER_OP } from 'appConstants'
import { exists } from 'utils/sonraiUtils'
import Icon from 'components/Icon'
import IHelp from 'containers/IHelp'
import Papa from 'papaparse'
import { PlainCheckbox } from 'components/Checkbox'
const styles = {
  optionsDropdown: {
    marginBottom: '0.5em',
  },
}

class StringOperator extends React.Component {
  constructor(props) {
    super(props)
    this.fileInput = React.createRef()

    this.styles = {
      options: {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        gridColumnGap: '1em',
      },
    }
  }

  onOperationChange = val => {
    if (val !== null) {
      const op = val.value
      if (op === QUERY_FILTER_OP.IS_NULL) {
        this.props.onChange(
          Map({
            op: 'EQ',
            value: null,
          })
        )
      } else if (op === QUERY_FILTER_OP.IS_NOT_NULL) {
        this.props.onChange(
          Map({
            op: 'NEQ',
            value: null,
          })
        )
      } else {
        this.props.onChange(
          Map({
            op: val.value,
            value:
              this.props.argumentValue.get('value') == null
                ? ''
                : this.props.argumentValue.get('value'),
          })
        )
      }
    } else {
      this.props.removeArgument()
    }
  }

  onValueChange = ({ target: { value } }) => {
    this.props.onChange(Map({ value }))
  }

  onCaseSensitiveChange = () => {
    this.props.onChange(
      Map({
        caseSensitive: !this.props.argumentValue.get('caseSensitive'),
      })
    )
  }
  getVal = () => {
    if (this.props.argumentValue.get('value') === null) {
      if (this.props.argumentValue.get('op') === `NEQ`) {
        return QUERY_FILTER_OP.IS_NOT_NULL
      } else {
        return QUERY_FILTER_OP.IS_NULL
      }
    } else {
      return this.props.argumentValue.get('op')
    }
  }

  renderOperationsSelection = () => {
    if (this.props.opType.isEmpty()) {
      return null
    }

    const options = this.props.opType.get('enumValues', List()).toJS()
    options.push({ name: QUERY_FILTER_OP.IS_NULL })
    options.push({ name: QUERY_FILTER_OP.IS_NOT_NULL })
    options.push({ name: QUERY_FILTER_OP.NOT_REGEX })

    const filteredOptions = options.filter(x => exists(x.name))

    return (
      <div style={styles.optionsDropdown}>
        <SelectBar
          options={filteredOptions.map(op => ({
            label: this.props.intl.formatMessage(
              globalMessages.operations[op.name]
            ),
            value: op.name,
          }))}
          onChange={this.onOperationChange}
          value={this.getVal()}
          isClearable={false}
        />
      </div>
    )
  }

  handleFileUpload = data => {
    const value = data.join('\n') // TODO: This should maybe be a newline char
    this.props.onChange(Map({ value }))
  }

  onFileChange = (e, fileIn) => {
    const file = fileIn || e.target.files[0],
      reader = new FileReader()

    reader.onload = () => {
      Papa.parse(file, {
        complete: results => {
          this.handleFileUpload(results.data[0])
        },
      })
    }

    reader.readAsDataURL(file)
  }

  toggleCsv = () => {
    this.props.onChange(
      Map({
        noCsv: !this.props.argumentValue.get('noCsv'),
      })
    )
  }

  render() {
    return (
      <div>
        {this.renderOperationsSelection()}
        {this.props.argumentValue.get('value') !== null && (
          <Fragment>
            <Input
              type="textarea"
              value={
                !this.props.argumentValue.get('value') &&
                this.props.argumentValue.get('values')
                  ? this.props.argumentValue.get('values').join(',')
                  : this.props.argumentValue.get('value')
              }
              onChange={this.onValueChange}
              style={{
                width:
                  this.props.argumentValue.get('op') !==
                    QUERY_FILTER_OP.REGEX ||
                  this.props.argumentValue.get('op') !==
                    QUERY_FILTER_OP.NOT_REGEX
                    ? 'calc(100% - 40px)'
                    : '100%',
                display: 'inline',
              }}
            />
            {this.props.argumentValue.get('op') !== QUERY_FILTER_OP.REGEX && (
              <Fragment>
                <div
                  style={{
                    verticalAlign: 'top',
                    marginLeft: '10px',
                    marginTop: '5px',
                    width: '30px',
                    display: 'inline-block',
                  }}
                >
                  <IHelp
                    id="multiFilterValue"
                    help
                    position="right"
                    helpKey="multiFilterValue"
                  />
                  <label
                    style={{
                      cursor: 'pointer',
                      marginTop: '5px',
                    }}
                  >
                    <Icon fa name="upload" title="Upload CSV" />
                    <input
                      type="file"
                      onChange={this.onFileChange}
                      ref={this.fileInput}
                      accept=".xlsx, .xls, .csv"
                      style={{ display: 'none' }}
                    />
                  </label>
                </div>

                <div style={this.styles.options}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <PlainCheckbox
                      checked={this.props.argumentValue.get('caseSensitive')}
                      onChange={this.onCaseSensitiveChange}
                    />
                    <small>
                      <FormattedMessage {...messages.caseSensitive} />
                    </small>
                  </div>

                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <PlainCheckbox
                      checked={!this.props.argumentValue.get('noCsv')}
                      onChange={this.toggleCsv}
                    />
                    <small>
                      <FormattedMessage {...messages.csv} />
                    </small>
                  </div>
                </div>
              </Fragment>
            )}
          </Fragment>
        )}
      </div>
    )
  }
}

StringOperator.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  argumentValue: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  removeArgument: PropTypes.func.isRequired,
  opType: ImmutablePropTypes.map.isRequired,
}

export default compose(injectIntl)(StringOperator)
