import React from 'react'
import PropTypes from 'prop-types'

export const Directions = {
  DOWNWARD: 'app/components/ConnectorCaret/Directions/DOWNWARD',
  UPWARD: 'app/components/ConnectorCaret/Directions/UPWARD',
  RIGHT: 'app/components/ConnectorCaret/Directions/RIGHT',
}

export const Alignment = {
  MIDDLE: 'app/components/ConnectorCaret/Alignment/MIDDLE',
  RIGHT: 'app/components/ConnectorCaret/Alignment/RIGHT',
  BOTTOM: 'app/components/ConnectorCaret/Alignment/BOTTOM',
}

class ConnectorCaret extends React.PureComponent {
  constructor(props) {
    super(props)
    this.styles = {
      carets: {
        container: {
          width: '100%',
        },
        [Directions.DOWNWARD]: {
          border: {
            width: 0,
            height: 0,
            borderLeft: '12px solid transparent',
            borderRight: '12px solid transparent',
            borderTop: '12px solid rgb(231, 231, 233)',
            margin: 'auto',
          },
          caret: {
            width: 0,
            height: 0,
            borderLeft: '11.5px solid transparent',
            borderRight: '11.5px solid transparent',
            borderTop: '11.5px solid white',
            margin: 'auto',
            position: 'relative',
            top: '-13px',
            left: '-11.25px',
          },
        },
        [Directions.UPWARD]: {
          border: {
            width: 0,
            height: 0,
            borderLeft: '1em solid transparent',
            borderRight: '1em solid transparent',
            borderBottom: '1.1em solid rgb(231, 231, 233)',
            margin: 'auto',
          },
          caret: {
            width: 0,
            height: 0,
            borderLeft: '1em solid transparent',
            borderRight: '1em solid transparent',
            borderBottom: '1.1em solid white',
            margin: 'auto',
            position: 'relative',
            top: '1.75px',
            left: '-1em',
          },
        },
        [Directions.RIGHT]: {
          border: {
            width: 0,
            height: 0,
            borderLeft: '12px solid rgb(231, 231, 233)',
            borderTop: '12px solid transparent',
            borderBottom: '12px solid transparent',
            margin: 'auto',
            marginTop: props.verticalOffset || 0,
          },
          caret: {
            width: 0,
            height: 0,
            borderLeft: '11.5px solid white',
            borderTop: '11.5px solid transparent',
            borderBottom: '11.5px solid transparent',
            margin: 'auto',
            position: 'relative',
            top: '-11.25px',
            left: '-13px',
          },
        },
        [Alignment.RIGHT]: { marginRight: props.horizontalOffset || 0 },
        [Alignment.MIDDLE]: {},
        [Alignment.BOTTOM]: { marginBottom: props.verticalOffset || 0 },
      },
    }
  }

  render() {
    const borderStyle = {
      ...this.styles.carets[this.props.direction].border,
      ...this.styles.carets[this.props.align],
    }

    return (
      <div className="connector-caret" style={this.styles.carets.container}>
        <div
          className={`connector-caret-border-${this.props.direction}`}
          style={borderStyle}
        >
          <div
            className={`connector-caret-${this.props.direction}`}
            style={this.styles.carets[this.props.direction].caret}
          />
        </div>
      </div>
    )
  }
}

ConnectorCaret.propTypes = {
  align: PropTypes.oneOf(Object.values(Alignment)),
  direction: PropTypes.oneOf(Object.values(Directions)),
  horizontalOffset: PropTypes.string,
  verticalOffset: PropTypes.string,
}

export default ConnectorCaret
