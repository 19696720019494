import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { injectIntl, intlShape } from 'react-intl'
import _ from 'lodash'
import { Map } from 'immutable'

import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import Hoverable from 'components/Hoverable'
import Icon from 'components/Icon'
import BorderedCard from 'components/BorderedCard'
import SectionHeader from 'components/SectionHeader'
import { getIconMapping } from 'utils/sonraiUtils'
import BorderlessButton from 'components/BorderlessButton'

import SavedSearchSubType from './SavedSearchSubType'
import messages from './messages.js'
import SubType from './SubType'
import numeral from 'numeral'

export class NodeType extends React.Component {
  constructor(props) {
    super(props)

    this.styles = {
      nodeType: {
        display: 'grid',
        gridTemplateColumns: '40% 1fr',
        gridColumnGap: '1em',
        marginBottom: '1em',
        paddingLeft: '0.3em',
      },
      mainNodeCard: {
        height: '100%',
      },
      nodeTitle: {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr auto',
        gridColumnGap: '0.5em',
      },
      nodeDescription: {
        fontSize: '0.9em',
      },
      hovered: {
        backgroundColor: props.theme.highlight,
      },
    }
  }

  gotoResults = type => {
    const def = this.props.definition.set(
      'displayName',
      type.defaultDisplayName
    )

    this.props.startQuery(def, true, type.filters)
  }

  onClickType = e => {
    e.stopPropagation()
    this.gotoBuilder(this.props.type)
  }

  gotoBuilder = type => {
    const def = this.props.definition.set(
      'displayName',
      type.defaultDisplayName
    )

    this.props.startQuery(def, false, type.filters)
  }

  gotoSavedSearchBuilder = searchId => {
    this.props.onSelectSavedSearch(searchId, true)
  }

  gotoSavedSearchResults = searchId => {
    this.props.onSelectSavedSearch(searchId, false)
  }

  onClickTopLevel = () => {
    this.gotoResults(this.props.type)
  }

  keyPressed = event => {
    if (event.key === 'Enter') {
      this.onClickTopLevel()
    }
  }

  getDefaultDisplayName = () => {
    const { nodeTypeMap = Map(), type } = this.props
    const { defaultDisplayName, nodeType } = type
    const count = nodeTypeMap.getIn([nodeType, 'count'], 0)

    if (this.props.nodeTypeCountsLoading) {
      return defaultDisplayName
    }

    const name = `${defaultDisplayName} (${numeral(count).format('0a')})`
    return name
  }

  render() {
    const icon = getIconMapping(this.props.type.nodeType).faIcon
    const iconColor = this.props.type.nodeType
      ? this.props.typeColors[this.props.type.nodeType.toLowerCase()]
      : undefined
    const subTypes = this.props.type.subTypes || []
    const savedSearches = this.props.type.savedSearches || []

    const combined = subTypes.concat(savedSearches)
    const sortedByStrength = _.orderBy(combined, ['strength'], ['desc'])

    return (
      <div style={this.styles.nodeType}>
        <div
          onClick={this.onClickTopLevel}
          tabIndex={0}
          onKeyDown={this.keyPressed}
        >
          <Hoverable
            style={{ height: '100%' }}
            renderContent={({ hovered }) => (
              <BorderedCard
                style={
                  hovered
                    ? { ...this.styles.mainNodeCard, ...this.styles.hovered }
                    : this.styles.mainNodeCard
                }
              >
                <SectionHeader small style={this.styles.nodeTitle}>
                  <Icon fa name={icon} color={iconColor} />
                  {this.getDefaultDisplayName()}
                  {hovered && (
                    <BorderlessButton
                      onClick={this.onClickType}
                      title={this.props.intl.formatMessage(
                        messages.openInBuilder
                      )}
                    >
                      <Icon fa name="tools" />
                    </BorderlessButton>
                  )}
                </SectionHeader>
                <p style={this.styles.nodeDescription}>
                  {this.props.type.defaultDescription}
                </p>
              </BorderedCard>
            )}
          />
        </div>

        <div>
          {sortedByStrength.map(entity =>
            entity.sid ? (
              <SavedSearchSubType
                key={entity.sid}
                search={entity}
                gotoSavedSearchBuilder={this.gotoSavedSearchBuilder}
                gotoSavedSearchResults={this.gotoSavedSearchResults}
                nodeType={this.props.type.nodeType}
              />
            ) : (
              <SubType
                key={entity.key}
                subtype={entity}
                gotoBuilder={this.gotoBuilder}
                gotoResults={this.gotoResults}
                nodeType={this.props.type.nodeType}
              />
            )
          )}
        </div>
      </div>
    )
  }
}

NodeType.propTypes = {
  definition: ImmutablePropTypes.map,
  intl: intlShape,
  startQuery: PropTypes.func.isRequired,
  theme: themeShape,
  typeColors: PropTypes.object.isRequired,
  type: PropTypes.shape({
    defaultDescription: PropTypes.string,
    defaultDisplayName: PropTypes.string,
    subTypes: PropTypes.array,
    nodeType: PropTypes.string.isRequired,
    savedSearches: PropTypes.array,
  }),
  onSelectSavedSearch: PropTypes.func,
  nodeTypeMap: ImmutablePropTypes.iterable.isRequired,
  nodeTypeCountsLoading: PropTypes.bool,
}

export default injectIntl(themeable(NodeType))
