import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl, intlShape } from 'react-intl'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List, fromJS, Map, isImmutable } from 'immutable'
import { compose } from 'redux'
import _ from 'lodash'
import uuid from 'uuid/v4'
import { getFieldFiltersType, getFilterProperties } from 'query-builder'
import { getSupportedFilterFields } from 'utils/sonraiUtils'
import ImmutablePureComponent from 'components/ImmutablePureComponent'
import QueryCardFieldFilter from 'components/QueryCardFieldFilter'
import SelectBar from 'components/SelectBar'
import FormLabel from 'components/FormLabel'
import Button from 'components/Button'
import TextLink from 'components/TextLink'
import messages from 'globalTranslations'
import IHelp from 'containers/IHelp'
class EditFilter extends ImmutablePureComponent {
  styles = {
    container: {
      width: '400px',
    },
    footer: {
      marginTop: '1em',
      display: 'flex',
      alignItems: 'center',
    },
    submitButton: {
      marginLeft: 'auto',
    },
    cancelButton: {
      marginRight: '0.5em',
    },
  }

  constructor(props) {
    super(props)

    let filterFieldType

    if (this.props.filterFieldType) {
      filterFieldType = this.props.filterFieldType
    } else {
      const filterArgDef = this.props.field
        .getIn(['definition', 'args'], List())
        .find(argDef => argDef.get('name') === 'where')

      filterFieldType = filterArgDef.getIn(['type', 'name'])
    }

    const fieldDef = props.types
      .getIn([filterFieldType, 'inputFields'], List())
      .find(inputField => inputField.get('name') === props.propertyName)

    let notFilter = false
    this.props.field
      .getIn(['arguments', this.props.opGroup || 'and'], List())
      .forEach(propName => {
        if (propName.get(this.props.propertyName)) {
          notFilter = propName.getIn(
            [this.props.propertyName, 'notFilter'],
            false
          )
        }
      })

    this.state = {
      fieldDef: fieldDef,
      propertyName: this.props.propertyName,
      value: this.props.value || Map(),
      opGroup: this.props.opGroup || 'and',
      id: this.props.value ? this.props.value.get('id') : uuid(),
      notFilter,
    }
  }

  handleCancel = () => {
    if (this.props.endHover) {
      this.props.endHover()
    }
    this.props.toggle()
  }

  submit = () => {
    if (this.props.endHover) {
      this.props.endHover()
    }
    if (this.state.value === null) {
      this.props.removeArgument()
    } else {
      this.props.onSubmit({
        value: {
          [this.state.propertyName]: {
            ...this.state.value.toJS(),
            id: this.state.id,
            notFilter: this.state.notFilter,
          },
        },
        fieldId: this.props.field.get('id'),
        opGroup: this.state.opGroup,
        id: this.state.id,
        type: this.state.option,
      })
    }
  }

  setValue = value => {
    if (
      value.get('value') !== false &&
      !value.get('value') &&
      value.get('value') !== null
    ) {
      value = value.set('value', value.getIn(['values', 0], ''))
    }
    this.setState({
      value,
    })
  }

  clearArgument = () => {
    this.setState({
      value: null,
    })
  }

  onSelectProperty = selectedValue => {
    if (selectedValue) {
      this.setState({
        fieldDef: fromJS(selectedValue.def),
        propertyName: selectedValue.value,
        value: Map(),
        opGroup: 'and',
        id: uuid(),
        option: selectedValue,
      })
    } else {
      this.setState({
        propertyName: null,
        value: Map(),
        fieldDef: Map(),
        option: null,
        notFilter: false,
      })
    }
  }

  toggleNotFilter = () => {
    this.setState(prev => ({ notFilter: !prev.notFilter }))
  }

  render() {
    const filterFieldType =
      this.props.filterFieldType || getFieldFiltersType(this.props.field)

    const scalarFilterFields = getFilterProperties(
      filterFieldType,
      this.props.types
    )

    const supportedFilters = getSupportedFilterFields(
      filterFieldType,
      this.props.types
    )

    return (
      <div style={this.styles.container}>
        <FormLabel label="Property" />
        <SelectBar
          autoFocus
          value={this.props.propertyName}
          options={scalarFilterFields
            .toJS()
            .map(inputFieldDef => {
              return {
                def: inputFieldDef,
                value: inputFieldDef.name,
                label: this.props.intl.formatMessage(
                  messages.fields[inputFieldDef.name] || {
                    id: 'unknown',
                    defaultMessage: _.startCase(inputFieldDef.name),
                  }
                ),
              }
            })
            .sort((a, b) => a.label.localeCompare(b.label))}
          onChange={this.onSelectProperty}
          isDisabled={!this.props.addNew}
        />

        {this.state.fieldDef && (
          <div style={{ marginTop: '0.5em' }}>
            <QueryCardFieldFilter
              filterFieldType={filterFieldType}
              argumentValue={this.state.value}
              inputType={this.state.fieldDef.getIn(['type', 'name'])}
              onChange={this.setValue}
              types={this.props.types}
              removeArgument={this.clearArgument}
              fieldName={this.state.propertyName}
              supportedFilters={supportedFilters}
            />
          </div>
        )}

        <div style={this.styles.footer}>
          {this.state.fieldDef && supportedFilters.includes('not') && (
            <div
              style={{
                display: 'flex',
                gridTemplateColumns: 'auto 1fr',
                columnGap: '0.25em',
              }}
            >
              <div style={{ marginTop: '0.25em' }}>
                <IHelp
                  id="notSearchFilter"
                  help
                  position="right"
                  helpKey="notSearchFilter"
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  gridTemplateColumns: 'auto 1fr',
                  columnGap: '0.25em',
                  cursor: 'pointer',
                }}
                onClick={this.toggleNotFilter}
              >
                <div
                  style={{
                    fontWeight: 400,
                    marginTop: '0.35em',
                  }}
                >
                  Not Filter
                </div>

                <input
                  style={{ marginTop: '0.65em' }}
                  name="isNot"
                  type="checkbox"
                  checked={this.state.notFilter}
                />
              </div>
            </div>
          )}
          <div style={this.styles.submitButton}>
            <TextLink
              color="primary"
              onClick={this.handleCancel}
              style={this.styles.cancelButton}
            >
              Cancel
            </TextLink>
            <Button
              color="primary"
              onClick={this.submit}
              disabled={
                isImmutable(this.state.value) && this.state.value.isEmpty()
              }
            >
              {this.props.addNew ? 'Add' : 'Update'}
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

EditFilter.propTypes = {
  field: ImmutablePropTypes.contains({
    id: PropTypes.string,
  }),
  filterFieldType: PropTypes.string,
  setBuilderChange: PropTypes.func,
  intl: intlShape,
  removeArgument: PropTypes.func.isRequired,
  toggle: PropTypes.func,
  types: ImmutablePropTypes.contains({
    inputFields: ImmutablePropTypes.listOf(
      ImmutablePropTypes.contains({
        name: PropTypes.string,
        type: ImmutablePropTypes.contains({
          name: PropTypes.string,
        }),
      })
    ),
  }),
  endHover: PropTypes.func,
}

export default compose(injectIntl)(EditFilter)
