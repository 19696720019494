import { createSelector } from 'reselect'
import { Map, List, fromJS, OrderedMap } from 'immutable'
import qs from 'query-string'
import {
  selectQueryTypes,
  selectSavedSearches,
} from 'containers/SonraiData/selectors'
import { RESULT_VIEW_TYPES, QueryBuilder } from 'query-builder'
import { SEARCH_VIEWS } from './constants'

import {
  selectRootQuery,
  selectQueryStatements,
  selectResultViews,
  selectConvertedFromLegacy,
} from 'containers/SearchQuery/selectors'

export const selectSearch = state => state.get('search') || Map()

export const selectQueryResults = createSelector(
  selectSearch,
  query => query.get('results') || OrderedMap()
)

export const selectQueryLoading = createSelector(selectSearch, query =>
  query.get('queryLoading')
)

export const selectError = createSelector(selectSearch, query =>
  query.get('error')
)

export const selectNodeTypeCounts = createSelector(selectSearch, search =>
  search.get('nodeTypeCounts')
)

export const selectNodeTypeCountsLoading = createSelector(
  selectSearch,
  search => search.get('nodeTypeCountsLoading')
)

export const selectLoadedSavedSearch = createSelector(selectSearch, query =>
  query.get('loadedSavedSearch')
)

export const selectSavingSearchDescription = createSelector(
  selectSearch,
  search => search.get('savingSearchDescription')
)

export const selectSearchModifiedConcurrently = createSelector(
  selectSearch,
  query => query.get('searchModifiedConcurrently')
)

export const selectSavingSearch = createSelector(selectSearch, query =>
  query.get('savingSearch')
)

export const selectSaveSearchError = createSelector(selectSearch, query =>
  query.get('saveSearchError')
)

export const selectDuplicatingSearchId = createSelector(selectSearch, query =>
  query.get('duplicatingSearchId')
)

export const selectResultRelations = createSelector(selectSearch, query =>
  query.get('resultRelations')
)

export const selectSearchName = createSelector(selectSearch, query =>
  query.get('searchName')
)

export const selectEnablingMonitoring = createSelector(selectSearch, query =>
  query.get('enablingMonitoring')
)

export const selectEnableMonitoringError = createSelector(selectSearch, query =>
  query.get('enableMonitoringError')
)

export const selectSavedQueries = createSelector(
  selectSavedSearches,
  searches =>
    searches.map(search =>
      search.set('isUsed', search.getIn(['containedByWidget', 'count'], 0) > 0)
    ) || Map()
)

export const selectSearchIdFromUrl = (state, props) =>
  props.location ? qs.parse(props.location.search).searchId : null

const getOrderedResultsViews = (field, queryStatements, resultViews) => {
  if (!field || field.isEmpty()) {
    return List()
  }

  const resultViewIds = field.get('resultViews', List())

  const currentCards = resultViewIds.map(id =>
    resultViews.get(id, Map()).set('fieldId', field.get('id'))
  )

  const childrenIds = field.get('selectionSet', List())
  const childrenCards = childrenIds.map(childId =>
    getOrderedResultsViews(
      queryStatements.get(childId, Map()),
      queryStatements,
      resultViews
    )
  )

  return currentCards.concat(childrenCards.flatten(1))
}

export const selectResultCards = createSelector(
  [selectRootQuery, selectQueryStatements, selectResultViews],
  getOrderedResultsViews
)

export const selectCurrentSavedQuery = createSelector(
  [selectSavedQueries, selectSearchIdFromUrl],
  (savedQueries, currentQueryId) => savedQueries.get(currentQueryId) || Map()
)

export const selectShouldLoadUrlSearch = createSelector(
  [selectQueryTypes, selectSearchIdFromUrl],
  (queryTypes, urlQueryId) => {
    return !queryTypes.isEmpty() && !!urlQueryId
  }
)

export const selectSearchModsParam = (state, props) => {
  return qs.parse(props.location.search).searchMods
}

export const selectQueryMods = createSelector(selectSearchModsParam, str => {
  if (!str) {
    return Map()
  }

  return fromJS(JSON.parse(decodeURIComponent(str)))
})

export const selectReadonly = createSelector(
  selectQueryMods,
  queryMods => !queryMods.isEmpty()
)

export const selectUrlResultView = (state, props) => {
  return props && props.location
    ? qs.parse(props.location.search).resultView
    : ''
}

export const selectResultView = createSelector(
  [selectResultViews, selectUrlResultView],
  (resultViews, urlResultView) =>
    urlResultView || resultViews.get('defaultView') || RESULT_VIEW_TYPES.TABLE
)

export const selectSearchView = (state, props) =>
  qs.parse(props.location.search).view || SEARCH_VIEWS.BUILD

export const selectMonitoringResourcesExceeded = createSelector(
  selectSearch,
  state => state.get('monitoringResourcesExceeded')
)

export const selectIsMultiImportanceModalOpen = createSelector(
  selectSearch,
  state => state.get('isMultiImportanceModalOpen')
)

export const selectIsMultiImportanceLoading = createSelector(
  selectSearch,
  state => state.get('isMultiImportanceLoading')
)

export const selectIsUpdatingTags = createSelector(selectSearch, state =>
  state.get('isUpdatingTags')
)

export const selectHasCommentsHidden = createSelector(selectSearch, state =>
  state.get('hasCommentsHidden')
)

export const selectHasUnsavedChanges = createSelector(
  [
    selectCurrentSavedQuery,
    selectSearchName,
    selectResultViews,
    selectQueryStatements,
    selectConvertedFromLegacy,
    selectQueryTypes,
  ],
  (
    currentSavedSearch,
    searchName,
    resultViews,
    queryStatements,
    convertedFromLegacy,
    queryTypes
  ) => {
    if (currentSavedSearch.isEmpty()) {
      //We don't have a saved search so as long as there's some statements, the search is considered Unsaved
      return !queryStatements.isEmpty()
    } else if (searchName !== currentSavedSearch.get('name')) {
      //The search name has changed
      return true
    } else if (
      !currentSavedSearch.getIn(['query', 'resultViews']).equals(resultViews)
    ) {
      //there were changes to the result view
      return true
    } else if (convertedFromLegacy) {
      //If a query was converted from a legacy format,
      //we only want hasUnsavedChanges to be true if the query statements have user-made changes
      //TODO: performance?...
      const queryBuilder = new QueryBuilder({
        query: currentSavedSearch.getIn(['query', 'fields']),
        resultViews: currentSavedSearch.getIn(['query', 'resultViews']),
        types: queryTypes,
      })
      const fieldsAfterConversion = queryBuilder.fields
      return !fieldsAfterConversion.equals(queryStatements)
    } else {
      //Check if the current query statements differ from the saved version
      return !currentSavedSearch
        .getIn(['query', 'fields'])
        .equals(queryStatements)
    }
  }
)
