import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import { push } from 'connected-react-router'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import Button from 'components/Button'
import TextLink from 'components/TextLink'
import { setMonitoringResourcesExceeded } from 'containers/Search/actions'

export class MonitoredResourcesExceededModal extends React.Component {
  goToCrm = () => {
    this.props.setMonitoringResourcesExceeded(false)

    this.props.push({
      pathname: '/App/SecurityCenter?view=allmonitored',
    })
  }

  render() {
    return (
      <Modal isOpen={this.props.numExceeded} toggle={this.props.toggle}>
        <ModalHeader toggle={this.props.toggle}>
          Monitored Resources limit exceeded
        </ModalHeader>
        <ModalBody>
          <p>Cannot enable monitoring on selected resources.</p>
          <p>
            Enabling monitoring on the selected resources will exceed the limit
            of {this.props.numExceeded} monitored resource(s) for your account.
          </p>
        </ModalBody>
        <ModalFooter>
          <div>
            <TextLink color="secondary" onClick={this.props.toggle}>
              Close
            </TextLink>
            <Button
              color="primary"
              onClick={this.goToCrm}
              style={{ margin: '0rem 0.75rem' }}
            >
              Manage Monitored Resources
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    )
  }
}

MonitoredResourcesExceededModal.propTypes = {
  numExceeded: PropTypes.number,
  toggle: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  setMonitoringResourcesExceeded: PropTypes.func,
}

const mapStateToProps = createStructuredSelector({})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      push,
      setMonitoringResourcesExceeded,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(MonitoredResourcesExceededModal)
