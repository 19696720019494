import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'

const styles = {
  bool: {
    fontFamily: 'monospace',
  },
}

export class BooleanDisplay extends React.Component {
  render() {
    return (
      <span style={styles.bool}>
        {this.props.arg
          .first()
          .get('value')
          .toString()}
      </span>
    )
  }
}

BooleanDisplay.propTypes = {
  arg: ImmutablePropTypes.mapOf(
    ImmutablePropTypes.contains({
      id: PropTypes.string.isRequired,
      value: PropTypes.any,
      op: PropTypes.string,
    })
  ),
}

export default BooleanDisplay
