import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import { ButtonGroup } from 'reactstrap'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { bindActionCreators, compose } from 'redux'
import { withRouter } from 'react-router'
import { Helmet } from 'react-helmet'
import DropdownActions, {
  DropdownItem,
  DropdownMenu,
  DropdownAnchor,
} from 'components/DropdownActions'
import Icon from 'components/Icon'
import Button from 'components/Button'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import InlineEditableText from 'components/InlineEditableText'
import { SEARCH_VIEWS } from 'containers/Search/constants'
import { SEARCH_SAVE_STATE } from 'appConstants'
import _ from 'lodash'
import permissionChecker from 'containers/PermissionChecker'
import {
  saveSearchWithConcurrentCheck,
  saveSearch,
  setSearchName,
  toggleComments,
  saveSearchDescription,
} from 'containers/Search/actions'
import {
  selectSearchModifiedConcurrently,
  selectSavingSearch,
  selectCurrentSavedQuery,
  selectSearchName,
  selectSearchView,
  selectHasUnsavedChanges,
  selectSavingSearchDescription,
  selectQueryResults,
} from 'containers/Search/selectors'

import { setSearchLimit } from 'containers/SearchQuery/actions'
import { selectSearchLimit } from 'containers/SearchQuery/selectors'
import { Flag } from 'containers/FeatureFlags'
import ModalConfirmEditUsed from './ModalConfirmEditUsed'
import ModalConfirmEditModified from './ModalConfirmEditModified'
import ModalEnterNewName from './ModalEnterNewName'
import ModalSaveAs from './ModalSaveAs'
import ModalSearchDescription from './ModalSearchDescription'
import ModalSetLimit from './ModalSetLimit'
import IHelp from 'containers/IHelp'

export class SearchToolbar extends React.Component {
  constructor(props) {
    super(props)

    this.styles = {
      toolbar: {
        display: 'grid',
        gridTemplateColumns: 'auto auto 1fr auto auto',
        gridTemplateAreas: '"save description title message  viewtoggles"',
        gridColumnGap: '0.5em',
        backgroundColor: props.theme.light,
        border: `1px solid ${props.theme.neutralLight}`,
        padding: '0.3em 0.5em',
        alignItems: 'center',
      },
      unsaved: {
        fontStyle: 'italic',
        color: props.theme.neutralMedium,
      },
      buttons: {
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        gridColumnGap: '0.5em',
        gridArea: 'viewtoggles',
      },
      searchControls: {
        gridArea: 'save',
        display: 'flex',
      },
      button: {
        padding: '4px 8px',
      },
      titlewrapper: {
        margin: '0 0.5em',
        gridArea: 'title',
      },
      title: {
        fontSize: '1.3em',
      },

      message: {
        gridArea: 'message',
        fontSize: '0.9em',
        display: 'flex',
        alignItems: 'center',
        marginTop: '0.3em',
        paddingLeft: '0.3em',
        color: props.theme.neutralDark,
      },
    }

    this.state = {
      showConfirmSave: false,
      showSaveSuccessMessage: false,
      showEnterNewName: false,
      showSaveAs: false,
      showDescriptionModal: false,
      showLimitModal: false,
    }
  }

  componentDidUpdate(oldProps) {
    if (
      oldProps.currentSavedQuery.get('srn') !==
      this.props.currentSavedQuery.get('srn')
    ) {
      this.props.setSearchName(this.props.currentSavedQuery.get('name'))
    }

    if (
      oldProps.savingSearchDescription !== this.props.savingSearchDescription
    ) {
      this.setState({ showDescriptionModal: false })
    }

    if (oldProps.limit !== this.props.limit) {
      this.setState({ showLimitModal: false })
    }

    if (
      oldProps.savingSearch !== SEARCH_SAVE_STATE.ALLOW &&
      this.props.savingSearch === SEARCH_SAVE_STATE.ALLOW
    ) {
      this.setState({
        showSaveSuccessMessage: true,
      })

      this.searchMessageTimeout = setTimeout(() => {
        this.setState({
          showSaveSuccessMessage: false,
        })
      }, 5000)
    }
  }

  componentWillUnmount() {
    if (this.searchMessageTimeout) {
      clearTimeout(this.searchMessageTimeout)
    }
  }

  save = () => {
    if (
      this.props.currentSavedQuery.getIn(['containedByWidget', 'count']) >= 1
    ) {
      this.toggleConfirmSave()
    } else {
      this.doSaveWithConcurrentCheck()
    }
  }

  doSaveWithConcurrentCheck = () => {
    this.props.saveSearchWithConcurrentCheck({
      id: this.props.currentSavedQuery.get('sid'),
      srn: this.props.currentSavedQuery.get('srn'),
      name: this.props.searchName,
      view: this.props.searchView,
    })

    this.setState({
      showConfirmSave: false,
    })
  }

  doSave = () => {
    this.props.saveSearch({
      id: this.props.currentSavedQuery.get('sid'),
      srn: this.props.currentSavedQuery.get('srn'),
      name: this.props.searchName,
      view: this.props.searchView,
    })

    this.setState({
      showConfirmSave: false,
    })
  }

  saveas = () => {
    this.setState({
      showSaveAs: true,
    })
  }

  doSaveAs = newName => {
    this.props.saveSearch({
      name: newName,
      view: this.props.searchView,
    })

    this.setState({
      showSaveAs: false,
    })
  }

  toggleSaveAs = () => {
    this.setState(currentState => ({
      showSaveAs: !currentState.showSaveAs,
    }))
  }

  saveCopy = () => {
    if (this.props.currentSavedQuery.get('name') !== this.props.searchName) {
      this.props.saveSearch({
        name: this.props.searchName,
        view: this.props.searchView,
      })
    } else {
      this.setState({
        showEnterNewName: true,
      })
    }
  }

  toggleEnterNewName = () => {
    this.setState({ showEnterNewName: false })
  }

  doSaveCopy = newName => {
    this.props.setSearchName(newName)
    this.props.saveSearch({
      name: newName || this.props.searchName,
      view: this.props.searchView,
    })

    this.setState({
      showEnterNewName: false,
    })
  }

  toggleConfirmSave = () => {
    this.setState(currentState => ({
      showConfirmSave: !currentState.showConfirmSave,
    }))
  }

  isReadonly = () => {
    const canUpdate = this.props.userHasPermission({
      permissionName: 'edit.searches',
      resourceId: this.props.currentSavedQuery.get('resourceId'),
    })
    return this.props.currentSavedQuery.isEmpty() ? false : !canUpdate
  }

  renderPrimaryButton = () => {
    const hasUnsavedChanges = this.props.hasUnsavedChanges // || this.props.currentSavedQuery.isEmpty()

    if (this.props.savingSearch) {
      return (
        <Button
          outline={!hasUnsavedChanges}
          color={hasUnsavedChanges ? 'primary' : 'secondary'}
          style={this.styles.button}
          disabled
        >
          <Icon
            style={{ margin: '0rem 0.5rem 0rem 0rem' }}
            fa
            name="spinner"
            spin
          />
          Saving...
        </Button>
      )
    }

    const readonly = this.isReadonly()

    if (readonly) {
      const canEdit = this.props.userHasPermission({
        permissionName: 'edit.searches',
      })

      if (!canEdit) {
        return (
          <Button
            outline={true}
            color={'secondary'}
            title="Insufficient Permissions to Save As New Search..."
            style={this.styles.button}
            disabled={true}
          >
            <Icon style={{ margin: '0rem 0.5rem 0rem 0rem' }} fa name="copy" />
            Save As
          </Button>
        )
      } else {
        return (
          <Button
            outline={!hasUnsavedChanges}
            color={hasUnsavedChanges ? 'primary' : 'secondary'}
            title="Save as..."
            style={this.styles.button}
            onClick={this.saveas}
          >
            <Icon style={{ margin: '0rem 0.5rem 0rem 0rem' }} fa name="copy" />
            Save As
          </Button>
        )
      }
    }

    if (hasUnsavedChanges && !this.props.searchName) {
      return (
        <Button
          color="primary"
          title="Save as..."
          style={this.styles.button}
          onClick={this.saveas}
        >
          <Icon style={{ margin: '0rem 0.5rem 0rem 0rem' }} fa name="save" />
          Save As
        </Button>
      )
    }

    return (
      <Button
        outline={!hasUnsavedChanges}
        color={hasUnsavedChanges ? 'primary' : 'secondary'}
        onClick={this.save}
        title="Save search"
        style={this.styles.button}
      >
        <Icon style={{ margin: '0rem 0.5rem 0rem 0rem' }} fa name="save" />
        Save
      </Button>
    )
  }

  renderSearchControls = () => {
    const readonly = this.isReadonly()

    const dropdownItems = [
      <DropdownItem
        key="comments"
        closeOnClick
        onClick={this.props.toggleComments}
      >
        Toggle Builder Comments
      </DropdownItem>,
      // eslint-disable-next-line react/jsx-key
      <Flag
        name={['searchLimit']}
        render={() => {
          return (
            <DropdownItem
              key="toggleLimitModal"
              onClick={() => this.toggleLimitModal()}
            >
              Set Limit
            </DropdownItem>
          )
        }}
      />,
    ]

    if (!readonly) {
      dropdownItems.push(
        <DropdownItem
          key="toggleDescriptionModal"
          closeOnClick
          onClick={() => this.toggleDescriptionModal()}
        >
          {this.props.currentSavedQuery.get('description')
            ? 'Edit Description'
            : 'Add Description'}
        </DropdownItem>
      )
      dropdownItems.push(
        <DropdownItem
          key="duplicate"
          closeOnClick
          onClick={
            this.props.currentSavedQuery.isEmpty() ? this.saveas : this.saveCopy
          }
        >
          Save as...
        </DropdownItem>
      )
    }
    return (
      <ButtonGroup>
        {this.renderPrimaryButton()}

        <DropdownActions>
          <DropdownAnchor
            outline
            themeColor="secondary"
            style={{
              padding: '4px',
              borderTopLeftRadius: '0',
              borderBottomLeftRadius: '0',
              borderLeft: 0,
            }}
          >
            <Icon fa name="caret-down" size="sm" />
          </DropdownAnchor>
          <DropdownMenu>{dropdownItems}</DropdownMenu>
        </DropdownActions>
      </ButtonGroup>
    )
  }

  renderName = () => {
    if (this.isReadonly()) {
      return <div style={this.styles.title}>{this.props.searchName}</div>
    }

    return (
      <InlineEditableText
        value={this.props.searchName}
        placeholder={
          this.props.searchName ? 'Enter a Name...' : '(Unsaved Search)'
        }
        style={
          this.props.searchName
            ? this.styles.title
            : { ...this.styles.title, ...this.styles.unsaved }
        }
        inputStyle={{
          fontStyle: 'normal',
          color: this.props.theme.bodyText,
        }}
        onSubmit={this.props.setSearchName}
      />
    )
  }

  renderMessage = () => {
    if (this.props.savingSearch) {
      return (
        <span>
          <Icon fa name="spinner" color={this.props.theme.primary} spin />
          &nbsp;Saving...
        </span>
      )
    }

    if (this.state.showSaveSuccessMessage) {
      return (
        <span>
          <Icon fa name="check-circle-solid" color={this.props.theme.success} />
          &nbsp;Search Saved
        </span>
      )
    }

    if (this.isReadonly()) {
      return (
        <span>
          <Icon fa name="info-circle" />
          &nbsp;This search is readonly
        </span>
      )
    }

    const hasUnsavedChanges = this.props.hasUnsavedChanges //|| this.props.currentSavedQuery.isEmpty()

    if (hasUnsavedChanges) {
      return (
        <span>
          <Icon
            fa
            name="exclamation-triangle-solid"
            color={this.props.theme.warn}
          />
          &nbsp;Unsaved Changes
        </span>
      )
    }
    return <span>&nbsp;</span>
  }

  renderHelpIcon = description => {
    const isReadOnly = this.isReadonly()
    return (
      <div
        style={{
          margin: '0rem -1rem 0rem 0.5rem',
        }}
      >
        <IHelp
          help
          id="searchDescription"
          customContent={() => (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                padding: '0.75rem',
                minHeight: '100px',
                minWidth: '250px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div style={{ fontWeight: '500px', fontSize: '1.15rem' }}>
                  Description
                </div>
                {!isReadOnly && (
                  <div
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    onClick={() => this.toggleDescriptionModal()}
                  >
                    <Icon
                      fa
                      name="pencil-alt"
                      style={{
                        fontSize: '1rem',
                        margin: '0rem 0.5rem 0rem 0rem',
                        color: this.props.theme.primary,
                      }}
                    />
                    <div
                      style={{
                        fontSize: '1rem',
                        color: this.props.theme.primary,
                      }}
                    >
                      Edit
                    </div>
                  </div>
                )}
              </div>

              <div
                style={{
                  color: this.props.theme.neutralDark,
                  fontStyle: 'italic',
                  padding: '0.5rem 0rem 0rem 0rem',
                }}
              >
                {description}
              </div>
            </div>
          )}
          iconSize="22px"
          position="bottom"
        />
      </div>
    )
  }

  saveDescription = description => {
    this.props.saveSearchDescription({
      srn: this.props.currentSavedQuery.get('srn'),
      description,
    })
  }

  toggleDescriptionModal = () => {
    this.setState(oldState => ({
      showDescriptionModal: !oldState.showDescriptionModal,
    }))
  }

  toggleLimitModal = () => {
    this.setState(oldState => ({
      showLimitModal: !oldState.showLimitModal,
    }))
  }

  setLimit = limit => {
    this.props.setSearchLimit(limit)
    this.toggleLimitModal()
  }

  renderDescriptionView = () => {
    const description = this.props.currentSavedQuery.get('description')
    const isReadonly = this.isReadonly()
    if (isReadonly) {
      if (description) {
        return this.renderHelpIcon(description)
      }
    } else {
      if (description) {
        return this.renderHelpIcon(description)
      }
    }
  }

  render() {
    return (
      <div>
        <div style={this.styles.toolbar}>
          <div style={this.styles.searchControls}>
            {this.renderSearchControls()}
          </div>

          <div style={this.styles.titlewrapper}>{this.renderName()}</div>
          <div style={this.styles.toggleDescriptionModal}>
            {this.renderDescriptionView()}
          </div>

          <div style={this.styles.message}>{this.renderMessage()}</div>

          <div style={this.styles.buttons}>
            {this.props.searchView === SEARCH_VIEWS.BUILD ? (
              <Button
                outline
                color="primary"
                onClick={this.props.showResultMode}
                disabled={
                  !this.props.hasPermission || this.props.queryResults.isEmpty()
                }
              >
                <Icon fa name="list" />
                &nbsp;Results
              </Button>
            ) : (
              <Button
                outline
                color="primary"
                onClick={this.props.showBuildMode}
              >
                <Icon fa name="tools" />
                &nbsp;Builder
              </Button>
            )}

            <Button
              color="primary"
              onClick={this.props.runQuery}
              disabled={!this.props.hasPermission}
            >
              <Icon fa name="run" />
              &nbsp;Run
            </Button>
          </div>
        </div>
        <ModalConfirmEditModified
          isOpen={this.props.searchModifiedConcurrently}
          toggle={this.toggleConfirmOverwriteModified}
          doSave={this.doSave}
        />
        <ModalConfirmEditUsed
          isOpen={this.state.showConfirmSave}
          toggle={this.toggleConfirmSave}
          doSave={this.doSaveWithConcurrentCheck}
        />
        <ModalEnterNewName
          isOpen={this.state.showEnterNewName}
          toggle={this.toggleEnterNewName}
          doSave={this.doSaveCopy}
          originalSearchName={this.props.currentSavedQuery.get('name')}
        />
        <ModalSaveAs
          isOpen={this.state.showSaveAs}
          toggle={this.toggleSaveAs}
          doSave={this.doSaveAs}
        />
        <ModalSearchDescription
          isOpen={this.state.showDescriptionModal}
          toggle={this.toggleDescriptionModal}
          doSave={this.saveDescription}
          description={this.props.currentSavedQuery.get('description')}
          savingSearchDescription={this.props.savingSearchDescription}
        />
        <ModalSetLimit
          isOpen={this.state.showLimitModal}
          toggle={this.toggleLimitModal}
          setLimit={this.setLimit}
          limit={this.props.limit}
          currentSavedQuery={this.props.currentSavedQuery}
        />

        {this.props.searchName && (
          <Helmet
            title={`Sonrai - ${_.truncate(this.props.searchName, {
              length: 24,
            })}`}
          />
        )}
      </div>
    )
  }
}

SearchToolbar.propTypes = {
  currentSavedQuery: ImmutablePropTypes.map.isRequired,
  hasUnsavedChanges: PropTypes.bool,
  runQuery: PropTypes.func.isRequired,
  saveSearch: PropTypes.func.isRequired,
  saveSearchWithConcurrentCheck: PropTypes.func.isRequired,
  savingSearch: PropTypes.number,
  searchModifiedConcurrently: PropTypes.bool,
  searchName: PropTypes.string,
  setSearchName: PropTypes.func.isRequired,
  searchView: PropTypes.string,
  showBuildMode: PropTypes.func.isRequired,
  showResultMode: PropTypes.func.isRequired,
  theme: themeShape,
  toggleComments: PropTypes.func.isRequired,
  userHasPermission: PropTypes.func.isRequired,
  saveSearchDescription: PropTypes.func,
  savingSearchDescription: PropTypes.bool,
  hasPermission: PropTypes.bool,
  queryResults: ImmutablePropTypes.map.isRequired,
  limit: PropTypes.number.isRequired,
  setSearchLimit: PropTypes.func.isRequired,
}

const mapStateToProps = createStructuredSelector({
  queryResults: selectQueryResults,
  currentSavedQuery: selectCurrentSavedQuery,
  hasUnsavedChanges: selectHasUnsavedChanges,
  savingSearch: selectSavingSearch,
  searchName: selectSearchName,
  searchView: selectSearchView,
  searchModifiedConcurrently: selectSearchModifiedConcurrently,
  savingSearchDescription: selectSavingSearchDescription,
  limit: selectSearchLimit,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      saveSearch,
      saveSearchWithConcurrentCheck,
      setSearchName,
      toggleComments,
      saveSearchDescription,
      setSearchLimit,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(
  withRouter,
  themeable,
  withConnect,
  permissionChecker
)(SearchToolbar)
