import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List, Map, isImmutable } from 'immutable'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose } from 'redux'
import AccountSelector from 'components/AccountSelector'
import { selectAccounts } from 'containers/SonraiData/selectors'
import SelectBar from 'components/SelectBar'
import { exists } from 'utils/sonraiUtils'
import { QUERY_FILTER_OP } from 'appConstants'
import messages from 'globalTranslations'
import StringOperator from './StringOperator'
import _ from 'lodash'

const styles = {
  optionsDropdown: {
    marginBottom: '0.5em',
  },
}

class AccountOperator extends React.Component {
  onValueChange = account => {
    if (!_.isEmpty(account)) {
      this.props.onChange({ value: account })
    } else {
      this.props.removeArgument()
    }
  }

  onOperationChange = val => {
    if (val !== null) {
      const op = val.value
      if (op === QUERY_FILTER_OP.IS_NULL) {
        this.props.onChange(
          Map({
            op: 'EQ',
            value: 'null',
          })
        )
      } else if (op === QUERY_FILTER_OP.IS_NOT_NULL) {
        this.props.onChange(
          Map({
            op: 'NEQ',
            value: 'null',
          })
        )
      } else {
        this.props.onChange(Map({ op, value: '' }))
      }
    } else {
      this.props.removeArgument()
    }
  }

  getOpVal = () => {
    if (this.props.argumentValue.get('value') == `null`) {
      if (this.props.argumentValue.get('op') == `EQ`) {
        return QUERY_FILTER_OP.IS_NULL
      } else {
        return QUERY_FILTER_OP.IS_NOT_NULL
      }
    } else {
      return this.props.argumentValue.get('op')
    }
  }

  renderOperationsSelection = () => {
    if (this.props.opType.isEmpty()) {
      return null
    }
    const options = this.props.opType.get('enumValues', List()).toJS()
    options.push({ name: QUERY_FILTER_OP.IS_NULL })
    options.push({ name: QUERY_FILTER_OP.IS_NOT_NULL })

    let filteredOptions = options
      .filter(x => exists(x.name))
      .filter(
        op =>
          op.name !== QUERY_FILTER_OP.IN_LIST &&
          op.name !== QUERY_FILTER_OP.NOT_IN_LIST
      )

    return (
      <div style={styles.optionsDropdown}>
        <SelectBar
          options={filteredOptions.map(op => ({
            label: this.props.intl.formatMessage(messages.operations[op.name]),
            value: op.name,
          }))}
          onChange={this.onOperationChange}
          value={this.getOpVal()}
        />
      </div>
    )
  }

  render() {
    //eslint-disable-next-line no-unused-vars
    const { accounts, argumentValue, fetchAccounts, ...props } = this.props
    if (
      this.getOpVal() !== QUERY_FILTER_OP.EQUALS &&
      this.getOpVal() !== QUERY_FILTER_OP.NOT_EQUALS
    ) {
      return <StringOperator {...this.props} />
    }

    let argumentValueArr =
      argumentValue.get('value') === '' || !exists(argumentValue.get('value'))
        ? []
        : isImmutable(argumentValue.get('value'))
        ? argumentValue.get('value').toJS()
        : argumentValue.get('value')

    if (
      !Array.isArray(argumentValueArr) &&
      typeof argumentValueArr === 'string'
    ) {
      argumentValueArr = argumentValueArr.split(',')
    }

    return (
      <div>
        {this.renderOperationsSelection()}
        <div style={{ margin: '0.5em 0rem' }}>
          <AccountSelector
            onChange={this.onValueChange}
            accounts={accounts}
            value={
              !_.isEmpty(argumentValueArr)
                ? argumentValueArr.map(value => ({ label: value, value }))
                : []
            }
            isMulti
          />
        </div>
      </div>
    )
  }
}

AccountOperator.propTypes = {
  accounts: ImmutablePropTypes.iterable.isRequired,
  argumentValue: PropTypes.object.isRequired,
  fetchAccounts: PropTypes.func,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  opType: ImmutablePropTypes.map.isRequired,
  removeArgument: PropTypes.func.isRequired,
}

const mapStateToProps = createStructuredSelector({
  accounts: selectAccounts,
})

const withConnect = connect(mapStateToProps)

export default compose(withConnect, injectIntl)(AccountOperator)
