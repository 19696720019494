import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { isImmutable } from 'immutable'
import ImmutablePropTypes from 'react-immutable-proptypes'

import { QUERY_FILTER_OP } from 'appConstants'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import { FILTER_DATE_FORMAT } from 'appConstants'
import filterMessages from 'components/QueryCardFieldFilter/messages'

const styles = {
  lowerCase: {
    textTransform: 'lowercase',
  },
}

export class DateTimeDisplay extends React.Component {
  getArgVal = () => {
    return this.props.arg.first()
  }

  render() {
    if (
      this.getArgVal().get('dateOffset') !== undefined &&
      this.getArgVal().get('dateOffset') !== null
    ) {
      // construct the path to the relative date label in messages
      const arg = this.getArgVal().get('dateOffset')

      if (isImmutable(arg)) {
        let path = ''
        if (arg.get('type') === 'GT') {
          path += 'in'
        } else if (arg.get('type') === 'LT') {
          path += 'out'
        }
        if (arg.get('tense') === 'past') {
          path += 'Past'
        } else if (arg.get('tense') === 'future') {
          path += 'Future'
        }

        // hack this for months... absolutely DISGUSTING
        let months = 0
        if (arg.get('measure') === 'days') {
          months = Math.abs(
            Math.round(
              moment
                .duration(
                  moment().diff(
                    moment()
                      .add(arg.get('number'), 'day')
                      .calendar()
                  )
                )
                .asMonths()
            )
          )
          if (months === 6) {
            path += 'Month6'
          } else if (months === 9) {
            path += 'Month9'
          } else if (months === 12) {
            path += 'Month12'
          } else {
            path +=
              arg
                .get('measure')
                .charAt(0)
                .toUpperCase() +
              arg.get('measure').slice(1, arg.get('measure').length - 1)
            path += arg.get('number')
          }
        } else {
          path +=
            arg
              .get('measure')
              .charAt(0)
              .toUpperCase() +
            arg.get('measure').slice(1, arg.get('measure').length - 1)
          path += arg.get('number')
        }
        return (
          <span style={styles.lowerCase}>
            <DynamicFormattedMessage
              defaultMessage={`within ${this.getArgVal().get(
                'dateOffset'
              )} days`}
              {...filterMessages.relativeDates[path]}
            />
          </span>
        )
      } else {
        return (
          <span style={styles.lowerCase}>
            <DynamicFormattedMessage
              defaultMessage={`within ${this.getArgVal().get(
                'dateOffset'
              )} days`}
              {...filterMessages.relativeDates[
                `now${this.getArgVal().get('dateOffset')}`
              ]}
            />
          </span>
        )
      }
    } else {
      if (
        this.getArgVal().get('values') &&
        (this.getArgVal().get('op') === QUERY_FILTER_OP.BETWEEN ||
          this.getArgVal().get('op') === QUERY_FILTER_OP.NOT_BETWEEN)
      ) {
        return `${this.getArgVal()
          .get('values')
          .get(0)} - ${this.getArgVal()
          .get('values')
          .get(1)}`
      }
      return moment(this.getArgVal().get('value'), FILTER_DATE_FORMAT).format(
        'L'
      )
    }
  }
}

DateTimeDisplay.propTypes = {
  arg: ImmutablePropTypes.mapOf(
    ImmutablePropTypes.contains({
      id: PropTypes.string.isRequired,
      value: PropTypes.any,
      op: PropTypes.string,
    })
  ),
}

export default DateTimeDisplay
