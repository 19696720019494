import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap'
import { FormattedMessage } from 'react-intl'
import TextLink from 'components/TextLink'
import Button from 'components/Button'
import { PlainCheckbox } from 'components/Checkbox'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import messages from './messages'
import { Map } from 'immutable'
import { getSearchLimit } from 'utils/sonraiUtils'
import { DEFAULT_SEARCH_LIMIT } from 'appConstants'

const ModalSetLimit = ({
  setLimit,
  isOpen,
  toggle,
  limit,
  theme,
  currentSavedQuery,
}) => {
  const query = currentSavedQuery.getIn(['query', 'fields'], Map()).first()
    ? currentSavedQuery.getIn(['query', 'fields'], Map()).first()
    : Map()
  const prevLimit = query.get('limit')

  const currentLimit = getSearchLimit(limit, prevLimit)
  const [isValid, setValidity] = useState(false)
  const [num, setNum] = useState(
    currentLimit !== -1 ? currentLimit : DEFAULT_SEARCH_LIMIT
  )
  const [isChecked, setChecked] = useState(currentLimit === -1)
  const [hasWarning, setWarning] = useState(false)

  const styles = {
    body: {
      display: 'grid',
      gridTemplateRows: 'auto 1fr auto',
      gridGap: '0.5rem',
    },
    header: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    warningText: {
      fontSize: '0.9rem',
      color: theme.warn,
    },
  }

  useEffect(() => {
    if (!isChecked) {
      if (num && num >= 1 && num !== limit && num !== prevLimit) {
        setValidity(true)
        if (num > 500) {
          setWarning(true)
        } else {
          setWarning(false)
        }
      } else {
        setValidity(false)
      }
    } else {
      setValidity(true)
      setWarning(true)
    }
  }, [num, isChecked])

  const handleSetLimit = () => {
    if (isChecked) {
      setLimit(-1)
    } else {
      setLimit(num)
    }
    setValidity(false)
    setWarning(false)
  }

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader toggle={toggle}>Set Limit</ModalHeader>
      <ModalBody style={styles.body}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <div style={{ margin: '0.1rem 0.25rem 0rem 0rem' }}>
            Show All Results
          </div>
          <PlainCheckbox
            checked={isChecked}
            onChange={() => setChecked(!isChecked)}
          />
        </div>
        <Input
          type="number"
          value={num}
          disabled={isChecked}
          onChange={({ target: { value } }) => setNum(value)}
          min={1}
        />
        {hasWarning && (
          <div style={styles.warningText}>
            The search may take longer than expected given the volume requested.
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <TextLink color="primary" onClick={() => toggle()}>
          <FormattedMessage {...messages.rename.cancelButton} />
        </TextLink>
        <Button
          color="primary"
          disabled={!isValid}
          onClick={() => handleSetLimit()}
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  )
}

ModalSetLimit.propTypes = {
  setLimit: PropTypes.func,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  limit: PropTypes.number,
  theme: themeShape,
  currentSavedQuery: ImmutablePropTypes.iterable.isRequired,
}

export default themeable(ModalSetLimit)
