import { defineMessages } from 'react-intl'

export default defineMessages({
  Duplicate: {
    id: 'app.components.SearchToolbar.duplicate',
    defaultMessage: 'Duplicate',
  },
  Usedin: {
    id: 'app.components.SearchToolbar.Usedin',
    defaultMessage: 'Used in',
  },
  Newsearch: {
    id: 'app.components.SearchToolbar.Newsearch',
    defaultMessage: 'New',
  },
  Unsavedchanges: {
    id: 'app.components.SearchToolbar.Unsavedchanges',
    defaultMessage: 'There are unsaved changes',
  },
  Saving: {
    id: 'app.components.SearchToolbar.Saving',
    defaultMessage: 'Saving...',
  },
  Lastsaved: {
    id: 'app.components.SearchToolbar.Lastsaved',
    defaultMessage: 'Last saved {date}',
  },
  Expand: {
    id: 'app.components.SearchToolbar.Expandsearch',
    defaultMessage: 'Expand Result',
  },
  Compress: {
    id: 'app.components.SearchToolbar.Compresssearch',
    defaultMessage: 'Flatten Result',
  },
  Runsearch: {
    id: 'app.components.SearchToolbar.Runsearch',
    defaultMessage: 'Run Search',
  },
  Save: {
    id: 'app.components.SearchToolbar.Save',
    defaultMessage: 'Save',
  },
  Advanced: {
    id: 'app.components.SearchToolbar.advancedButton',
    defaultMessage: 'Advanced',
  },
  customized: {
    id: 'app.components.SearchToolbar.customized',
    defaultMessage: 'This search has been customized',
  },
  noModify: {
    id: 'app.components.SearchToolbar.noModify',
    defaultMessage: 'The original search cannot be modified',
  },
  enterNewName: {
    id: 'app.components.SearchToolbar.enterNewName',
    defaultMessage: 'Enter a new name to save',
  },
  saveAs: {
    id: 'app.components.SearchToolbar.saveAs',
    defaultMessage: 'Save as',
  },
  saveCopy: {
    id: 'app.components.SearchToolbar.saveCopy',
    defaultMessage: 'Copy',
  },
  saveButtonAlt: {
    id: 'app.components.SearchToolbar.saveButtonAlt',
    defaultMessage: 'Save this search',
  },
  advancedButtonAlt: {
    id: 'app.components.SearchToolbar.advancedButtonAlt',
    defaultMessage: 'Advanced Search',
  },
  flattenButtonAlt: {
    id: 'app.components.SearchToolbar.flattenButtonAlt',
    defaultMessage: 'Combine results into one table',
  },
  edit: {
    id: 'app.containers.Query.confirmSave.edit',
    defaultMessage: 'Builder',
  },
  showResults: {
    id: 'app.containers.Query.confirmSave.showResults',
    defaultMessage: 'Results',
  },
  confirmSave: {
    title: {
      id: 'app.containers.Query.confirmSave.title',
      defaultMessage: 'Confirm Update',
    },
    intro: {
      id: 'app.containers.Query.confirmSave.intro',
      defaultMessage: 'This search is used in a Solution Center widget.',
    },
    strong: {
      id: 'app.containers.Query.confirmSave.strong',
      defaultMessage:
        'Editing this search may cause errors in configured solution cards.',
    },
    confirm: {
      id: 'app.containers.Query.confirmSave.confirm',
      defaultMessage: 'Are you sure you want to update this search?',
    },
    cancelButton: {
      id: 'app.containers.Query.confirmSave.cancelButton',
      defaultMessage: 'Cancel',
    },
    saveButton: {
      id: 'app.containers.Query.confirmSave.saveButton',
      defaultMessage: 'Update Search',
    },
  },
  rename: {
    title: {
      id: 'app.containers.Query.rename.title',
      defaultMessage: 'Create a Copy',
    },
    intro: {
      id: 'app.containers.Query.rename.intro',
      defaultMessage: 'Enter a name for the new copy of',
    },
    cancelButton: {
      id: 'app.containers.Query.rename.cancelButton',
      defaultMessage: 'Cancel',
    },
    saveButton: {
      id: 'app.containers.Query.rename.saveButton',
      defaultMessage: 'Save Copy',
    },
  },
  deleteSearchConfirmationModalTitle: {
    id: 'app.components.SearchToolbar.deleteSearchConfirmationModalTitle',
    defaultMessage: 'Confirm Delete',
  },
  deleteSearchConfirmationModalInstructions: {
    id:
      'app.components.SearchToolbar.deleteSearchConfirmationModalInstructions',
    defaultMessage:
      'This saved search is used in the locations(s) listed below. Deleting this search will also delete these widgets and policies. Are you sure you want to continue?',
  },
  widget: {
    id: 'app.components.SearchToolbar.widget',
    defaultMessage: 'Widget',
  },
  type: {
    id: 'app.components.SearchToolbar.type',
    defaultMessage: 'Type',
  },
  solutionCard: {
    id: 'app.components.SearchToolbar.solutionCard',
    defaultMessage: 'Solution Card',
  },
  policy: {
    id: 'app.components.SearchToolbar.polkicy',
    defaultMessage: 'Policy',
  },
  no: {
    id: 'app.components.SearchToolbar.no',
    defaultMessage: 'Cancel',
  },
  yes: {
    id: 'app.components.SearchToolbar.yes',
    defaultMessage: 'Delete Widgets, Policies, and Search',
  },
})
