import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List, Map, isImmutable } from 'immutable'
import { injectIntl } from 'react-intl'
import SelectBar from 'components/SelectBar'
import { exists } from 'utils/sonraiUtils'
import { QUERY_FILTER_OP } from 'appConstants'
import messages from 'globalTranslations'

const styles = {
  optionsDropdown: {
    marginBottom: '0.5em',
  },
}

class SelectOperator extends React.Component {
  onValueChange = val => {
    if (exists(val) && !Array.isArray(val)) {
      const value = val.value
      this.props.onChange({ value })
    } else if (Array.isArray(val)) {
      this.props.onChange(val.map(value => value.value))
    } else {
      this.props.removeArgument()
    }
  }

  onOperationChange = val => {
    if (val !== null) {
      const op = val.value
      if (op === QUERY_FILTER_OP.IS_NULL) {
        this.props.onChange(
          Map({
            op: 'EQ',
            value: 'null',
          })
        )
      } else if (op === QUERY_FILTER_OP.IS_NOT_NULL) {
        this.props.onChange(
          Map({
            op: 'NEQ',
            value: 'null',
          })
        )
      } else {
        this.props.onChange(Map({ op, value: '' }))
      }
    } else {
      this.props.removeArgument()
    }
  }

  getFilterValue = () => {
    let val = this.props.argumentValue.get('value')

    if (isImmutable(val)) {
      val = val.toJS()
    }
    if (Array.isArray(val)) {
      return val.map(value => ({
        label: value,
        value: value,
      }))
    } else if (val !== '') {
      return [{ label: val, value: val }]
    } else {
      return []
    }
  }

  getVal = () => {
    if (this.props.argumentValue.get('value') == `null`) {
      if (this.props.argumentValue.get('op') == `EQ`) {
        return QUERY_FILTER_OP.IS_NULL
      } else {
        return QUERY_FILTER_OP.IS_NOT_NULL
      }
    } else {
      return this.props.argumentValue.get('op')
    }
  }

  renderOperationsSelection = () => {
    if (this.props.opType.isEmpty()) {
      return null
    }
    const options = this.props.opType.get('enumValues', List()).toJS()
    options.push({ name: QUERY_FILTER_OP.IS_NULL })
    options.push({ name: QUERY_FILTER_OP.IS_NOT_NULL })

    let filteredOptions = options
      .filter(x => exists(x.name))
      .filter(
        op =>
          op.name !== QUERY_FILTER_OP.IN_LIST &&
          op.name !== QUERY_FILTER_OP.NOT_IN_LIST
      )

    return (
      <div style={styles.optionsDropdown}>
        <SelectBar
          options={filteredOptions.map(op => ({
            label: this.props.intl.formatMessage(messages.operations[op.name]),
            value: op.name,
          }))}
          onChange={this.onOperationChange}
          value={this.getVal()}
          isClearable={false}
        />
      </div>
    )
  }

  render() {
    const options = this.props.valueType
      .get('enumValues', List())
      .toJS()
      .filter(x => exists(x.name))
      .filter(
        op =>
          op.name !== QUERY_FILTER_OP.BETWEEN &&
          op.name !== QUERY_FILTER_OP.NOT_BETWEEN
      )

    return (
      <div>
        {this.renderOperationsSelection()}
        <div style={{ margin: '0.5em 0rem' }}>
          <SelectBar
            isMulti={this.props.isMulti}
            isDisabled={this.props.argumentValue.get('value') === 'null'}
            options={options.map(op => ({
              label: op.name,
              value: op.name,
            }))}
            value={this.getFilterValue()}
            onChange={this.onValueChange}
          />
        </div>
      </div>
    )
  }
}

SelectOperator.defaultProps = {
  isMulti: true,
}

SelectOperator.propTypes = {
  argumentValue: PropTypes.object.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  isMulti: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  opType: ImmutablePropTypes.map.isRequired,
  removeArgument: PropTypes.func.isRequired,
  valueType: ImmutablePropTypes.map.isRequired,
}

export default injectIntl(SelectOperator)
