import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { bindActionCreators, compose } from 'redux'
import _ from 'lodash'
import { injectIntl } from 'react-intl'
import { Input } from 'reactstrap'
import { Map } from 'immutable'

import SelectBar from 'components/SelectBar'
import ConnectorCaret, { Directions } from 'components/ConnectorCaret'
import BorderedCard from 'components/BorderedCard'
import { changeArgument } from 'containers/SearchQuery/actions'
import messages from 'globalTranslations'
import Dropdown, {
  DropdownMenu,
  InlineDropdownAnchor,
  DropdownItem,
} from 'components/Dropdown'

const COUNT_FILTER_TYPES = {
  ZERO: 'zero',
  MORE_THAN_ZERO: 'morethanzero',
  CUSTOM: 'custom',
}

const styles = {
  card: {
    minWidth: '300px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  customCountWrapper: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 100px',
    gridColumnGap: '0.3em',
    margin: '0.5em 0',
  },
}

export class RelationCard extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showCustom: this.getSelectedCountValue() === COUNT_FILTER_TYPES.CUSTOM,
    }
  }

  componentDidUpdate(oldProps) {
    const relationId = this.getRelationArgumentId()
    if (
      !oldProps.parentField
        .getIn(['arguments', relationId, 'count'], Map())
        .equals(
          this.props.parentField.getIn(
            ['arguments', relationId, 'count'],
            Map()
          )
        )
    ) {
      this.setState({
        showCustom: this.getSelectedCountValue() === COUNT_FILTER_TYPES.CUSTOM,
      })
    }
  }

  getRelationArgumentId = () => {
    if (
      this.props.parentField.hasIn([
        'arguments',
        `${this.props.fieldType}-${this.props.fieldId}`,
      ])
    ) {
      return `${this.props.fieldType}-${this.props.fieldId}`
    }

    return this.props.fieldType
  }

  onChangeCountFilter = selectedValue => {
    const relationId = this.getRelationArgumentId()
    if (selectedValue.value === COUNT_FILTER_TYPES.MORE_THAN_ZERO) {
      this.props.changeArgument({
        fieldId: this.props.parentField.get('id'),
        value: { count: { op: 'GT', value: 0 } },
        name: relationId,
      })

      this.setState({
        showCustom: false,
      })
    } else if (selectedValue.value === COUNT_FILTER_TYPES.ZERO) {
      this.props.changeArgument({
        fieldId: this.props.parentField.get('id'),
        value: { count: { op: 'EQ', value: 0 } },
        name: relationId,
      })

      this.setState({
        showCustom: false,
      })
    } else {
      this.setState({
        showCustom: true,
      })
    }
  }

  updateValue = e => {
    const relationId = this.getRelationArgumentId()

    this.props.changeArgument({
      fieldId: this.props.parentField.get('id'),
      value: {
        count: {
          op: this.props.parentField.getIn([
            'arguments',
            relationId,
            'count',
            'op',
          ]),
          value: e.target.value,
        },
      },
      name: this.props.fieldType,
    })
  }

  updateOp = selectedValue => {
    const relationId = this.getRelationArgumentId()

    this.props.changeArgument({
      fieldId: this.props.parentField.get('id'),
      value: {
        count: {
          value: this.props.parentField.getIn([
            'arguments',
            relationId,
            'count',
            'value',
          ]),
          op: selectedValue.value,
        },
      },
      name: this.props.fieldType,
    })
  }

  commitCustom = () => {
    this.props.changeArgument({
      fieldId: this.props.parentField.get('id'),
      value: {
        count: { op: this.state.customOp, value: this.state.customValue },
      },
      name: this.props.fieldType,
    })
  }

  getSelectedCountValue = () => {
    const relationId = this.getRelationArgumentId()

    const countArg = this.props.parentField.getIn([
      'arguments',
      relationId,
      'count',
    ])

    if (countArg) {
      if (this.state) {
        if (this.state.showCustom) {
          return COUNT_FILTER_TYPES.CUSTOM
        }
      }
    }

    return countArg &&
      (countArg.get('op') === 'GT' && countArg.get('value')) === 0
      ? COUNT_FILTER_TYPES.MORE_THAN_ZERO
      : countArg && (countArg.get('op') === 'EQ' && countArg.get('value')) === 0
      ? COUNT_FILTER_TYPES.ZERO
      : COUNT_FILTER_TYPES.CUSTOM
  }

  render() {
    const { fieldType } = this.props
    const fieldName = this.props.intl.formatMessage({
      id: 'undefinedTranslation',
      defaultMessage: _.startCase(fieldType),
      ...messages.fields[fieldType],
    })

    const moreThanZeroLabel = fieldName

    const zeroLabel = this.props.intl.formatMessage({
      id: 'undefinedTranslation',
      defaultMessage: `(not) ${_.startCase(fieldType)}`,
      ...messages.fields[
        `not${fieldType.charAt(0).toUpperCase() + fieldType.slice(1)}`
      ],
    })

    const customLabel = `${fieldName} (custom count)`

    const options = [
      {
        label: this.props.intl.formatMessage(messages.operations.EQ),
        value: 'EQ',
      },
      {
        label: this.props.intl.formatMessage(messages.operations.GT),
        value: 'GT',
      },
      {
        label: this.props.intl.formatMessage(messages.operations.GTE),
        value: 'GTE',
      },
      {
        label: this.props.intl.formatMessage(messages.operations.LT),
        value: 'LT',
      },
      {
        label: this.props.intl.formatMessage(messages.operations.LTE),
        value: 'LTE',
      },
    ]

    const relationId = this.getRelationArgumentId()

    return (
      <div>
        <BorderedCard style={styles.card}>
          <div>
            <Dropdown
              selectedValue={
                this.state.showCustom
                  ? COUNT_FILTER_TYPES.CUSTOM
                  : this.getSelectedCountValue()
              }
              onClick={this.onChangeCountFilter}
            >
              <InlineDropdownAnchor />
              <DropdownMenu>
                <DropdownItem
                  label={moreThanZeroLabel}
                  defaultValue
                  value={COUNT_FILTER_TYPES.MORE_THAN_ZERO}
                />
                <DropdownItem
                  label={zeroLabel}
                  value={COUNT_FILTER_TYPES.ZERO}
                />
                <DropdownItem
                  label={customLabel}
                  value={COUNT_FILTER_TYPES.CUSTOM}
                />
              </DropdownMenu>
            </Dropdown>
          </div>

          {this.state.showCustom && (
            <div style={styles.customCountWrapper}>
              <SelectBar
                options={options}
                onChange={this.updateOp}
                value={this.props.parentField.getIn([
                  'arguments',
                  relationId,
                  'count',
                  'op',
                ])}
                isClearable={false}
              />
              <Input
                type="number"
                value={this.props.parentField.getIn([
                  'arguments',
                  relationId,
                  'count',
                  'value',
                ])}
                onChange={this.updateValue}
              />
            </div>
          )}
        </BorderedCard>

        <ConnectorCaret direction={Directions.DOWNWARD} />
      </div>
    )
  }
}

RelationCard.propTypes = {
  changeArgument: PropTypes.func,
  fieldType: PropTypes.string,
  parentField: ImmutablePropTypes.contains({
    id: PropTypes.string,
  }),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  fieldId: PropTypes.string.isRequired,
}

const mapStateToProps = createStructuredSelector({})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      changeArgument,
    },
    dispatch
  )
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)

export default compose(
  withConnect,
  injectIntl
)(RelationCard)
