import { all, put, takeLatest, select } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { fromJS, Map } from 'immutable'
import { FETCH_SAVED_SEARCH_QUERY } from 'static-queries'
import qs from 'query-string'

import { getClient } from 'apolloClient'
import { QueryBuilder } from 'query-builder'
import gql from 'graphql-tag'
import {
  selectQueryTypes,
  selectSavedSearches,
} from 'containers/SonraiData/selectors'
import { setSavedSearch } from 'containers/SonraiData/actions'
import {
  selectRootQuery,
  selectQuery,
  selectSearchLimit,
} from 'containers/SearchQuery/selectors'
import { loadSavedQuery } from 'containers/SearchQuery/actions'
import mappings from 'searchMappings.json'
import { getNodeTypeMap, getSearchLimit } from 'utils/sonraiUtils'

import { selectSearchName } from './selectors'
import {
  setQueryResults,
  queryErrorHappen,
  setSearchModifiedConcurrently,
  saveSearchSuccess,
  saveSearch as saveSearchAction,
  setMonitoringOnResourcesSuccess,
  setMonitoringOnResourcesError,
  setMonitoringResourcesExceeded,
  setImportanceMultiSuccess,
  addTagsOnSelectedResourcesSuccess,
  setSaveSearchError,
  saveSearchDescriptionSuccess,
  setNodeTypeCounts,
} from './actions'
import {
  RUN_QUERY,
  SAVE_SEARCH_WITH_CONCURRENT_CHECK,
  SAVE_SEARCH,
  LOAD_SEARCH_IN_ADVANCED,
  SET_MONITORING_ON_RESOURCES,
  SET_IMPORTANCE_MULTI,
  ADD_TAGS_ON_SELECTED_RESOURCES,
  SAVE_SEARCH_DESCRIPTION,
  GET_NODETYPE_COUNTS,
} from './constants'
import _ from 'lodash'

function* runQuery(action) {
  try {
    const queryRootField = yield select(selectRootQuery)

    //If query is blank, abort
    if (queryRootField.isEmpty()) {
      yield put(setQueryResults({ results: {} }))
      return
    }

    const query = yield select(selectQuery)
    const searchName = yield select(selectSearchName)
    const queryTypes = yield select(selectQueryTypes)

    const queryBuilder = new QueryBuilder({
      query: query.get('query'),
      resultViews: query.get('resultViews'),
      types: queryTypes,
    })

    if (action.payload.flatten) {
      queryBuilder.enableFlattenMode()
      queryBuilder.skipCounts()
    }

    const reduxLimit = yield select(selectSearchLimit)
    const prevLimit = query.get('query').first().get('limit')
    const limit = getSearchLimit(reduxLimit, prevLimit)

    const queryConfig = queryBuilder.buildPivotableSource(
      queryRootField.get('id'),
      undefined,
      { limit: !_.isNumber(limit) ? parseInt(limit) : limit }
    )

    const client = getClient()
    const results = yield client.query({
      query: gql`
        ${queryConfig.gqlStatement}
      `,
      variables: queryConfig.variables,
      context: {
        queryName: `UISearchQuery - ${searchName || 'unnamed'}`,
      },
    })

    if (!results || !results.data) {
      if (results.errors) {
        throw new Error('An unexpected error happened')
      }
      yield put(setQueryResults({ results: {} }))
      return
    }

    Object.keys(results.data).forEach(key => {
      if (!results.data[key].items) {
        throw new Error(`${key}.items is null`)
      }
    })

    yield put(setQueryResults({ results: results.data }))
  } catch (e) {
    if (_.get(e, ['networkError', 'statusCode'], null) === 504) {
      yield put(
        queryErrorHappen(
          'The search has timed out.  It has been scheduled to run in the background and depending on the complexity will complete in 5-20 minutes.  Please save and then re-run it in approx 5 -20 minutes to view results.'
        )
      )
    } else {
      //eslint-disable-next-line no-console
      console.error(e)
      yield put(queryErrorHappen(e.message || 'Error when handling RUN_QUERY'))
    }
  }
}

function* saveSearchWithConcurrentCheck(action) {
  const queryRootField = yield select(selectRootQuery)

  if (queryRootField.isEmpty()) {
    //eslint-disable-next-line no-console
    console.warn('Tried to save empty query; exiting')
    return
  }

  if (!action.payload.srn) {
    yield put(saveSearchAction(action.payload))
    return
  }

  try {
    const client = getClient()
    const response = yield client.query({
      forceFetch: true,
      fetchPolicy: 'no-cache',
      query: gql`
        ${FETCH_SAVED_SEARCH_QUERY}
      `,
      variables: {
        srn: action.payload.srn,
      },
    })

    if (response.data.Searches.items === null) {
      throw new Error('Bad formatting of response from server: items is null')
    }

    const serverVersion = fromJS(response.data.Searches.items['0'].query)
    const savedSearchesRedux = yield select(selectSavedSearches)

    const clientVersion = savedSearchesRedux
      .find(query => query.get('srn') === action.payload.srn, null, Map())
      .get('query')

    if (!clientVersion.equals(serverVersion)) {
      yield put(setSearchModifiedConcurrently(true))
      return
    }

    yield put(saveSearchAction(action.payload))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error(`Failed to compare existing searches ${e}`)
  }
}

function* saveSearch(action) {
  try {
    const queryRootField = yield select(selectRootQuery)

    const limit = yield select(selectSearchLimit)
    if (queryRootField.isEmpty()) {
      //eslint-disable-next-line no-console
      console.warn('Tried to save empty query; exiting')
      return
    }

    const newQueryName = action.payload.name
    const queryState = yield select(selectQuery)
    const queryTypes = yield select(selectQueryTypes)

    const queryBuilder = new QueryBuilder({
      query: queryState.get('query'),
      types: queryTypes,
      resultViews: queryState.get('resultViews'),
    })

    queryBuilder.addDefaultResultViews()
    queryBuilder.enableFlattenMode()
    queryBuilder.skipCount = false

    const searchSid = action.payload.id
    const searchSRN = action.payload.srn

    const isExistingSearch = !!searchSid

    const mutateSearchConfig = isExistingSearch
      ? queryBuilder.buildUpdateSavedSearch(
          queryRootField.get('id'),
          newQueryName,
          searchSRN,
          { limit: !_.isNumber(limit) ? parseInt(limit) : limit }
        )
      : queryBuilder.buildCreateSavedSearch(
          queryRootField.toJS(), //TODO: Update query-builder to take just the ID here
          newQueryName,
          { limit: !_.isNumber(limit) ? parseInt(limit) : limit }
        )
    const client = getClient()
    const results = yield client.mutate({
      mutation: gql`
        ${mutateSearchConfig.gqlStatement}
      `,
      variables: mutateSearchConfig.variables,
    })

    const savedSearch = isExistingSearch
      ? results.data.UpdateSearch
      : results.data.CreateSearch

    yield put(saveSearchSuccess())
    yield put(setSavedSearch(savedSearch))
    yield put(
      loadSavedQuery({
        savedSearch: fromJS(savedSearch),
        searchId: savedSearch.sid,
        skipRun: true,
      })
    )
    yield put(
      push({
        search: qs.stringify({
          searchId: savedSearch.sid,
          view: action.payload.view,
        }),
      })
    )
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('Error when handling SAVE_SEARCH', e)
    yield put(setSaveSearchError(e.message))
  }
}

function* loadSearchInAdvanced() {
  const queryRootField = yield select(selectRootQuery)

  if (queryRootField.isEmpty()) {
    return yield put(
      push({
        pathname: '/App/GraphExplorer',
      })
    )
  }

  const query = yield select(selectQuery)
  const queryTypes = yield select(selectQueryTypes)
  const limit = yield select(selectSearchLimit)

  const queryBuilder = new QueryBuilder({
    query: query.get('query'),
    resultViews: query.get('resultViews'),
    types: queryTypes,
  })

  const queryString = queryBuilder.buildPivotableSource(
    queryRootField.get('id'),
    undefined,
    { limit: !_.isNumber(limit) ? parseInt(limit) : limit }
  ).gqlStatement

  yield put(
    push({
      pathname: '/App/GraphExplorer',
      state: {
        query: queryString,
      },
    })
  )
}

function* setImportanceMulti(action) {
  const { srns, level } = action.payload
  let queryString = 'mutation setImportance {'
  let i = 0
  srns.forEach(srn => {
    queryString += `
      s${i}: setImportance(srn: "${srn}", importance: ${level}) {
        srn
        importance
      }
    `
    i++
  })
  queryString += `
    }
  `

  try {
    const client = getClient()
    yield client.mutate({
      mutation: gql`
        ${queryString}
      `,
    })

    yield put(setImportanceMultiSuccess())
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('Error setting importance', e)
  }
}

function* setMonitoringOnResources(action) {
  const client = getClient()
  let hasExceeded = null
  try {
    const monitorResults = yield all(
      action.payload.map(srn => {
        return client.mutate({
          mutation: gql`
            mutation toggleResourceMonitoring {
              setMonitor(monitorStatusBySrn: {srn: "${srn}", monitor: true }){
                srn
                monitor
              }
            }
          `,
        })
      })
    )

    for (let result of monitorResults) {
      const message = _.get(result, ['errors', 0, 'message'])
      if (result.errors) {
        if (message && message.includes('maximum allowed')) {
          hasExceeded = message.substr(message.search(/monitor:/) + 9)
        }
        throw new Error(message)
      }
    }
    yield put(setMonitoringOnResourcesSuccess())
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('error enabling monitoring on resources', e)
    if (hasExceeded) {
      yield put(
        setMonitoringResourcesExceeded({ message: e.message, hasExceeded })
      )
    } else {
      yield put(setMonitoringOnResourcesError(e.message))
    }
  }
}

function* addTagsOnMultipleResources(action) {
  const { resources, tags } = action.payload
  try {
    const client = getClient()
    const response = yield all(
      tags.map(tag => {
        return client.mutate({
          mutation: gql`
          mutation addTagsWithNoDuplicates {
            AddTag(
              value: {
                key: "${tag.key}"
                value: "${tag.value}"
                tagsEntity: {
                  add: [${resources.map(resource => `"${resource}"`)}]
                }
              }) {
              srn
              key
              value
            }
          }`,
        })
      })
    )
    if (response) {
      yield put(addTagsOnSelectedResourcesSuccess())
    }
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('error adding tag on resources', e)
  }
}

function* saveSearchDescription(action) {
  const { srn, description } = action.payload
  try {
    const client = getClient()
    const result = yield client.mutate({
      mutation: gql`
        mutation updateSearchDescription($srn: ID!, $description: String!) {
          UpdateSearch(srn: $srn, value: { description: $description }) {
            name
            description
            createdDate
            createdBy
            sid
            srn
            lastModified
            query
            containedByWidget {
              count
              items(limit: -1) {
                title
                type
                srn
                containedBy {
                  items {
                    name
                  }
                }
              }
            }
            containedByControlPolicy {
              count
              items(limit: -1) {
                title
                srn
              }
            }
            ownedByOrganization {
              items {
                sid
                name
              }
            }
          }
        }
      `,
      variables: {
        srn,
        description,
      },
    })

    const updatedSearch = _.get(result, ['data', 'UpdateSearch'])
    if (updatedSearch) {
      yield put(saveSearchDescriptionSuccess())
      yield put(setSavedSearch(updatedSearch))
    }
  } catch (error) {
    //eslint-disable-next-line no-console
    console.error('Failed to save search description', error)
  }
}

function* fetchNodeTypeCounts() {
  try {
    const client = getClient()
    const response = yield client.query({
      query: gql`
        query getNodeTypeCounts {
          GroupedQuery(where: { keys: [Label] }) {
            key
            items {
              value
              count
            }
          }
        }
      `,
    })
    const items = _.get(response, ['data', 'GroupedQuery', 'items'])
    const queryTypes = yield select(selectQueryTypes)

    const nodeTypeMap = getNodeTypeMap(
      mappings.nodeTypes,
      fromJS(items),
      queryTypes
    )

    yield put(setNodeTypeCounts(nodeTypeMap))
  } catch (error) {
    //eslint-disable-next-line no-console
    console.error('Failed to get node type counts from grouped query', error)
  }
}

function* searchSaga() {
  yield all([
    takeLatest(RUN_QUERY, runQuery),
    takeLatest(
      SAVE_SEARCH_WITH_CONCURRENT_CHECK,
      saveSearchWithConcurrentCheck
    ),
    takeLatest(SAVE_SEARCH, saveSearch),
    takeLatest(LOAD_SEARCH_IN_ADVANCED, loadSearchInAdvanced),
    takeLatest(SET_MONITORING_ON_RESOURCES, setMonitoringOnResources),
    takeLatest(SET_IMPORTANCE_MULTI, setImportanceMulti),
    takeLatest(ADD_TAGS_ON_SELECTED_RESOURCES, addTagsOnMultipleResources),
    takeLatest(SAVE_SEARCH_DESCRIPTION, saveSearchDescription),
    takeLatest(GET_NODETYPE_COUNTS, fetchNodeTypeCounts),
  ])
}

export default searchSaga
