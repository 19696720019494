import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { bindActionCreators, compose } from 'redux'
import { List, Map, fromJS } from 'immutable'
import IHelp from 'containers/IHelp'
import BorderedCard from 'components/BorderedCard'
import ImmutablePureComponent from 'components/ImmutablePureComponent'
import {
  addEffectivePermissionIncludeGroup,
  addEffectivePermissionExcludeGroup,
  addEffectivePermission,
  removeEffectivePermission,
  removeEffectivePermissionGroup,
  addEPDisplayColumn,
  removeEPDisplayColumn,
  setIsDistinctEP,
  removeEffectivePermissionBlock,
  updateEffectivePermission,
  setEPDisplayColumnAlias,
  removeEPColumnAlias,
} from 'containers/SearchQuery/actions'
import EffectivePermissionCard from './EffectivePermissionCard'
import SectionHeader from 'components/SectionHeader'
import EffectivePermissionSelect from './EffectivePermissionSelect'
import Popover, { PopoverBody, PopoverAnchor } from 'components/Popover'
import TextLink from 'components/TextLink'
import BorderlessButton from 'components/BorderlessButton'
import Icon from 'components/Icon'

import EditProperty from './EditProperty'

export class EffectivePermissionBlock extends ImmutablePureComponent {
  constructor(props) {
    super(props)
    this.state = {
      showPropertyPopover: false,
    }
  }

  styles = {
    filterHeaderText: {
      fontVariant: 'small-caps',
      color: '#888888',
      fontSize: '18px',
      marginTop: '-0.2em',
    },
    filterHeader: {
      display: 'grid',
      gridTemplateColumns: 'auto 1fr auto',
      marginBottom: '0.5em',
    },
    verticalLine: {
      height: '0.5em',
      borderLeft: '1px solid #c8c8c8',
      margin: 'auto auto',
      width: '0',
    },
    permissionContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridColumnGap: '2em',
      marginBottom: '1em',
    },
  }

  addEPIncludeGroup = fieldId => {
    this.props.addEffectivePermissionIncludeGroup({ fieldId: fieldId })
    this.setState({
      nextCardSelectorOpen: false,
    })
  }

  setEPDisplayColumnAlias = alias => {
    this.props.setEPDisplayColumnAlias({
      fieldId: this.props.field.get('id'),
      ...alias,
    })
  }

  addEPExcludeGroup = fieldId => {
    this.props.addEffectivePermissionExcludeGroup({ fieldId: fieldId })
    this.setState({
      nextCardSelectorOpen: false,
    })
  }

  onAddPermission = params => {
    this.props.addEffectivePermission({
      fieldId: this.props.field.get('id'),
      ...params,
    })
  }

  onRemovePermission = params => {
    this.props.removeEffectivePermission({
      fieldId: this.props.field.get('id'),
      ...params,
    })
  }

  onRemovePermissionGroup = params => {
    this.props.removeEffectivePermissionGroup({
      fieldId: this.props.field.get('id'),
      ...params,
    })
  }

  setOrder = order => {
    this.props.setOrder({
      type: this.props.type,
      order: order,
    })
  }

  removeEPDisplayColumn = col => {
    this.props.removeEPDisplayColumn({
      item: col,
      fieldId: this.props.field.get('id'),
    })
  }

  addEPDisplayColumn = col => {
    this.props.addEPDisplayColumn({
      item: col,
      fieldId: this.props.field.get('id'),
    })
  }

  showPropertyPopover = () => {
    this.setState(currentState => ({
      showPropertyPopover: !currentState.showPropertyPopover,
    }))
  }

  onAddProperty = property => {
    this.props.addEPDisplayColumn({
      fieldId: property.fieldId,
      ...property,
    })
    this.setState({
      showPropertyPopover: false,
    })
  }

  setIsDistinct = distinct => {
    this.props.setIsDistinctEP({
      fieldId: this.props.field.get('id'),
      isDistinct: distinct,
    })
  }

  removeEPColumnAlias = col => {
    this.props.removeEPColumnAlias({
      fieldId: this.props.field.get('id'),
      column: col,
    })
  }

  removeBlock = () => {
    this.props.removeEffectivePermissionBlock({
      fieldId: this.props.field.get('id'),
    })
  }

  render() {
    if (!this.props.field.get('effectivePermissions')) {
      return null
    }

    let include = {}
    let exclude = {}
    if (this.props.field.get('effectivePermissions')) {
      include = this.props.field
        .getIn(['effectivePermissions', 'include'], Map())
        .toJS()
      exclude = this.props.field
        .getIn(['effectivePermissions', 'exclude'], Map())
        .toJS()
    }

    const fields = this.props.queryTypes.getIn(
      ['EffectivePermission', 'fields'],
      List()
    )

    const filterFields = this.props.queryTypes
      .getIn(['EffectivePermissionFilterType', 'inputFields'], List())
      .filter(
        inputField =>
          inputField.getIn(['type', 'name']) !== null &&
          inputField.getIn(['type', 'name']).endsWith('Operator')
      )

    let displayCols = List()
    const defaultColsForNow = ['policySrn', 'permission']
    if (!this.props.field.getIn(['effectivePermissions', 'order'])) {
      this.setOrder(
        fields
          .toJS()
          .map(field => field.name)
          .filter(item => defaultColsForNow.includes(item))
      )
      displayCols = fromJS(
        fields
          .toJS()
          .map(field => field.name)
          .filter(item => defaultColsForNow.includes(item))
      )
    } else {
      displayCols = this.props.field.getIn(['effectivePermissions', 'order'])
    }
    const aliases = this.props.field.getIn(
      ['effectivePermissions', 'aliases'],
      Map()
    )
    const isDistinct = this.props.field.getIn(
      ['effectivePermissions', 'isDistinct'],
      false
    )

    const includeKeys = Object.keys(include)
    const excludeKeys = Object.keys(exclude)
    return (
      <BorderedCard style={{ textAlign: 'left', width: '900px' }}>
        <div>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr auto' }}>
            <SectionHeader small>With Effective Permissions</SectionHeader>
            <BorderlessButton
              onClick={this.removeBlock}
              title="Remove Effective Permission Section"
            >
              <Icon fa name="times" />
            </BorderlessButton>
          </div>

          <div style={this.styles.permissionContainer}>
            <div>
              <div
                style={{
                  ...this.styles.filterHeader,
                  ...{
                    display: 'flex',
                    alignItems: 'center',
                  },
                }}
              >
                <span
                  style={{
                    ...this.styles.filterHeaderText,
                    ...{ margin: '0px 4px 3px 0px' },
                  }}
                >
                  resources which include these permissions
                </span>
                <IHelp help helpKey="includePermissions" iconSize="16px" />
              </div>
              {includeKeys.length === 0 && (
                <p
                  style={{
                    fontStyle: 'italic',
                    fontSize: '1em',
                    marginLeft: '5px',
                  }}
                >
                  All Permissions
                </p>
              )}
              {includeKeys.map(inc => {
                return (
                  <EffectivePermissionCard
                    onAddPermission={this.onAddPermission}
                    onRemovePermission={this.onRemovePermission}
                    key={inc}
                    removeCard={() => {
                      this.onRemovePermissionGroup({
                        qepId: inc,
                        type: 'include',
                      })
                    }}
                    type={`include`}
                    fields={filterFields}
                    displayColumns={displayCols}
                    permissions={include[inc]}
                    qepId={inc}
                    types={this.props.queryTypes}
                    field={this.props.field}
                    updateEffectivePermission={
                      this.props.updateEffectivePermission
                    }
                  />
                )
              })}
              <TextLink
                color="secondary"
                style={{
                  marginLeft: '5px',
                  fontSize: '0.9em',
                  marginTop: '1em',
                  display: 'block',
                }}
                onClick={() =>
                  this.props.addEffectivePermissionIncludeGroup({
                    fieldId: this.props.field.get('id'),
                  })
                }
              >
                <Icon fa name="plus" />
                &nbsp;{' '}
                {includeKeys.length === 0
                  ? 'Add a Condition Group'
                  : 'Add Another Condition Group'}
              </TextLink>
            </div>
            <div>
              <div
                style={{
                  ...this.styles.filterHeader,
                  ...{
                    display: 'flex',
                    alignItems: 'center',
                  },
                }}
              >
                <span
                  style={{
                    ...this.styles.filterHeaderText,
                    ...{ margin: '0px 4px 3px 0px' },
                  }}
                >
                  ignore resources which include these permissions
                </span>
                <IHelp help helpKey="ignorePermissions" iconSize="16px" />
              </div>
              {excludeKeys.length === 0 && (
                <p
                  style={{
                    fontStyle: 'italic',
                    fontSize: '1em',
                    marginLeft: '5px',
                  }}
                >
                  No exclusion filters
                </p>
              )}
              {excludeKeys.map(exc => {
                return (
                  <EffectivePermissionCard
                    key={exc}
                    onAddPermission={this.onAddPermission}
                    onRemovePermission={this.onRemovePermission}
                    removeCard={() => {
                      this.onRemovePermissionGroup({
                        qepId: exc,
                        type: 'exclude',
                      })
                    }}
                    type={`exclude`}
                    fields={filterFields}
                    field={this.props.field}
                    displayColumns={displayCols}
                    permissions={exclude[exc]}
                    qepId={exc}
                    types={this.props.queryTypes}
                    updateEffectivePermission={
                      this.props.updateEffectivePermission
                    }
                  />
                )
              })}
              <TextLink
                color="secondary"
                style={{
                  marginLeft: '5px',
                  fontSize: '0.9em',
                  marginTop: '1em',
                  display: 'block',
                }}
                onClick={() =>
                  this.props.addEffectivePermissionExcludeGroup({
                    fieldId: this.props.field.get('id'),
                  })
                }
              >
                <Icon fa name="plus" />
                &nbsp;{' '}
                {excludeKeys.length === 0
                  ? 'Add a Condition Group'
                  : 'Add Another Condition Group'}
              </TextLink>
            </div>
          </div>
          <div style={this.styles.filterHeader}>
            <span style={this.styles.filterHeaderText}>display columns</span>
            <div style={{ marginLeft: '50px' }}>
              <BorderlessButton onClick={() => this.setIsDistinct(!isDistinct)}>
                <div
                  style={{ display: 'grid', gridTemplateColumns: 'auto 1fr' }}
                >
                  <div>
                    <input type="checkbox" checked={isDistinct} />
                  </div>
                  <div style={this.styles.filterHeaderText}>distinct</div>
                </div>
              </BorderlessButton>
            </div>
            <Popover
              isOpen={this.state.showPropertyPopover}
              position="right-start"
            >
              <PopoverAnchor>
                <TextLink onClick={this.showPropertyPopover} color="primary">
                  <Icon name="plus" fa />
                  &nbsp;Add Display Column
                </TextLink>
              </PopoverAnchor>
              <PopoverBody>
                <EditProperty
                  displayColumns={displayCols}
                  onSubmit={this.onAddProperty}
                  types={this.props.queryTypes}
                  field={this.props.field}
                  toggle={this.showPropertyPopover}
                  toggleDefaultProperty={this.toggleDefaultProperty}
                  defaultColumns={[]}
                  scalerFieldsFiltered={fields}
                />
              </PopoverBody>
            </Popover>
          </div>
          <EffectivePermissionSelect
            displayColumns={displayCols}
            defaultColumns={fields}
            setOrder={this.setOrder}
            removeProperty={this.removeEPDisplayColumn}
            aliases={aliases}
            setEPDisplayColumnAlias={this.setEPDisplayColumnAlias}
            removeEPColumnAlias={this.removeEPColumnAlias}
          />
        </div>
      </BorderedCard>
    )
  }
}

EffectivePermissionBlock.propTypes = {
  addEffectivePermissionIncludeGroup: PropTypes.func.isRequired,
  setOrder: PropTypes.func,
  setIsDistinctEP: PropTypes.func,
  removeEPColumnAlias: PropTypes.func,
  updateEffectivePermission: PropTypes.func,
}

const mapStateToProps = createStructuredSelector({})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addEffectivePermissionIncludeGroup,
      addEffectivePermissionExcludeGroup,
      addEffectivePermission,
      removeEffectivePermission,
      removeEffectivePermissionGroup,
      addEPDisplayColumn,
      removeEPDisplayColumn,
      setIsDistinctEP,
      removeEffectivePermissionBlock,
      updateEffectivePermission,
      setEPDisplayColumnAlias,
      removeEPColumnAlias,
    },
    dispatch
  )
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)

export default compose(withConnect)(EffectivePermissionBlock)
