import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { bindActionCreators, compose } from 'redux'
import qs from 'query-string'
import { Alert } from 'reactstrap'
import { push } from 'connected-react-router'

import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import { RESULT_VIEW_TYPES } from 'query-builder'
import SearchResults from 'containers/SearchResults'
import {
  loadAndConvertSavedQuery,
  resetQuery,
  startQuery,
  clearSearchLimit,
} from 'containers/SearchQuery/actions'
import { selectRootQuery } from 'containers/SearchQuery/selectors'
import querysagas from 'containers/SearchQuery/sagas'
import queryreducer from 'containers/SearchQuery/reducer'
import SearchBuilder from 'containers/SearchBuilder'
import SearchLanding from 'containers/SearchLanding'
import SearchToolbar from 'containers/SearchToolbar'
import { DAEMON } from 'utils/constants'

import {
  selectSavedQueries,
  selectSearchIdFromUrl,
  selectQueryMods,
  selectSearchView,
  selectResultView,
  selectError,
  selectNodeTypeCounts,
} from './selectors'
import {
  clearQueryError,
  runQuery,
  loadSearchInAdvanced,
  setSearchName,
  resetEditor,
  getNodeTypeCounts,
} from './actions'
import sagas from './sagas'
import reducer from './reducer'
import GlobalSearchActions from './GlobalSearchActions'
import { SEARCH_VIEWS } from './constants'
import permissionChecker from 'containers/PermissionChecker'
import { selectSearchLimit } from 'containers/SearchQuery/selectors'

export class Search extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showSearchListModal: false,
    }

    this.styles = {
      container: {
        display: 'grid',
        gridTemplateRows: 'auto auto 1fr',
        gridTemplateAreas: '"globalActions" "toolbar" "body"',
        padding: '1em',
        height: '100%',
        overflow: 'hidden',
      },
      globalActions: {
        gridArea: 'globalActions',
      },
      toolbar: {
        gridArea: 'toolbar',
      },
      body: {
        gridArea: 'body',
        height: '100%',
        overflow: 'auto',
      },
    }

    if (!props.nodeTypeCounts || props.nodeTypeCounts.isEmpty()) {
      props.getNodeTypeCounts()
    }

    this.hasPermission = props.userHasPermission({
      permissionName: 'view.data',
    })
    if (props.urlQueryId) {
      const savedSearch = this.props.savedSearches.get(props.urlQueryId)

      if (savedSearch) {
        props.loadSavedSearch({
          queryMods: props.queryMods,
          savedSearch,
        })
        props.setSearchName(savedSearch.get('name'))

        if (props.searchView === SEARCH_VIEWS.RESULTS) {
          this.runQuery()
        }
      } else {
        //Invalid search ID, redirect to builder landing page
        this.resetQuery()
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.limit !== this.props.limit &&
      this.props.searchView === SEARCH_VIEWS.RESULTS
    ) {
      this.runQuery()
    }
  }

  dismissError = () => {
    this.props.clearQueryError()
  }

  resetQuery = () => {
    this.props.resetQuery()
    this.props.resetEditor()
    this.props.clearSearchLimit()
    this.props.push({
      search: qs.stringify({
        view: SEARCH_VIEWS.BUILD,
      }),
    })
  }

  setUrlParam = newParams => {
    const search = qs.parse(this.props.location.search)
    this.props.push({
      search: qs.stringify({
        ...search,
        ...newParams,
      }),
    })
  }

  showBuildMode = () => {
    this.setUrlParam({
      view: SEARCH_VIEWS.BUILD,
    })
  }

  showResultMode = () => {
    this.setUrlParam({
      view: SEARCH_VIEWS.RESULTS,
    })
  }

  onSelectSavedSearch = (searchId, buildMode) => {
    this.setState({
      showSearchListModal: false,
    })

    if (this.hasPermission) {
      this.props.resetEditor()
      const savedSearch = this.props.savedSearches.get(searchId)
      this.props.loadSavedSearch({
        savedSearch,
      })

      this.props.setSearchName(savedSearch.get('name'))

      this.setUrlParam({
        searchId: searchId,
        view: buildMode ? SEARCH_VIEWS.BUILD : SEARCH_VIEWS.RESULTS,
      })

      //Flatten the search by default if it has EPs, or if its a explorer or flattened view
      const shouldFlatten =
        savedSearch.getIn(
          ['query', 'resultViews', 'defaultView'],
          RESULT_VIEW_TYPES.TABLE
        ) !== RESULT_VIEW_TYPES.TABLE

      this.props.runQuery({
        flatten: shouldFlatten,
      })
    }
  }

  startQuery = (definition, autorun, filters) => {
    if (this.hasPermission) {
      this.props.startQuery({
        definition: definition,
        filters: filters,
      })

      if (autorun) {
        this.runQuery()
      }
    }
  }

  runQuery = () => {
    if (this.hasPermission) {
      this.props.runQuery({
        flatten: this.props.resultView !== RESULT_VIEW_TYPES.TABLE,
      })

      this.showResultMode()
    }
  }

  renderToolbar = () => {
    if (!this.props.rootQuery.isEmpty()) {
      return (
        <SearchToolbar
          hasPermission={this.hasPermission}
          runQuery={this.runQuery}
          showResultMode={this.showResultMode}
          showBuildMode={this.showBuildMode}
        />
      )
    }
  }

  renderBody = () => {
    if (this.props.rootQuery.isEmpty()) {
      return (
        <SearchLanding
          startQuery={this.startQuery}
          onSelectSavedSearch={this.onSelectSavedSearch}
        />
      )
    } else if (this.props.searchView === SEARCH_VIEWS.BUILD) {
      return <SearchBuilder />
    } else {
      return <SearchResults hasPermission={this.hasPermission} />
    }
  }

  render() {
    return (
      <div style={this.styles.container}>
        <div style={this.styles.globalActions}>
          {this.props.error && (
            <Alert color="danger" isOpen={true} toggle={this.dismissError}>
              {this.props.error}
            </Alert>
          )}
          <GlobalSearchActions
            toggleSearchListModal={this.toggleSearchListModal}
            loadSearchInAdvanced={this.props.loadSearchInAdvanced}
            resetQuery={
              this.props.rootQuery.isEmpty() ? undefined : this.resetQuery
            }
          />
        </div>
        <div style={this.styles.toolbar}>{this.renderToolbar()}</div>
        <div style={this.styles.body}>{this.renderBody()}</div>
      </div>
    )
  }
}

Search.propTypes = {
  clearQueryError: PropTypes.func.isRequired,
  error: PropTypes.string,
  loadSavedSearch: PropTypes.func.isRequired,
  loadSearchInAdvanced: PropTypes.func.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
    pathname: PropTypes.string,
  }).isRequired,
  nodeTypeCounts: ImmutablePropTypes.map.isRequired,
  push: PropTypes.func.isRequired,
  queryMods: ImmutablePropTypes.map,
  resetEditor: PropTypes.func.isRequired,
  resetQuery: PropTypes.func.isRequired,
  resultView: PropTypes.string.isRequired,
  rootQuery: ImmutablePropTypes.map.isRequired,
  runQuery: PropTypes.func.isRequired,
  savedSearches: ImmutablePropTypes.map,
  searchView: PropTypes.string,
  setSearchName: PropTypes.func.isRequired,
  startQuery: PropTypes.func.isRequired,
  urlQueryId: PropTypes.string,
  userHasPermission: PropTypes.func.isRequired,
  getNodeTypeCounts: PropTypes.func.isRequired,
  limit: PropTypes.number.isRequired,
  clearSearchLimit: PropTypes.func.isRequired,
}

const mapStateToProps = createStructuredSelector({
  error: selectError,
  queryMods: selectQueryMods,
  resultView: selectResultView,
  savedSearches: selectSavedQueries,
  urlQueryId: selectSearchIdFromUrl,
  rootQuery: selectRootQuery,
  searchView: selectSearchView,
  nodeTypeCounts: selectNodeTypeCounts,
  limit: selectSearchLimit,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadSearchInAdvanced,
      loadSavedSearch: loadAndConvertSavedQuery,
      push,
      resetQuery,
      resetEditor,
      runQuery,
      clearQueryError,
      setSearchName,
      startQuery,
      getNodeTypeCounts,
      clearSearchLimit,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
const withReducer = injectReducer({ key: 'search', reducer })
const withSaga = injectSaga({ key: 'search', saga: sagas, mode: DAEMON })
const withSearchReducer = injectReducer({ key: 'query', reducer: queryreducer })
const withSearchSaga = injectSaga({
  key: 'query',
  saga: querysagas,
  mode: DAEMON,
})

export default compose(
  withReducer,
  withConnect,
  withSaga,
  withSearchReducer,
  withSearchSaga,
  permissionChecker
)(Search)
