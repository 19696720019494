import _ from 'lodash'
import { List, Map } from 'immutable'

import mappings from 'searchMappings.json'
import { keywordMatch, stripExtraChars } from 'utils/sonraiUtils'
import { CLOUD_TYPES } from 'appConstants'

const GCP_KEYWORDS = ['google', 'gcp']
const AWS_KEYWORDS = ['aws', 'amazon']
const AZURE_KEYWORDS = ['azure', 'microsoft']
const KUBE_KEYWORDS = ['kubernetes', 'kube', 'k8s']
const HASHICORP_KEYWORDS = ['hashicorp']
const SONRAI_KEYWORDS = ['sonrai', 'sonraisecurity']
const GSUITE_KEYWORDS = ['gsuite']

const matches = (vals, search, allowPartial) => {
  return _.some(vals, el =>
    allowPartial ? keywordMatch(el, search) : search.includes(el)
  )
}

export const getCloudMatch = (search, allowPartial) => {
  if (!search) {
    return null
  }

  if (matches(GCP_KEYWORDS, search, allowPartial)) {
    return CLOUD_TYPES.GCP
  }

  if (matches(GSUITE_KEYWORDS, search, allowPartial)) {
    return CLOUD_TYPES.GSUITE
  }

  if (matches(AWS_KEYWORDS, search, allowPartial)) {
    return CLOUD_TYPES.AWS
  }

  if (matches(AZURE_KEYWORDS, search, allowPartial)) {
    return CLOUD_TYPES.AZURE
  }

  if (matches(KUBE_KEYWORDS, search, allowPartial)) {
    return CLOUD_TYPES.KUBERNETES
  }

  if (matches(HASHICORP_KEYWORDS, search, allowPartial)) {
    return CLOUD_TYPES.HASHICORP
  }

  if (matches(SONRAI_KEYWORDS, search, allowPartial)) {
    return CLOUD_TYPES.SONRAI
  }

  return null
}

export const getMatchStrength = (node, rawSearch) => {
  const displayValue = stripExtraChars(node.defaultDisplayName || node.key)
  const nodeType = stripExtraChars(node.nodeType || '')
  const search = stripExtraChars(rawSearch || '')

  let matchStrength = 0

  //Exact search match on the whole search term should always be at the top
  //This helps when people are familiar with the data model
  if (displayValue === search || nodeType === search) {
    matchStrength += 10000
  }

  //If the whole search contains the label, or the label contains the whole search, that's pretty good
  if (keywordMatch(displayValue, search)) {
    matchStrength += 100
  }

  //Filter out the special cloud keywords and set those aside
  const allSearchKeywords = search.replace(/\s\s+/g, ' ').split(' ') || []
  const clouds = []
  const searchKeywords = allSearchKeywords.filter(keyword => {
    const cloudMatch = getCloudMatch(keyword)
    if (cloudMatch) {
      clouds.push(cloudMatch)
      return false
    }

    return true
  })

  if (searchKeywords.length > 0) {
    //Handle keywords being in a different order from the labels.
    //But weight keywords at the start of the search as more important than those at the end
    //This handles cases where user types something like "Dynamo db"
    //We want the Dynamo options to go to the top, even if there are other values that match on "db"
    const singleWordMatchValues = searchKeywords
      .map((subsearch, index) => {
        const value = keywordMatch(subsearch, displayValue)
        return value * (searchKeywords.length - index)
      })
      .filter(val => val)

    //If we have all the keywords present, that's a perfect match!
    if (singleWordMatchValues.length === searchKeywords.length) {
      matchStrength += 10000
    } else {
      matchStrength += singleWordMatchValues.reduce(
        (sum, value) => (sum += value),
        0
      )
    }
  }

  //The weakest match is the "searchTerms" match, because the user cannot see them on the screen
  if (node.searchTerms) {
    //Compare each searchTerm to each keyword
    if (
      node.searchTerms.find(term =>
        _.some(searchKeywords, keyword => keywordMatch(term, keyword))
      )
    ) {
      matchStrength += 5
    }
  }

  const partialCloudMatches = searchKeywords
    .map(keyword => getCloudMatch(keyword, true))
    .filter(match => !!match)

  const userIsMaybeTypingACloud = partialCloudMatches.length === 1

  //If the node has a cloud on it, perform additional filtering
  if (clouds.length > 0 && node.cloud) {
    //If we have cloud keywords AND additional search keywords, remove any matches that are not in that cloud.
    //Handle special case where user is in the process of typing just a cloud type AFTER typing another cloud
    //We don't want to show a blank screen in this case!
    if (searchKeywords.length > 0 && !userIsMaybeTypingACloud) {
      if (!clouds.includes(node.cloud)) {
        matchStrength = 0
      }
    } else {
      //otherwise if the only keywords was clouds, we want to show everything that matches those clouds
      //But order doesn't really matter so it can be a low match strength
      if (clouds.includes(node.cloud)) {
        matchStrength += 1
      }
    }
  }

  //handle special case where user is in the process of typing just a cloud type
  //We don't want to show a blank screen in this case!
  if (userIsMaybeTypingACloud && node.cloud) {
    if (partialCloudMatches[0] === node.cloud) {
      matchStrength += 1
    }
  }

  return matchStrength
}

export const validateMappings = (queryDefs, queryTypes) => {
  //Remove mappings that do not match the current schema
  //And emit warnings about ones that could be missing
  const nodeTypes = mappings.nodeTypes.map(type => {
    //Every type mapping should have a label filter
    const labelValue = _.get(type, ['filters', 'label'])
    if (!labelValue) {
      //eslint-disable-next-line no-console
      console.warn(
        `searchMapping.json - nodeType "${type.nodeType}" does not have a "label" filter`
      )
      return true
    }

    //If the type isn't in the current schema, remove it from the mapping
    const queryDef = queryDefs.get(labelValue)
    const typeIsValid = !!queryDef
    if (!typeIsValid) {
      //eslint-disable-next-line no-console
      console.warn(
        `searchMapping.json - nodeType "${type.nodeType}" is not present in the graphql schema`
      )

      return null
    }

    //Validate the subTypes:
    //Intentionally skip classification sub-types because there's too many of them
    if (type.topGrouping !== 'classification') {
      const validEnumValues = getTypeEnumValuesForNode(queryDef, queryTypes)

      if (!type.subTypes && !validEnumValues.isEmpty()) {
        //eslint-disable-next-line no-console
        console.warn(
          `searchMapping.json - "${type.nodeType}" does not have subTypes defined. Valid subTypes are: `,
          validEnumValues.toJS()
        )
      } else if (type.subTypes) {
        validEnumValues.forEach(enumValue => {
          const matchingSubType = type.subTypes.find(subType => {
            const typeValue = _.get(subType, ['filters', 'type', 'value'])
            return typeValue && typeValue === enumValue
          })

          if (!matchingSubType) {
            //eslint-disable-next-line no-console
            console.warn(
              `searchMapping.json - nodeType "${type.nodeType}" is missing a subType definition for enum type "${enumValue}"`
            )
          }
        })

        const correctSubTypes = type.subTypes.filter(subType => {
          const typeValue = _.get(subType, ['filters', 'type', 'value'])
          if (!typeValue) {
            //eslint-disable-next-line no-console
            console.warn(
              `searchMapping.json - subType "${subType.key}" for nodeType "${type.nodeType}" is defined without a type filter`
            )
            return true
          }

          if (!validEnumValues.includes(typeValue)) {
            //eslint-disable-next-line no-console
            console.warn(
              `searchMapping.json - "${typeValue}" is not a valid subType of "${type.nodeType}". Valid subTypes are: `,
              validEnumValues.toJS()
            )

            return false
          }

          return true
        })

        type.subTypes = correctSubTypes
      }
    }

    return type
  })

  return nodeTypes.filter(type => type !== null)
}

const getTypeEnumValuesForNode = (typeDef, queryTypes) => {
  //Get the filter definition name, like "UserFilter"
  const filterType = typeDef
    .get('args', List())
    .find(arg => arg.get('name') === 'where', null, Map())
    .getIn(['type', 'name'])

  if (!filterType) {
    return List()
  }

  //Get the name of the type filter operator, like "UserTypeOperator"
  const typeFilterType = queryTypes
    .get(filterType, Map())
    .get('inputFields', List())
    .find(field => field.get('name') === 'type', null, Map())
    .getIn(['type', 'name'])

  if (!typeFilterType) {
    return List()
  }

  //Get the definition of the "type" property so we can check the valid enum values
  const typeDefinitionName = queryTypes
    .get(typeFilterType, Map())
    .get('inputFields', List())
    .find(field => field.get('name') === 'value', null, Map())
    .getIn(['type', 'name'])

  if (!typeDefinitionName) {
    return List()
  }

  const enumValues =
    queryTypes.get(typeDefinitionName, Map()).get('enumValues') || List()

  return enumValues.map(enumValue => enumValue.get('name'))
}
