import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { bindActionCreators, compose } from 'redux'
import _ from 'lodash'
import Icon from 'components/Icon'
import { FormattedMessage } from 'react-intl'
import messages from './messages'
import DataTable from 'components/DataTable'
import CenterContent from 'components/CenterContent'
import EmptySection from 'components/EmptySection'
import { flattenData } from 'utils/sonraiUtils'
import { ToolbarItem } from 'components/BulkActionToolbar'
import { TYPES_WITH_CRM } from 'appConstants'
import { getTypeFromDefinition } from 'utils/graphDataUtils'

import {
  selectTags,
  selectQueryRelations,
} from 'containers/SonraiData/selectors'
import {
  selectIsMultiImportanceModalOpen,
  selectIsMultiImportanceLoading,
  selectIsUpdatingTags,
  selectEnablingMonitoring,
  selectMonitoringResourcesExceeded,
} from 'containers/Search/selectors'
import {
  setMonitoringOnResources,
  setMonitoringOnResourcesError,
  setMonitoringResourcesExceeded,
  setImportanceMulti,
  setImportanceMultiModal,
  addTagsOnSelectedResources,
} from 'containers/Search/actions'

import MonitoredResourcesExceededModal from './MonitoredResourcesExceededModal'
import ChangeImportanceModal from './ChangeImportanceModal'
import AddTagsModal from './AddTagsModal'

class ResultTableWithActions extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      selectedRows: [],
      isImportanceModalOpen: false,
      addTagsModal: false,
    }
  }

  componentDidUpdate(oldProps) {
    if (this.api) {
      if (oldProps.enablingMonitoring && !this.props.enablingMonitoring) {
        this.api.deselectAll()
      } else if (oldProps.isUpdatingTags && !this.props.isUpdatingTags) {
        this.api.deselectAll()
      } else if (
        oldProps.isMultiImportanceLoading &&
        !this.props.isMultiImportanceLoading
      ) {
        this.api.deselectAll()
      }
    }
  }

  hasCrm = () => {
    if (!this.props.field || this.props.field.isEmpty()) {
      return false
    }

    const entityType = getTypeFromDefinition(this.props.field).toLowerCase()
    return TYPES_WITH_CRM.includes(entityType)
  }

  getBulkActionWorking = () => {
    return (
      this.props.isUpdatingTags ||
      this.props.enablingMonitoring ||
      this.props.isMultiImportanceLoading
    )
  }

  clearMonitoringResourcesExceeded = () => {
    this.props.setMonitoringResourcesExceeded({
      message: null,
      hasExceeded: null,
    })
  }

  enableMonitoringOnSelected = () => {
    this.props.setMonitoringOnResources(
      this.state.selectedRows.map(item => item.srn)
    )
  }

  addTagsOnSelectedResources = tags => {
    this.setState({
      addTagsModal: false,
    })
    const resources = this.state.selectedRows.map(item => item.srn)
    this.props.addTagsOnSelectedResources({
      tags,
      resources,
    })
  }

  toggleChangeImportanceModal = () => {
    this.setState(prevState => {
      return { isImportanceModalOpen: !prevState.isImportanceModalOpen }
    })
  }

  changeImportanceMulti = level => {
    this.props.setImportanceMulti({
      level: level,
      srns: this.state.selectedRows.map(item => item.srn),
    })
  }

  onTableLoad = ({ api }) => {
    this.api = api
  }

  onTableSelectionChange = evnt => {
    const rows = evnt.api.getSelectedRows() || []

    this.setState({
      selectedRows: rows,
    })
  }

  getCustomColumnHeaders = () => {
    const { query, data } = this.props

    let items = data
    if (this.props.flatten) {
      items = flattenData(items)
    }

    let headers = []
    items.forEach(row => {
      _.keys(row).forEach(fieldName => {
        if (fieldName.startsWith('qf')) {
          let arr = fieldName.includes('_')
            ? _.first(fieldName.split('_')).split('')
            : fieldName.split('')
          arr.splice(2, 0, '-')
          const key = arr.join('')
          const field = query.get(key)
          if (field) {
            const name = `${field.get('name')} - ${_.startCase(
              field.getIn(['definition', 'name'])
            )}`
            headers.push({ fieldName, alias: name })
          }
        }
      })
    })

    return _.isEmpty(headers) ? [] : _.uniqBy(headers, obj => obj.fieldName)
  }

  renderActions = () => {
    if (!this.hasCrm()) {
      return [
        <ToolbarItem
          permissionName="edit.searches"
          key="tags"
          iconName={'tags'}
          onClick={() => this.setState({ addTagsModal: true })}
          disabled={this.state.selectedRows.length === 0}
        >
          Add Tag(s)
        </ToolbarItem>,
      ]
    }

    return [
      <ToolbarItem
        permissionName="edit.crmsettings"
        key="monitoring"
        iconName={'clock'}
        onClick={this.enableMonitoringOnSelected}
        disabled={this.state.selectedRows.length === 0}
      >
        Enable Monitoring
      </ToolbarItem>,
      <ToolbarItem
        permissionName="edit.crmsettings"
        key="importance"
        iconName={'alert'}
        onClick={this.toggleChangeImportanceModal}
        disabled={this.state.selectedRows.length === 0}
      >
        Change Importance
      </ToolbarItem>,
      <ToolbarItem
        permissionName="edit.searches"
        key="tags"
        iconName={'tags'}
        onClick={() => this.setState({ addTagsModal: true })}
        disabled={this.state.selectedRows.length === 0}
      >
        Add Tag(s)
      </ToolbarItem>,
    ]
  }

  render() {
    const { data, ...pprops } = this.props

    if (data.length === 0) {
      return (
        <CenterContent>
          <EmptySection
            style={{ fontSize: '1em', fontWeight: '400' }}
            icon={<Icon name="cloud" fa transform="grow-10" />}
          >
            <p style={{ marginTop: '0.5em' }}>
              <FormattedMessage {...messages.noResults} />
            </p>
          </EmptySection>
        </CenterContent>
      )
    }

    return (
      <Fragment>
        <DataTable
          pageSize={this.props.pageSize}
          data={data}
          customColumnHeaders={this.getCustomColumnHeaders()}
          hasCheckBoxes
          selectionChanged={this.onTableSelectionChange}
          checkboxSelectedRows={this.state.selectedRows}
          bulkActions={this.renderActions()}
          bulkActionWorking={this.getBulkActionWorking()}
          onLoad={this.onTableLoad}
          {...pprops}
        />
        <MonitoredResourcesExceededModal
          numExceeded={this.props.monitoringResourcesExceeded}
          toggle={this.clearMonitoringResourcesExceeded}
        />
        <AddTagsModal
          tagOptions={this.props.tagOptions}
          isOpen={this.state.addTagsModal}
          onSave={this.addTagsOnSelectedResources}
          onCancel={() => this.setState({ addTagsModal: false })}
        />
        <ChangeImportanceModal
          isOpen={this.state.isImportanceModalOpen}
          centered={true}
          toggle={this.toggleChangeImportanceModal}
          setImportance={this.changeImportanceMulti}
          isLoading={this.props.isMultiImportanceLoading}
        />
      </Fragment>
    )
  }
}

ResultTableWithActions.propTypes = {
  addTagsOnSelectedResources: PropTypes.func.isRequired,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  enablingMonitoring: PropTypes.bool,
  field: ImmutablePropTypes.contains({
    id: PropTypes.string.isRequired,
    definition: ImmutablePropTypes.contains({
      name: PropTypes.string.isRequired,
      type: ImmutablePropTypes.contains({
        name: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    name: PropTypes.string,
    parentId: PropTypes.string,
    selectionSet: ImmutablePropTypes.listOf(PropTypes.string),
  }),
  flatten: PropTypes.bool,
  isMultiImportanceLoading: PropTypes.bool,
  isUpdatingTags: PropTypes.bool,
  monitoringResourcesExceeded: PropTypes.number,
  query: ImmutablePropTypes.mapOf(
    ImmutablePropTypes.contains({
      definition: ImmutablePropTypes.contains({
        name: PropTypes.string.isRequired,
        type: ImmutablePropTypes.contains({
          name: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
      parentId: PropTypes.string,
    })
  ).isRequired,
  relations: ImmutablePropTypes.listOf(
    ImmutablePropTypes.contains({
      type: PropTypes.string.isRequired,
      relations: ImmutablePropTypes.listOf(
        ImmutablePropTypes.contains({
          reverse: PropTypes.string,
          name: PropTypes.string,
          to: PropTypes.string,
          from: PropTypes.string,
        })
      ).isRequired,
    })
  ).isRequired,
  setMonitoringOnResources: PropTypes.func.isRequired,
  setMonitoringOnResourcesError: PropTypes.func.isRequired,
  setMonitoringResourcesExceeded: PropTypes.func.isRequired,
  setImportanceMulti: PropTypes.func.isRequired,
  setImportanceMultiModal: PropTypes.func.isRequired,
  tagOptions: ImmutablePropTypes.iterable.isRequired,
  pageSize: PropTypes.number,
}

const mapStateToProps = createStructuredSelector({
  enablingMonitoring: selectEnablingMonitoring,
  monitoringResourcesExceeded: selectMonitoringResourcesExceeded,
  relations: selectQueryRelations,
  isImportModalOpen: selectIsMultiImportanceModalOpen,
  isMultiImportanceLoading: selectIsMultiImportanceLoading,
  isUpdatingTags: selectIsUpdatingTags,
  tagOptions: selectTags,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setMonitoringOnResources,
      setMonitoringOnResourcesError,
      setMonitoringResourcesExceeded,
      setImportanceMulti,
      setImportanceMultiModal,
      addTagsOnSelectedResources,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(ResultTableWithActions)
