import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { bindActionCreators, compose } from 'redux'
import { List } from 'immutable'

import Button from 'components/Button'
import IHelp from 'containers/IHelp'
import Popover, { PopoverBody, PopoverAnchor } from 'components/Popover'
import BorderedCard from 'components/BorderedCard'
import { getItemsType } from 'query-builder'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import ImmutablePureComponent from 'components/ImmutablePureComponent'
import {
  addArgument,
  addField,
  changeArgument,
  changeChildOperator,
  changeFieldName,
  removeArgument,
  removeField,
  moveArgument,
  addProperty,
  removeProperty,
  toggleDefaultProperty,
  addComment,
  removeComment,
  setQueryPropertyOrder,
  setQueryColumnAlias,
  addEffectivePermissionIncludeGroup,
  addEffectivePermissionExcludeGroup,
  addEffectivePermission,
  removeEffectivePermission,
  removeEffectivePermissionGroup,
  setEffectivePermissionPropertyOrder,
  removeQueryColumnAlias,
} from 'containers/SearchQuery/actions'
import { selectQueryStatements } from 'containers/SearchQuery/selectors'
import { selectHasCommentsHidden } from 'containers/Search/selectors'

import globalMessages from 'globalTranslations'
import Dropdown, {
  DropdownMenu,
  InlineDropdownAnchor,
  DropdownItem,
} from 'components/Dropdown'

import messages from './messages'
import NextCardSelectorCard from './NextCardSelectorCard'
import SearchCardHeader from './SearchCardHeader'
import SearchCardBody from './SearchCardBody'
import EffectivePermissionBlock from './EffectivePermissionBlock'

export class SearchCard extends ImmutablePureComponent {
  state = {
    nextCardSelectorOpen: false,
  }

  styles = {
    wrapper: {
      width: '500px',
      textAlign: 'left',
      position: 'relative',
      margin: 'auto',
      padding: '0.5em 1em',
    },
    addCardButton: {
      textAlign: 'center',
    },
    childOpSelector: {
      border: '1px solid #c8c8c8',
      display: 'inline-block',
      padding: '0 0.5em',
      backgroundColor: '#fff',
      borderRadius: '3px',
    },
    verticalLine: {
      height: '0.5em',
      borderLeft: '1px solid #c8c8c8',
      margin: 'auto auto',
      width: '0',
    },
    relationButton: {
      boxShadow: '0 1px 3px rgba(0,0,0,0.16), 0 1px 2px rgba(0,0,0,0.4)',
      whiteSpace: 'nowrap',
    },
    leftLine: {
      borderLeft: '1px solid #c8c8c8',
      margin: 'auto auto',
      width: '0',
      display: 'grid',
      gridTemplateColumns: '2em auto',
      padding: '1em 0 0 0',
    },
    horizontalLine: {
      borderBottom: '1px solid #c8c8c8',
      height: '50%',
    },
  }

  removeField = () => {
    this.props.removeField({ fieldId: this.props.field.get('id') })
  }

  toggleNextCardSelector = () => {
    this.setState(currentState => ({
      nextCardSelectorOpen: !currentState.nextCardSelectorOpen,
    }))
  }

  onAddField = args => {
    this.props.addField(args)

    this.setState({
      nextCardSelectorOpen: false,
    })
  }

  editCardName = name => {
    this.props.changeFieldName({
      field: { id: this.props.field.get('id') },
      name,
    })
  }

  onChangeChildOperator = selectedValue => {
    this.props.changeChildOperator({
      field: { id: this.props.field.get('id') },
      op: selectedValue.value,
    })
  }

  onRelationButtonToggle = newVisibility => {
    this.setState({
      nextCardSelectorOpen: newVisibility,
    })
  }

  addEPIncludeGroup = fieldId => {
    this.props.addEffectivePermissionIncludeGroup({ fieldId: fieldId })
    this.setState({
      nextCardSelectorOpen: false,
    })
  }

  addEPExcludeGroup = fieldId => {
    this.props.addEffectivePermissionExcludeGroup({ fieldId: fieldId })
    this.setState({
      nextCardSelectorOpen: false,
    })
  }

  renderAddRelationButton = label => {
    const { field, queryTypes } = this.props
    return (
      <Popover
        isOpen={this.state.nextCardSelectorOpen}
        onToggle={this.onRelationButtonToggle}
      >
        <PopoverAnchor>
          <Button
            onClick={this.toggleNextCardSelector}
            outline
            color="primary"
            style={this.styles.relationButton}
          >
            {label}
          </Button>
        </PopoverAnchor>
        <PopoverBody>
          <NextCardSelectorCard
            addField={this.onAddField}
            title={<FormattedMessage {...messages.nextCardSelectorTitle} />}
            field={field}
            types={queryTypes}
            effectivePermissions={{
              addEffectivePermissionIncludeGroup: this.addEPIncludeGroup,
              addEffectivePermissionExcludeGroup: this.addEPExcludeGroup,
            }}
          />
        </PopoverBody>
      </Popover>
    )
  }

  setOrder = params => {
    this.props.setEffectivePermissionPropertyOrder({
      fieldId: this.props.field.get('id'),
      ...params,
    })
  }

  renderAddRelation = () => {
    const { field } = this.props

    const hasChildren = !field.get('selectionSet', List()).isEmpty()

    if (hasChildren) {
      return (
        <Fragment>
          <div style={this.styles.leftLine}>
            <div style={this.styles.horizontalLine} />
            {this.renderAddRelationButton('Add Another Relation')}
          </div>
        </Fragment>
      )
    }

    return (
      <Fragment>
        <div style={this.styles.verticalLine} />
        {this.renderAddRelationButton('Add Relation')}
      </Fragment>
    )
  }

  renderRelationOperator = () => {
    const hasMultipleChildren =
      this.props.field.get('selectionSet', List()).size > 1

    if (!hasMultipleChildren) {
      return null
    }

    return (
      <Fragment>
        <div style={this.styles.verticalLine} />
        <div style={{ position: 'relative', display: 'inline' }}>
          <div style={this.styles.childOpSelector}>
            <Dropdown
              selectedValue={this.props.field.get('childOperator')}
              onClick={this.onChangeChildOperator}
            >
              <InlineDropdownAnchor />
              <DropdownMenu>
                <DropdownItem label="OR" defaultValue value="or" />
                <DropdownItem label="AND" value="and" />
              </DropdownMenu>
            </Dropdown>
          </div>
          <div
            style={{
              position: 'absolute',
              left: '100%',
              top: '-3px',
              paddingLeft: '0.5em',
            }}
          >
            <IHelp
              help
              helpKey="relationOperator"
              iconSize="18px"
              position="right"
            />
          </div>
        </div>
      </Fragment>
    )
  }

  renderEffectivePermissions = () => {
    return (
      <Fragment>
        <div style={this.styles.verticalLine} />
        <EffectivePermissionBlock
          field={this.props.field}
          queryTypes={this.props.queryTypes}
          setOrder={this.setOrder}
        />
      </Fragment>
    )
  }

  render() {
    const { field, queryTypes, hasCommentsHidden } = this.props
    const title = field.get('name')
    const hasComments =
      !hasCommentsHidden &&
      field.get('comments') &&
      !field.get('comments').isEmpty()
    const itemType = getItemsType(field, queryTypes)

    return (
      <Fragment>
        <BorderedCard
          style={
            hasComments
              ? { ...this.styles.wrapper, ...{ marginRight: '260px' } }
              : this.styles.wrapper
          }
        >
          <SearchCardHeader
            fieldTitle={
              <DynamicFormattedMessage
                defaultMessage={itemType}
                {...globalMessages.fields[itemType]}
              />
            }
            removeField={this.removeField}
          />

          <SearchCardBody
            changeArgument={this.props.changeArgument}
            field={field}
            removeArgument={this.props.removeArgument}
            addArgument={this.props.addArgument}
            types={queryTypes}
            title={title}
            onChangeTitle={this.editCardName}
            moveArgument={this.props.moveArgument}
            addProperty={this.props.addProperty}
            removeProperty={this.props.removeProperty}
            toggleDefaultProperty={this.props.toggleDefaultProperty}
            addComment={this.props.addComment}
            removeComment={this.props.removeComment}
            hasCommentsHidden={this.props.hasCommentsHidden}
            setQueryPropertyOrder={this.props.setQueryPropertyOrder}
            setQueryColumnAlias={this.props.setQueryColumnAlias}
            removeQueryColumnAlias={this.props.removeQueryColumnAlias}
          />
        </BorderedCard>
        {this.renderEffectivePermissions()}
        {this.renderRelationOperator()}
        {this.renderAddRelation()}
      </Fragment>
    )
  }
}

SearchCard.propTypes = {
  addArgument: PropTypes.func,
  addField: PropTypes.func,
  changeArgument: PropTypes.func,
  changeFieldName: PropTypes.func.isRequired,
  changeChildOperator: PropTypes.func,
  field: ImmutablePropTypes.contains({
    arguments: ImmutablePropTypes.map,
    id: PropTypes.string.isRequired,
    definition: ImmutablePropTypes.contains({
      name: PropTypes.string,
      type: ImmutablePropTypes.contains({
        name: PropTypes.string,
      }).isRequired,
      args: ImmutablePropTypes.list,
    }),
    selectionSet: ImmutablePropTypes.listOf(PropTypes.string),
    resultViews: ImmutablePropTypes.listOf(PropTypes.string),
  }),
  moveArgument: PropTypes.func.isRequired,
  queryStatements: ImmutablePropTypes.map.isRequired,
  removeArgument: PropTypes.func,
  removeField: PropTypes.func.isRequired,
  title: PropTypes.string,
  queryTypes: ImmutablePropTypes.mapOf(
    ImmutablePropTypes.contains({
      fields: ImmutablePropTypes.listOf(
        ImmutablePropTypes.contains({ name: PropTypes.string })
      ),
      name: PropTypes.string,
      enumValues: ImmutablePropTypes.listOf(
        ImmutablePropTypes.contains({ name: PropTypes.string })
      ),
      inputFields: ImmutablePropTypes.listOf(
        ImmutablePropTypes.contains({
          name: PropTypes.string,
          type: ImmutablePropTypes.contains({
            name: PropTypes.string,
          }),
        })
      ),
    })
  ),
  toggleDefaultProperty: PropTypes.func,
  addComment: PropTypes.func,
  removeComment: PropTypes.func,
  hasCommentsHidden: PropTypes.bool,
  setQueryPropertyOrder: PropTypes.func,
  setQueryColumnAlias: PropTypes.func,
  removeQueryColumnAlias: PropTypes.func,
}

const mapStateToProps = createStructuredSelector({
  queryStatements: selectQueryStatements,
  hasCommentsHidden: selectHasCommentsHidden,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addArgument,
      addField,
      changeArgument,
      changeChildOperator,
      changeFieldName,
      removeArgument,
      removeField,
      moveArgument,
      addProperty,
      removeProperty,
      toggleDefaultProperty,
      addComment,
      removeComment,
      setQueryPropertyOrder,
      setQueryColumnAlias,
      addEffectivePermissionIncludeGroup,
      addEffectivePermissionExcludeGroup,
      addEffectivePermission,
      removeEffectivePermission,
      removeEffectivePermissionGroup,
      setEffectivePermissionPropertyOrder,
      removeQueryColumnAlias,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(SearchCard)
