import React from 'react'
import PropTypes from 'prop-types'
import { Input } from 'reactstrap'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List, Map } from 'immutable'
import { injectIntl } from 'react-intl'
import { compose } from 'redux'
import SelectBar from 'components/SelectBar'
import messages from 'globalTranslations'
import { QUERY_FILTER_OP } from 'appConstants'
const styles = {
  optionsDropdown: {
    marginBottom: '0.5em',
  },
}

class IntOperator extends React.Component {
  onOperationChange = val => {
    if (val !== null) {
      const op = val.value
      this.props.onChange(Map({ op }))
    } else {
      this.props.removeArgument()
    }
  }

  onValueChange = ({ target: { value } }) => {
    this.props.onChange(Map({ value }))
  }

  renderOperationsSelection = () => {
    if (this.props.opType.isEmpty()) {
      return null
    }
    const options = this.props.opType
      .get('enumValues', List())
      .toJS()
      .filter(
        op =>
          op.name !== QUERY_FILTER_OP.IN_LIST &&
          op.name !== QUERY_FILTER_OP.NOT_IN_LIST
      )
    return (
      <div style={styles.optionsDropdown}>
        <SelectBar
          options={options.map(op => ({
            label: this.props.intl.formatMessage(messages.operations[op.name]),
            value: op.name,
          }))}
          onChange={this.onOperationChange}
          value={this.props.argumentValue.get('op')}
          isClearable={false}
        />
      </div>
    )
  }

  render() {
    return (
      <div>
        {this.renderOperationsSelection()}
        <Input
          type="number"
          value={this.props.argumentValue.get('value')}
          onChange={this.onValueChange}
        />
      </div>
    )
  }
}

IntOperator.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  argumentValue: ImmutablePropTypes.map.isRequired,
  onChange: PropTypes.func.isRequired,
  removeArgument: PropTypes.func.isRequired,
  opType: ImmutablePropTypes.map.isRequired,
}

export default compose(injectIntl)(IntOperator)
