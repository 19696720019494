import React from 'react'
import { injectIntl, intlShape } from 'react-intl'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List, fromJS } from 'immutable'
import _ from 'lodash'

import SelectBar from 'components/SelectBar'
import ImmutablePureComponent from 'components/ImmutablePureComponent'
import messages from 'globalTranslations'

import { stripExtraDefinitionProperties } from 'query-builder'

const styles = {
  container: {
    width: '400px',
  },
}

export class NextCardSelectorCard extends ImmutablePureComponent {
  displayName = 'NextCardSelectorCard'

  addField = newFieldDef => {
    if (!newFieldDef) {
      return
    }

    if (newFieldDef.value.name === 'hasEffectivePermissions') {
      if (newFieldDef.value.epType === 'include') {
        this.props.effectivePermissions.addEffectivePermissionIncludeGroup(
          this.props.field.get('id')
        )
      } else if (newFieldDef.value.epType === 'exclude')
        this.props.effectivePermissions.addEffectivePermissionExcludeGroup(
          this.props.field.get('id')
        )
    } else {
      this.props.addField({
        definition: stripExtraDefinitionProperties(
          fromJS(newFieldDef.value)
        ).toJS(),
        to: this.props.field.get('id'),
      })
    }
  }

  getListContent = () => {
    const { types, field, intl } = this.props

    const fieldType = types.get(field.getIn(['definition', 'type', 'name']))
    const itemsDefinition = fieldType
      .get('fields')
      .find(f => f.get('name') === 'items', null, Map())
    const itemsType = types.get(
      itemsDefinition.getIn(['type', 'ofType', 'name']),
      Map()
    )
    let hasEffectivePermissions = null
    let content = itemsType
      .get('fields', List())
      .filter(field => {
        if (
          field.getIn(['type', 'name'], '') === 'EffectivePermissionRelation'
        ) {
          hasEffectivePermissions = field
        }
        if (
          field.getIn(['type', 'kind']) === 'OBJECT' &&
          field.getIn(['type', 'name'], '').endsWith('EdgeRelation')
        ) {
          return true
        } else {
          return false
        }
      })
      .map(fieldDef => {
        const relationName = fieldDef.get('name')

        const relationDisplayName = intl.formatMessage({
          id: 'undefined.translation',
          defaultMessage: _.startCase(fieldDef.get('name')),
          ...messages.fields[fieldDef.get('name')],
        })

        const fieldTypeName = fieldDef
          .getIn(['type', 'name'], '')
          .replace(/EdgeRelation/, '')
        const fieldTypeDisplayName = intl.formatMessage({
          id: 'undefined.translation',
          defaultMessage: fieldDef
            .getIn(['type', 'name'], '')
            .replace(/EdgeRelation/, ''),
          ...messages.fields[fieldTypeName],
        })

        if (relationName.toLowerCase().endsWith(fieldTypeName.toLowerCase())) {
          return fieldDef.set('displayLabel', relationDisplayName)
        } else {
          return fieldDef.set(
            'displayLabel',
            `${relationDisplayName} ${fieldTypeDisplayName}`
          )
        }
      })

    if (hasEffectivePermissions) {
      content = content.push(
        hasEffectivePermissions
          .set('displayLabel', 'Includes Effective Permission')
          .set('epType', 'include')
      )
      content = content.push(
        hasEffectivePermissions
          .set('displayLabel', 'Excludes Effective Permission')
          .set('epType', 'exclude')
      )
    }

    return content.sortBy(fieldDef =>
      fieldDef.get('displayLabel').toLowerCase()
    )
  }

  render() {
    const { title } = this.props

    const nextFieldDefs = this.getListContent()

    return (
      <div style={styles.container}>
        <SelectBar
          autoFocus
          isClearable
          name="relationsList"
          value={this.props.selectedFieldId}
          options={nextFieldDefs
            .map(field => ({
              value: field,
              label: field.get('displayLabel'),
            }))
            .toJS()}
          onChange={this.addField}
          placeholder={title}
          menuPlacement={
            this.props.field.get('selectionSet').isEmpty() &&
            this.props.field.get('parentId')
              ? 'top'
              : 'auto'
          }
        />
      </div>
    )
  }
}

NextCardSelectorCard.propTypes = {
  addField: PropTypes.func.isRequired,
  field: ImmutablePropTypes.contains({
    id: PropTypes.string.isRequired,
    definition: ImmutablePropTypes.contains({
      type: ImmutablePropTypes.contains({
        name: PropTypes.string,
      }).isRequired,
    }).isRequired,
    selectionSet: ImmutablePropTypes.listOf(PropTypes.string),
  }).isRequired,
  intl: intlShape, //added by Intl provider
  title: PropTypes.node,
  types: ImmutablePropTypes.mapOf(
    ImmutablePropTypes.contains({
      fields: ImmutablePropTypes.listOf(
        ImmutablePropTypes.contains({
          kind: PropTypes.string,
          name: PropTypes.string,
          type: ImmutablePropTypes.contains({
            ofType: ImmutablePropTypes.contains({
              name: PropTypes.string,
            }),
          }),
        })
      ),
    })
  ).isRequired,
}

export default injectIntl(NextCardSelectorCard)
