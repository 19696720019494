import React, { Component } from 'react'
import PropTypes from 'prop-types'

import TextLink from 'components/TextLink'

class TabbedScrollable extends Component {
  constructor(props) {
    super(props)
    this.sectionContentRef = React.createRef()
    this.sectionRefs = {}

    this.styles = {
      container: {
        display: 'grid',
        height: '100%',
        gridTemplateColumns: 'auto 1fr',
      },
      sectionContent: {
        paddingTop: '14px',
        marginBottom: '14px',
      },
      sectionContentContainer: {
        overflowY: 'scroll',
      },
      sectionLinks: {
        height: '100%',
        borderRight: '1px solid #eee',
        marginRight: '10px',
      },
      sectionLink: {
        textAlign: 'right',
        paddingTop: '14px',
        padding: '14px',
      },
    }
  }

  /**
   * Scroll the permission sections to the section the user clicked
   */
  handleSectionLinkClick = async sectionKey => {
    const section = this.sectionRefs[sectionKey]

    section.scrollIntoView({
      block: 'start',
      inline: 'nearest',
      behavior: 'smooth',
    })
  }

  renderSectionLink = (sectionKey, displayLabel) => {
    return (
      <div key={sectionKey} style={this.styles.sectionLink}>
        <TextLink
          title="Scroll to section"
          color="primary"
          onClick={() => this.handleSectionLinkClick(sectionKey)}
        >
          {displayLabel}
        </TextLink>
      </div>
    )
  }

  render() {
    const sectionLinks = []
    const sectionContent = React.Children.map(this.props.children, child => {
      if (!child) {
        return null
      }

      const key = child.props.sectionKey
      const displayLabel = child.props.displayLabel
      sectionLinks.push(this.renderSectionLink(key, displayLabel))

      return (
        <div
          className="section-content"
          key={key}
          style={this.styles.sectionContent}
          ref={element => {
            this.sectionRefs[key] = element
          }}
        >
          {child}
        </div>
      )
    })

    return (
      <div style={this.styles.container}>
        <div style={this.styles.sectionLinks}>{sectionLinks}</div>
        <div
          style={this.styles.sectionContentContainer}
          ref={this.sectionContentRef}
        >
          {sectionContent}
        </div>
      </div>
    )
  }
}

TabbedScrollable.propTypes = {
  children: PropTypes.node,
}

export default TabbedScrollable
export { default as TabbedScrollableSection } from './TabbedScrollableSection'
