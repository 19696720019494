import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { SingleDatePicker } from 'react-dates'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List, Map } from 'immutable'
import { injectIntl } from 'react-intl'
import { compose } from 'redux'
import SelectBar from 'components/SelectBar'
import globalMessages from 'globalTranslations'
import messages from './messages'
import { FILTER_DATE_FORMAT } from 'appConstants'
import { exists, monthsToDays } from 'utils/sonraiUtils'

const styles = {
  optionsDropdown: {
    marginBottom: '0.5em',
  },
  optionsLine: {
    borderTop: '1px black solid',
    opacity: '0.5',
  },
}

class DateTime extends React.Component {
  constructor(props) {
    super(props)

    const value = props.argumentValue.get('value')
    const values = props.argumentValue.get('values', List())

    this.state = {
      datePickerFocused: false,
      endDatePickerFocused: false,
      startDate: value
        ? moment(value, FILTER_DATE_FORMAT)
        : values !== null && !values.isEmpty()
        ? moment(values.get(0), FILTER_DATE_FORMAT)
        : null,
      endDate:
        values !== null && !values.isEmpty()
          ? moment(values.get(1), FILTER_DATE_FORMAT)
          : null,
      onEndDateValueChange: null,
    }
    this.dateOffsetOptions = [
      {
        label: props.intl.formatMessage(messages.relativeDates.fixed),
        value: null,
      },
      {
        options: [
          {
            label: props.intl.formatMessage(messages.relativeDates.inPastHour1),
            value: {
              tense: 'past',
              type: 'GT',
              measure: 'hours',
              number: 1,
            },
          },
          {
            label: props.intl.formatMessage(messages.relativeDates.inPastHour3),
            value: {
              tense: 'past',
              type: 'GT',
              measure: 'hours',
              number: 3,
            },
          },
          {
            label: props.intl.formatMessage(
              messages.relativeDates.inPastHour12
            ),
            value: {
              tense: 'past',
              type: 'GT',
              measure: 'hours',
              number: 12,
            },
          },
          {
            label: props.intl.formatMessage(messages.relativeDates.inPastDay1),
            value: {
              tense: 'past',
              type: 'GT',
              measure: 'days',
              number: 1,
            },
          },
          {
            label: props.intl.formatMessage(messages.relativeDates.inPastDay7),
            value: {
              tense: 'past',
              type: 'GT',
              measure: 'days',
              number: 7,
            },
          },
          {
            label: props.intl.formatMessage(messages.relativeDates.inPastDay30),
            value: {
              tense: 'past',
              type: 'GT',
              measure: 'days',
              number: 30,
            },
          },
          {
            label: props.intl.formatMessage(messages.relativeDates.inPastDay90),
            value: {
              tense: 'past',
              type: 'GT',
              measure: 'days',
              number: 90,
            },
          },
          {
            label: props.intl.formatMessage(
              messages.relativeDates.inPastMonth6
            ),
            value: {
              tense: 'past',
              type: 'GT',
              measure: 'days',
              number: monthsToDays(6),
            },
          },
          {
            label: props.intl.formatMessage(
              messages.relativeDates.inPastMonth9
            ),
            value: {
              tense: 'past',
              type: 'GT',
              measure: 'days',
              number: monthsToDays(9),
            },
          },
          {
            label: props.intl.formatMessage(
              messages.relativeDates.inPastMonth12
            ),
            value: {
              tense: 'past',
              type: 'GT',
              measure: 'days',
              number: monthsToDays(12),
            },
          },
        ],
      },
      {
        label: <div style={styles.optionsLine}>&nbsp;</div>,
        options: [
          {
            label: props.intl.formatMessage(
              messages.relativeDates.outPastHour1
            ),
            value: {
              tense: 'past',
              type: 'LT',
              measure: 'hours',
              number: 1,
            },
          },
          {
            label: props.intl.formatMessage(
              messages.relativeDates.outPastHour3
            ),
            value: {
              tense: 'past',
              type: 'LT',
              measure: 'hours',
              number: 3,
            },
          },
          {
            label: props.intl.formatMessage(
              messages.relativeDates.outPastHour12
            ),
            value: {
              tense: 'past',
              type: 'LT',
              measure: 'hours',
              number: 12,
            },
          },
          {
            label: props.intl.formatMessage(messages.relativeDates.outPastDay1),
            value: {
              tense: 'past',
              type: 'LT',
              measure: 'days',
              number: 1,
            },
          },
          {
            label: props.intl.formatMessage(messages.relativeDates.outPastDay7),
            value: {
              tense: 'past',
              type: 'LT',
              measure: 'days',
              number: 7,
            },
          },
          {
            label: props.intl.formatMessage(
              messages.relativeDates.outPastDay30
            ),
            value: {
              tense: 'past',
              type: 'LT',
              measure: 'days',
              number: 30,
            },
          },
          {
            label: props.intl.formatMessage(
              messages.relativeDates.outPastDay90
            ),
            value: {
              tense: 'past',
              type: 'LT',
              measure: 'days',
              number: 90,
            },
          },
          {
            label: props.intl.formatMessage(
              messages.relativeDates.outPastMonth6
            ),
            value: {
              tense: 'past',
              type: 'LT',
              measure: 'days',
              number: monthsToDays(6),
            },
          },
          {
            label: props.intl.formatMessage(
              messages.relativeDates.outPastMonth9
            ),
            value: {
              tense: 'past',
              type: 'LT',
              measure: 'days',
              number: monthsToDays(9),
            },
          },
          {
            label: props.intl.formatMessage(
              messages.relativeDates.outPastMonth12
            ),
            value: {
              tense: 'past',
              type: 'LT',
              measure: 'days',
              number: monthsToDays(12),
            },
          },
        ],
      },
      {
        label: <div style={styles.optionsLine}>&nbsp;</div>,
        options: [
          {
            label: props.intl.formatMessage(
              messages.relativeDates.outFutureHour1
            ),
            value: {
              tense: 'future',
              type: 'LT',
              measure: 'hours',
              number: 1,
            },
          },
          {
            label: props.intl.formatMessage(
              messages.relativeDates.outFutureHour3
            ),
            value: {
              tense: 'future',
              type: 'LT',
              measure: 'hours',
              number: 3,
            },
          },
          {
            label: props.intl.formatMessage(
              messages.relativeDates.outFutureHour12
            ),
            value: {
              tense: 'future',
              type: 'LT',
              measure: 'hours',
              number: 12,
            },
          },
          {
            label: props.intl.formatMessage(
              messages.relativeDates.outFutureDay1
            ),
            value: {
              tense: 'future',
              type: 'LT',
              measure: 'days',
              number: 1,
            },
          },
          {
            label: props.intl.formatMessage(
              messages.relativeDates.outFutureDay7
            ),
            value: {
              tense: 'future',
              type: 'LT',
              measure: 'days',
              number: 7,
            },
          },
          {
            label: props.intl.formatMessage(
              messages.relativeDates.outFutureDay30
            ),
            value: {
              tense: 'future',
              type: 'LT',
              measure: 'days',
              number: 30,
            },
          },
          {
            label: props.intl.formatMessage(
              messages.relativeDates.outFutureDay90
            ),
            value: {
              tense: 'future',
              type: 'LT',
              measure: 'days',
              number: 90,
            },
          },
          {
            label: props.intl.formatMessage(
              messages.relativeDates.outFutureMonth6
            ),
            value: {
              tense: 'future',
              type: 'LT',
              measure: 'days',
              number: monthsToDays(6),
            },
          },
          {
            label: props.intl.formatMessage(
              messages.relativeDates.outFutureMonth9
            ),
            value: {
              tense: 'future',
              type: 'LT',
              measure: 'days',
              number: monthsToDays(9),
            },
          },
          {
            label: props.intl.formatMessage(
              messages.relativeDates.outFutureMonth12
            ),
            value: {
              tense: 'future',
              type: 'LT',
              measure: 'days',
              number: monthsToDays(12),
            },
          },
        ],
      },
      {
        label: <div style={styles.optionsLine}>&nbsp;</div>,
        options: [
          {
            label: props.intl.formatMessage(
              messages.relativeDates.inFutureHour1
            ),
            value: {
              tense: 'future',
              type: 'GT',
              measure: 'hours',
              number: 1,
            },
          },
          {
            label: props.intl.formatMessage(
              messages.relativeDates.inFutureHour3
            ),
            value: {
              tense: 'future',
              type: 'GT',
              measure: 'hours',
              number: 3,
            },
          },
          {
            label: props.intl.formatMessage(
              messages.relativeDates.inFutureHour12
            ),
            value: {
              tense: 'future',
              type: 'GT',
              measure: 'hours',
              number: 12,
            },
          },
          {
            label: props.intl.formatMessage(
              messages.relativeDates.inFutureDay1
            ),
            value: {
              tense: 'future',
              type: 'GT',
              measure: 'days',
              number: 1,
            },
          },
          {
            label: props.intl.formatMessage(
              messages.relativeDates.inFutureDay7
            ),
            value: {
              tense: 'future',
              type: 'GT',
              measure: 'days',
              number: 7,
            },
          },
          {
            label: props.intl.formatMessage(
              messages.relativeDates.inFutureDay30
            ),
            value: {
              tense: 'future',
              type: 'GT',
              measure: 'days',
              number: 30,
            },
          },
          {
            label: props.intl.formatMessage(
              messages.relativeDates.inFutureDay90
            ),
            value: {
              tense: 'future',
              type: 'GT',
              measure: 'days',
              number: 90,
            },
          },
          {
            label: props.intl.formatMessage(
              messages.relativeDates.inFutureMonth6
            ),
            value: {
              tense: 'future',
              type: 'GT',
              measure: 'days',
              number: monthsToDays(6),
            },
          },
          {
            label: props.intl.formatMessage(
              messages.relativeDates.inFutureMonth9
            ),
            value: {
              tense: 'future',
              type: 'GT',
              measure: 'days',
              number: monthsToDays(9),
            },
          },
          {
            label: props.intl.formatMessage(
              messages.relativeDates.inFutureMonth12
            ),
            value: {
              tense: 'future',
              type: 'GT',
              measure: 'days',
              number: monthsToDays(12),
            },
          },
        ],
      },
    ]
  }

  onStartDateValueChange = momentDate => {
    if (momentDate) {
      this.setState({
        startDate: momentDate ? momentDate.format(FILTER_DATE_FORMAT) : null,
      })
      if (!momentDate) {
        return this.props.removeArgument()
      } else if (
        this.props.argumentValue.get('op') !== 'BETWEEN' &&
        this.props.argumentValue.get('op') !== 'NOT_BETWEEN'
      ) {
        const value = momentDate.format(FILTER_DATE_FORMAT)
        this.props.onChange(Map({ value }))
      } else {
        if (this.state.endDate) {
          const value = momentDate.format(FILTER_DATE_FORMAT)
          const endDate = this.state.endDate._isAMomentObject
            ? this.state.endDate.format(FILTER_DATE_FORMAT)
            : this.state.endDate
          this.props.onChange(Map({ values: [value, endDate] }))
        }
      }
    }
  }

  onEndDateValueChange = momentDate => {
    if (!momentDate) {
      this.setState({
        endDate: null,
      })
      return this.props.removeArgument()
    }
    const value = momentDate.format(FILTER_DATE_FORMAT)
    this.setState({ endDate: value })
    if (this.state.startDate) {
      const startDate = this.state.startDate._isAMomentObject
        ? this.state.startDate.format(FILTER_DATE_FORMAT)
        : this.state.startDate
      this.props.onChange(Map({ values: [startDate, value] }))
    }
  }

  onOperationChange = val => {
    if (val !== null) {
      const op = val.value
      this.props.onChange(Map({ op }))
    } else {
      this.props.removeArgument()
    }
  }

  onSelectRelativeDate = val => {
    if (val.value !== null) {
      const dateOffset = val.value
      this.props.onChange(Map({ dateOffset }))
    } else {
      this.showFixedDate()
    }
  }

  showFixedDate = () => {
    this.props.onChange(Map({ dateOffset: null }))
  }

  renderOperationsSelection = () => {
    if (this.props.opType.isEmpty()) {
      return null
    }
    const options = this.props.opType.get('enumValues', List()).toJS()

    return (
      <div style={styles.optionsDropdown}>
        <SelectBar
          options={options.map(op => ({
            label: this.props.intl.formatMessage(
              globalMessages.operations[op.name]
            ),
            value: op.name,
          }))}
          onChange={this.onOperationChange}
          value={this.props.argumentValue.get('op')}
          isClearable={false}
        />
      </div>
    )
  }

  getEndDateValue = () => {
    return exists(this.state.endDate)
      ? moment(this.state.endDate, FILTER_DATE_FORMAT)
      : null
  }

  getStartDateValue = () => {
    return exists(this.state.startDate)
      ? moment(this.state.startDate, FILTER_DATE_FORMAT)
      : null
  }

  render() {
    const showBothDatePickers =
      this.props.argumentValue.get('op') === 'BETWEEN' ||
      this.props.argumentValue.get('op') === 'NOT_BETWEEN'
        ? true
        : false

    return (
      <div>
        <div style={styles.optionsDropdown}>
          <SelectBar
            autoSort={false}
            options={this.dateOffsetOptions}
            isClearable={false}
            value={this.props.argumentValue.get('dateOffset')}
            onChange={this.onSelectRelativeDate}
          />
        </div>

        {!this.props.argumentValue.get('dateOffset') && (
          <div>
            {this.renderOperationsSelection()}
            <SingleDatePicker
              noBorder
              date={this.getStartDateValue()}
              isOutsideRange={() => false}
              onDateChange={this.onStartDateValueChange}
              focused={this.state.datePickerFocused}
              onFocusChange={({ focused: datePickerFocused }) =>
                this.setState({ datePickerFocused })
              }
              enableOutsideDays={false}
              displayFormat={FILTER_DATE_FORMAT}
            />
          </div>
        )}
        {!this.props.argumentValue.get('dateOffset') && showBothDatePickers && (
          <div style={{ marginTop: '0.5rem' }}>
            <SingleDatePicker
              noBorder
              date={this.getEndDateValue()}
              isOutsideRange={() => false}
              onDateChange={this.onEndDateValueChange}
              focused={this.state.endDatePickerFocused}
              onFocusChange={({ focused: datePickerFocused }) =>
                this.setState({ endDatePickerFocused: datePickerFocused })
              }
              enableOutsideDays={false}
              displayFormat={FILTER_DATE_FORMAT}
            />
          </div>
        )}
      </div>
    )
  }
}

DateTime.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  argumentValue: ImmutablePropTypes.map.isRequired,
  onChange: PropTypes.func.isRequired,
  removeArgument: PropTypes.func.isRequired,
  opType: ImmutablePropTypes.map.isRequired,
}

export default compose(injectIntl)(DateTime)
