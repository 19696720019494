/* eslint-disable react/no-unescaped-entities */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import Icon from 'components/Icon'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import Popover, { PopoverBody, PopoverAnchor } from 'components/Popover'
import { Input } from 'reactstrap'
import Button from 'components/Button'
import TextLink from 'components/TextLink'

class SearchCardComments extends Component {
  constructor(props) {
    super(props)

    this.state = {
      modal: false,
      value: '',
    }

    this.styles = {
      icon: {
        fontSize: '1.3rem',
        color: props.theme.neutralMedium,
        cursor: 'pointer',
      },
      container: {
        width: '400px',
      },
      footer: {
        marginTop: '1em',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      },
      submitButton: {
        marginLeft: 'auto',
      },
      cancelButton: {
        marginRight: '0.5em',
      },
      commentContainter: {
        position: 'absolute',
        height: '100%',
        right: '-350px',
        padding: '10px',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '350px',
        top: '1px',
      },
      comment: {
        background: `rgb(236, 236, 236)`,
        display: 'flex',
        margin: '5px',
        padding: '14px',
        borderRadius: '10px',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      commentText: {
        fontSize: '1rem',
        color: `#424242`,
        fontStyle: 'italic',
      },
    }
  }

  handleAddComment = () => {
    this.setState({ value: '' })
    this.props.addComment({
      fieldId: this.props.field.get('id'),
      comment: this.state.value.trim(),
    })
    this.props.toggleAddComment()
  }

  render() {
    const { toggle, hasCommentsHidden } = this.props
    return (
      <div>
        <Popover isOpen={toggle} position="right-start">
          <PopoverAnchor>
            <div
              title="Add Comment"
              style={{ padding: '10px 0px 10px 10px' }}
              onClick={this.props.toggleAddComment}
            >
              <Icon fa name="add-comment" style={this.styles.icon} />
            </div>
          </PopoverAnchor>
          <PopoverBody>
            <div style={this.styles.container}>
              <Input
                type="textarea"
                placeholder="Your comment..."
                value={this.state.value}
                style={{
                  width: '100%',
                  minHeight: '50px',
                }}
                onChange={event => this.setState({ value: event.target.value })}
              />

              <div style={this.styles.footer}>
                <TextLink
                  color="primary"
                  onClick={this.props.toggleAddComment}
                  style={this.styles.cancelButton}
                >
                  Cancel
                </TextLink>
                <Button
                  color="primary"
                  onClick={this.handleAddComment}
                  disabled={this.state.value.trim() === ''}
                >
                  Add
                </Button>
              </div>
            </div>
          </PopoverBody>
        </Popover>

        {this.props.field.get('comments') &&
          !this.props.field.get('comments').isEmpty() &&
          !hasCommentsHidden && (
            <div style={this.styles.commentContainter}>
              <div style={{ maxHeight: '450px', overflow: 'auto' }}>
                {this.props.field.get('comments').map((comment, i) => (
                  <div key={i} style={this.styles.comment}>
                    <div>
                      <span style={this.styles.commentText}>{comment} </span>
                    </div>
                    <div
                      style={{ cursor: 'pointer', marginLeft: '5px' }}
                      onClick={() =>
                        this.props.removeComment({
                          fieldId: this.props.field.get('id'),
                          comment,
                        })
                      }
                    >
                      <Icon
                        style={{
                          color: this.props.theme.neutralMedium,
                        }}
                        fa
                        name="trash"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
      </div>
    )
  }
}

SearchCardComments.propTypes = {
  theme: themeShape,
  field: ImmutablePropTypes.iterable,
  removeComment: PropTypes.func,
  addComment: PropTypes.func,
  toggle: PropTypes.bool,
  toggleAddComment: PropTypes.func,
  hasCommentsHidden: PropTypes.bool,
}

export default themeable(SearchCardComments)
