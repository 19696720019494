import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import _ from 'lodash'
import { List } from 'immutable'

import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import messages from 'globalTranslations'
import { QUERY_FILTER_OP } from 'appConstants'

import EnumDisplay from './EnumDisplay'
import AccountDisplay from './AccountDisplay'
import BooleanDisplay from './BooleanDisplay'
import DateTimeDisplay from './DateTimeDisplay'
import StringDisplay from './StringDisplay'
import SwimlaneFilterDisplay from './SwimlaneFilterDisplay'

const styles = {
  text: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  lowerCase: {
    textTransform: 'lowercase',
  },
}

export class FilterStringDisplay extends React.Component {
  getComponent = () => {
    let filterFieldType
    const fieldName = this.getArgName()
    if (this.props.filterFieldType) {
      filterFieldType = this.props.filterFieldType
    } else {
      const filterArgDef = this.props.card
        .getIn(['definition', 'args'], List())
        .find(argDef => argDef.get('name') === 'where')

      filterFieldType = filterArgDef.getIn(['type', 'name'])
    }

    const fieldDef = this.props.types
      .getIn([filterFieldType, 'inputFields'], List())
      .find(inputField => inputField.get('name') === fieldName)

    const op = this.getArgVal().get('op')
    if (
      fieldName === 'account' &&
      (op === QUERY_FILTER_OP.EQUALS || op === QUERY_FILTER_OP.NOT_EQUALS)
    ) {
      return AccountDisplay
    }

    if (fieldName === 'swimlaneSRNs') {
      return SwimlaneFilterDisplay
    }

    switch (fieldDef.getIn(['type', 'name'])) {
      case 'StringOperator':
        return StringDisplay
      case 'DateTimeOperator':
        return DateTimeDisplay
      case 'BooleanOperator':
        return BooleanDisplay
      default: {
        return EnumDisplay
      }
    }
  }

  getArgVal = () => {
    return this.props.arg.first()
  }

  getArgName = () => {
    return this.props.arg.keySeq().first()
  }

  renderOperator = () => {
    if (
      this.getArgVal().get('dateOffset') !== undefined &&
      this.getArgVal().get('dateOffset') !== null
    ) {
      return ''
    }

    return (
      <span style={styles.lowerCase}>
        &nbsp;
        <DynamicFormattedMessage
          defaultMessage={this.getArgVal().get('op')}
          {...messages.operations[this.getArgVal().get('op')]}
        />
      </span>
    )
  }

  getTitle = () => {
    const fieldName = _.startCase(this.getArgName()) || ''
    const opName = messages.operations[this.getArgVal().get('op')]
      ? messages.operations[
          this.getArgVal().get('op')
        ].defaultMessage.toLowerCase()
      : ''
    const value = this.getArgVal().get('value') || ''

    return `${fieldName} ${opName} ${value}`
  }

  render() {
    const fieldName = this.getArgName()
    const DisplayComponent = this.getComponent()
    const moreStyles = this.props.moreStyles ? this.props.moreStyles : {}
    const title = this.getTitle()

    return (
      <em
        title={title}
        data-sonrai-id="display-content"
        style={{ ...styles.text, ...moreStyles }}
      >
        <DynamicFormattedMessage
          defaultMessage={_.startCase(fieldName)}
          {...messages.fields[fieldName]}
        />
        {this.renderOperator()}
        &nbsp;
        <DisplayComponent arg={this.props.arg} />
      </em>
    )
  }
}

FilterStringDisplay.propTypes = {
  arg: ImmutablePropTypes.mapOf(
    ImmutablePropTypes.contains({
      id: PropTypes.string.isRequired,
      value: PropTypes.any,
      op: PropTypes.string,
    })
  ),
  card: ImmutablePropTypes.contains({
    id: PropTypes.string.isRequired,
    definition: ImmutablePropTypes.contains({
      name: PropTypes.string,
      type: ImmutablePropTypes.contains({
        name: PropTypes.string,
      }).isRequired,
      args: ImmutablePropTypes.list,
    }),
  }),
  types: ImmutablePropTypes.iterable.isRequired,
  filterFieldType: PropTypes.string,
  moreStyles: PropTypes.object,
}

export default FilterStringDisplay
