import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List } from 'immutable'
import { FormattedMessage } from 'react-intl'
import _ from 'lodash'
import {
  getItemsType,
  getFieldFiltersType,
  getFilterProperties,
  getDefaultColumnsForField,
} from 'query-builder'
import uuid from 'uuid/v4'

import InlineEditableText from 'components/InlineEditableText'
import TextLink from 'components/TextLink'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import Icon from 'components/Icon'
import Popover, { PopoverBody, PopoverAnchor } from 'components/Popover'
import globalMessages from 'globalTranslations'
import SearchInput from 'components/SearchInput'

import messages from './messages'
import EditFilter from './EditFilter'
import EditProperty from './EditProperty'
import FilterDisplayGroup from './FilterDisplayGroup'
import PropertySelect from './PropertySelect'
import SearchCardComments from './SearchCardComments'

class SearchCardBody extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showAddComment: false,
      showAddPopover: false,
      showPropertyPopover: false,
      quickFilter: '',
    }

    this.styles = {
      filterHeader: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        marginBottom: '0.5em',
      },
      filterHeaderText: {
        fontVariant: 'small-caps',
        color: props.theme.neutralDark,
        fontSize: '1.3em',
        marginTop: '-0.2em',
      },
      nameInputWrapper: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        alignContent: 'center',
        margin: '0 -0.5em',
      },
      name: {
        fontSize: '1.3em',
        paddingLeft: '0.3em',
        width: '100%',
        whiteSpace: 'nowrap',
        overflowX: 'hidden',
        alignItems: 'center',
      },
    }
  }

  toggleAddPopover = () => {
    this.setState(currentState => ({
      showAddPopover: !currentState.showAddPopover,
    }))
  }

  showPropertyPopover = () => {
    this.setState(currentState => ({
      showPropertyPopover: !currentState.showPropertyPopover,
    }))
  }

  onAddButtonToggle = newVisibility => {
    this.setState({
      showAddPopover: newVisibility,
    })
  }

  onSubmit = vals => {
    this.props.changeArgument(vals)
    this.setState({
      showAddPopover: false,
    })
  }

  onAddArgument = vals => {
    this.props.addArgument(vals)
    this.setState({
      showAddPopover: false,
    })
  }

  onAddProperty = property => {
    this.props.addProperty(property)
    this.setState({
      showPropertyPopover: false,
    })
  }

  removeProperty = col => {
    this.props.removeProperty({
      fieldId: this.props.field.get('id'),
      property: col,
    })
  }

  toggleDefaultProperty = (col, remove) => {
    this.props.toggleDefaultProperty({
      queryId: this.props.field.get('id'),
      property: col,
      remove: remove,
    })
    this.setState({
      showPropertyPopover: false,
    })
  }

  moveFilter = argId => {
    this.props.moveArgument({
      fieldId: this.props.field.get('id'),
      id: argId,
    })
  }

  deleteFilter = argId => {
    this.props.removeArgument({
      fromFieldId: this.props.field.get('id'),
      id: argId,
    })
  }

  getAndArgs = () => {
    return this.props.field
      .getIn(['arguments', 'and'], List())
      .filter(argumentValue => {
        return (
          argumentValue !== undefined &&
          (argumentValue.get('value') !== '' ||
            (argumentValue.get('dateOffset') !== undefined &&
              argumentValue.get('dateOffset') !== null))
        )
      })
  }

  getOrArgs = () => {
    return this.props.field
      .getIn(['arguments', 'or'], List())
      .filter(argumentValue => {
        return (
          argumentValue !== undefined &&
          (argumentValue.get('value') !== '' ||
            (argumentValue.get('dateOffset') !== undefined &&
              argumentValue.get('dateOffset') !== null))
        )
      })
  }

  renderFilters = (orArgs, andArgs) => {
    const { field, removeArgument } = this.props
    if (orArgs.isEmpty() && andArgs.isEmpty()) {
      return null
    }

    return (
      <Fragment>
        {!andArgs.isEmpty() && (
          <FilterDisplayGroup
            label="Must match all of these filters"
            badgeLabel="AND"
            args={andArgs}
            onSubmit={this.onSubmit}
            removeArgument={removeArgument}
            field={field}
            types={this.props.types}
            opGroup="and"
            deleteFilter={this.deleteFilter}
            moveFilter={this.moveFilter}
          />
        )}

        {!orArgs.isEmpty() && (
          <FilterDisplayGroup
            label="Match one or more of these filters"
            badgeLabel="&nbsp;OR&nbsp;"
            args={orArgs}
            onSubmit={this.onSubmit}
            removeArgument={removeArgument}
            field={field}
            types={this.props.types}
            opGroup="or"
            deleteFilter={this.deleteFilter}
            moveFilter={this.moveFilter}
          />
        )}
      </Fragment>
    )
  }

  handleQuickFilter = e => {
    if (e.key === 'Enter') {
      const { quickFilter } = this.state
      const args = this.props.field.getIn(['arguments', 'or'], List())
      const argValues = _.uniq(
        args
          .map(arg => {
            const key = _.first(_.keys(arg.toJS()))
            return arg.getIn([key, 'value'])
          })
          .toJS()
      )
      if (quickFilter !== '' && !argValues.includes(quickFilter)) {
        //Find the valid quickfilter properties for the current node type
        const fieldFiltersType = getFieldFiltersType(this.props.field)
        const validProperties = getFilterProperties(
          fieldFiltersType,
          this.props.types
        )
        const fields = [
          'name',
          'srn',
          'tagSet',
          'key',
          'value',
        ].filter(propName =>
          validProperties.find(propDef => propDef.get('name') === propName)
        )

        //Add the quick filters
        const values = fields.map(field => {
          const id = uuid()
          return {
            fieldId: this.props.field.get('id'),
            id,
            opGroup: 'or',
            value: {
              [field]: {
                caseSensitive: false,
                dateOffset: null,
                op: 'CONTAINS',
                noCsv: false,
                value: quickFilter,
                id,
              },
            },
          }
        })
        this.props.addArgument(values)
      }
      this.setState({
        quickFilter: '',
      })
    }
  }

  render() {
    const { field, types, removeArgument } = this.props
    const orArgs = this.getOrArgs()
    const andArgs = this.getAndArgs()
    const displayColumns = field.getIn(['displayFields'], List())
    let itemType = this.props.field.get('name')

    try {
      itemType = getItemsType(this.props.field, this.props.types)
    } catch (e) {
      //eslint-disable-next-line no-console
      console.warn('Incorrect filter mapping')
    }

    const newFieldDefinition = this.props.types.get(itemType)
    const defaultColumns = getDefaultColumnsForField(
      newFieldDefinition,
      itemType,
      this.props.types
    )

    //Sometimes nodetypes allow selection of properties that cannot be filtered on in the where clause,
    //so the display fields list can be different from the filter proerties list
    const allowedItemsProperties = newFieldDefinition
      .get('fields')
      .filter(inputField => !displayColumns.includes(inputField.get('name')))

    return (
      <div>
        <div style={this.styles.nameInputWrapper}>
          <InlineEditableText
            activeNode
            value={this.props.title}
            onSubmit={this.props.onChangeTitle}
            style={this.styles.name}
          />
          <SearchCardComments
            hasCommentsHidden={this.props.hasCommentsHidden}
            toggleAddComment={() =>
              this.setState(state => ({
                showAddComment: !state.showAddComment,
              }))
            }
            toggle={this.state.showAddComment}
            removeComment={this.props.removeComment}
            addComment={this.props.addComment}
            field={field}
          />
        </div>
        <div>
          <div style={this.styles.filterHeader}>
            {orArgs.isEmpty() && andArgs.isEmpty() ? (
              <em>
                <FormattedMessage {...messages.all} />
                &nbsp;
                <DynamicFormattedMessage
                  defaultMessage={_.startCase(itemType)}
                  {...globalMessages.pluralFields[itemType]}
                />
              </em>
            ) : (
              <span style={this.styles.filterHeaderText}>filters</span>
            )}
            <Popover
              isOpen={this.state.showAddPopover}
              onToggle={this.onAddButtonToggle}
              position="right-start"
            >
              <PopoverAnchor>
                <TextLink onClick={this.toggleAddPopover} color="primary">
                  <Icon name="plus" fa />
                  &nbsp;Add Filter
                </TextLink>
              </PopoverAnchor>
              <PopoverBody>
                <EditFilter
                  addNew
                  onSubmit={this.onAddArgument}
                  types={types}
                  field={field}
                  removeArgument={removeArgument}
                  toggle={this.toggleAddPopover}
                />
              </PopoverBody>
            </Popover>
          </div>
          <div
            style={{
              paddingBottom: '8px',
            }}
          >
            <SearchInput
              quickFilter
              placeholder="Quick Add Filter..."
              onKeyPress={this.handleQuickFilter}
              value={this.state.quickFilter}
              onChange={({ target: { value } }) =>
                this.setState({ quickFilter: value })
              }
            />
          </div>

          {this.renderFilters(orArgs, andArgs)}
        </div>
        <div style={this.styles.filterContainer}>
          <div style={this.styles.filterHeader}>
            <span style={this.styles.filterHeaderText}>display columns</span>
            <Popover
              isOpen={this.state.showPropertyPopover}
              position="right-start"
            >
              <PopoverAnchor>
                <TextLink onClick={this.showPropertyPopover} color="primary">
                  <Icon name="plus" fa />
                  &nbsp;Add Column
                </TextLink>
              </PopoverAnchor>
              <PopoverBody>
                <EditProperty
                  displayColumns={displayColumns}
                  onSubmit={this.onAddProperty}
                  types={types}
                  field={field}
                  toggle={this.showPropertyPopover}
                  toggleDefaultProperty={this.toggleDefaultProperty}
                  defaultColumns={defaultColumns}
                  scalerFieldsFiltered={allowedItemsProperties}
                />
              </PopoverBody>
            </Popover>
          </div>
          <PropertySelect
            removeProperty={this.removeProperty}
            field={field}
            displayColumns={displayColumns}
            toggleDefaultProperty={this.toggleDefaultProperty}
            defaultColumns={defaultColumns}
            scalerFieldsFiltered={allowedItemsProperties}
            setQueryPropertyOrder={this.props.setQueryPropertyOrder}
            setQueryColumnAlias={this.props.setQueryColumnAlias}
            removeQueryColumnAlias={this.props.removeQueryColumnAlias}
          />
        </div>
      </div>
    )
  }
}

SearchCardBody.propTypes = {
  addArgument: PropTypes.func.isRequired,
  changeArgument: PropTypes.func,
  field: ImmutablePropTypes.contains({
    arguments: ImmutablePropTypes.map,
    id: PropTypes.string.isRequired,
    definition: ImmutablePropTypes.contains({
      name: PropTypes.string,
      type: ImmutablePropTypes.contains({
        name: PropTypes.string,
      }).isRequired,
      args: ImmutablePropTypes.list,
    }),
  }),
  moveArgument: PropTypes.func.isRequired,
  removeArgument: PropTypes.func,
  onChangeTitle: PropTypes.func.isRequired,
  title: PropTypes.string,
  theme: themeShape,
  types: ImmutablePropTypes.contains({
    inputFields: ImmutablePropTypes.listOf(
      ImmutablePropTypes.contains({
        name: PropTypes.string,
        type: ImmutablePropTypes.contains({
          name: PropTypes.string,
        }),
      })
    ),
  }),
  addProperty: PropTypes.func,
  removeProperty: PropTypes.func,
  toggleDefaultProperty: PropTypes.func,
  addComment: PropTypes.func,
  removeComment: PropTypes.func,
  hasCommentsHidden: PropTypes.bool,
  setQueryPropertyOrder: PropTypes.func,
  setQueryColumnAlias: PropTypes.func,
  removeQueryColumnAlias: PropTypes.func,
}

export default themeable(SearchCardBody)
