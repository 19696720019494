import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import SearchInput from 'components/SearchInput'

export class FilterInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      searchFilter: '',
      searchFilterInput: '',
    }
    this.setFilterText = _.debounce(this.setFilterText, 500)
  }

  setFilterInputText = e => {
    const value = e.target.value

    this.setState({
      searchFilterInput: value,
    })

    this.setFilterText(value)
  }

  setFilterText = value => {
    this.props.onChange(value)
  }

  render() {
    return (
      <SearchInput
        value={this.state.searchFilterInput}
        onChange={this.setFilterInputText}
        placeholder={this.props.placeholder}
        autoFocus
      />
    )
  }
}

FilterInput.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
}

export default FilterInput
