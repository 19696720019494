import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'

import DateTime from './DateTime'
import StringOperator from './StringOperator'
import BooleanOperator from './BooleanOperator'
import IntOperator from './IntOperator'
import SelectOperator from './SelectOperator'
import FilterFieldErrorBoundry from './FilterFieldErrorBoundry'
import AccountOperator from './AccountOperator'
import SwimlaneOperator from './SwimlaneOperator'

class QueryCardFieldFilter extends React.Component {
  getArgumentValue = () => {
    return Map({
      value: '',
      op:
        this.props.inputType === 'StringOperator' &&
        this.props.fieldName !== 'account' &&
        this.props.fieldName !== 'swimlaneSRNs' &&
        this.props.filterFieldType !== 'EffectivePermissionFilterType'
          ? 'CONTAINS'
          : 'EQ',
      caseSensitive: false,
      dateOffset: null,
      noCsv: false,
    }).merge(this.props.argumentValue)
  }

  onFilterChange = newVals => {
    if (Array.isArray(newVals)) {
      const newVal = { value: newVals }
      this.props.onChange(this.getArgumentValue().merge(newVal))
    } else {
      this.props.onChange(this.getArgumentValue().merge(newVals))
    }
  }

  getInputType = () => {
    return this.props.types.get(this.props.inputType, Map())
  }

  getFilterComponent = () => {
    if (this.props.fieldName === 'account') {
      return AccountOperator
    }

    if (this.props.fieldName === 'swimlaneSRNs') {
      return SwimlaneOperator
    }

    switch (this.props.inputType) {
      case 'DateTimeOperator':
        return DateTime

      case 'StringOperator':
        return StringOperator

      case 'BooleanOperator':
        return BooleanOperator

      case 'LongOperator':
      case 'IntOperator':
        return IntOperator

      default:
        return SelectOperator
    }
  }

  render() {
    const inputFields = this.getInputType().get('inputFields', Map())
    const opDefinition = inputFields.find(
      f => f.get('name') === 'op',
      null,
      Map()
    )

    const opType = this.props.types.get(
      opDefinition.getIn(['type', 'name']),
      Map()
    )

    const valueDefinition = inputFields.find(
      f => f.get('name') === 'value',
      null,
      Map()
    )

    const valueType = this.props.types.get(
      valueDefinition.getIn(['type', 'name']),
      Map()
    )

    const FieldFilter = this.getFilterComponent()

    return (
      <FilterFieldErrorBoundry>
        <FieldFilter
          isMulti={this.props.supportedFilters.includes('values')}
          onChange={this.onFilterChange}
          removeArgument={this.props.removeArgument}
          argumentValue={this.getArgumentValue()}
          opType={opType}
          valueType={valueType}
        />
      </FilterFieldErrorBoundry>
    )
  }
}

QueryCardFieldFilter.defaultProps = {
  argumentValue: Map(),
}

QueryCardFieldFilter.propTypes = {
  argumentValue: ImmutablePropTypes.map.isRequired,
  onChange: PropTypes.func.isRequired,
  inputType: PropTypes.string.isRequired,
  fieldName: PropTypes.string,
  removeArgument: PropTypes.func.isRequired,
  types: ImmutablePropTypes.mapOf(
    ImmutablePropTypes.contains({
      name: PropTypes.string,
      enumValues: ImmutablePropTypes.listOf(
        ImmutablePropTypes.contains({ name: PropTypes.string })
      ),
      inputFields: ImmutablePropTypes.listOf(
        ImmutablePropTypes.contains({
          name: PropTypes.string,
          type: ImmutablePropTypes.contains({
            name: PropTypes.string,
          }),
        })
      ),
    })
  ),
  filterFieldType: PropTypes.string,
  supportedFilters: ImmutablePropTypes.iterable.isRequired,
}

export default QueryCardFieldFilter
