import React from 'react'
import PropTypes from 'prop-types'
import BorderlessButton from 'components/BorderlessButton'
import Icon from 'components/Icon'

const styles = {
  headerTitle: {
    color: '#aaa',
  },
  headerTitleContainer: {
    alignItems: 'end',
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    position: 'absolute',
    top: '-25px',
    left: 0,
    right: 0,
  },
  topButtonContainer: {
    textAlign: 'right',
  },
}

class SearchCardHeader extends React.Component {
  render() {
    const { fieldTitle, removeField } = this.props

    return (
      <div style={styles.headerTitleContainer}>
        <span style={styles.headerTitle}>{fieldTitle}</span>
        <div style={styles.topButtonContainer}>
          <BorderlessButton onClick={removeField} title="Remove Card">
            <Icon name="times" fa transform="shrink-2" />
          </BorderlessButton>
        </div>
      </div>
    )
  }
}

SearchCardHeader.propTypes = {
  fieldTitle: PropTypes.node,
  removeField: PropTypes.func,
}

export default SearchCardHeader
