import React from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap'
import BorderlessButton from 'components/BorderlessButton'
import Icon from 'components/Icon'
import TextLink from 'components/TextLink'
import Button from 'components/Button'
import _ from 'lodash'
import Color from 'color'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import Autosuggest from 'react-autosuggest'
import FormLabel from 'components/FormLabel'
import ImmutablePropTypes from 'react-immutable-proptypes'

export class AddTagsModal extends React.Component {
  styles = {
    removeButton: {
      marginLeft: '0.5em',
    },
    tag: {
      display: 'inline-flex',
      margin: '0.5rem',
      alignContent: 'center',
      maxWidth: '210px',
    },
    key: {
      padding: '0.10rem 0.7rem',
      borderTopLeftRadius: '1rem',
      borderBottomLeftRadius: '1rem',
      textOverflow: 'ellipsis',
      width: '105px',
      overflow: 'hidden',
      backgroundColor: Color(this.props.theme.secondary)
        .blacken(0.5)
        .hex(),
      color: this.props.theme.light,
      height: '30px',
    },
    val: {
      padding: '0.10rem 0.5rem',
      borderTopRightRadius: '1.2rem',
      borderBottomRightRadius: '1.2rem',
      backgroundColor: this.props.theme.neutralLight,
      color: this.props.theme.dark,
      textOverflow: 'ellipsis',
      height: '30px',
      width: '105px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    tagText: {
      fontSize: '0.9rem',
      maxWidth: '10rem',
      width: 'auto',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    inputWrapper: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 80px',
      gridTemplateRows: 'auto',
      gridColumnGap: '0.5em',
      gridRowGap: '0.5em',
      padding: '1em 0.5em',
    },
    inputLabel: {
      display: 'flex',
      alignItems: 'center',
      margin: 0,
    },
    actionButtons: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
  }

  state = {
    tags: [],
    suggestions: [],
    key: '',
    value: '',
  }

  handleChange = ({ key, value }) => {
    const { tags } = this.state
    const keys = tags.map(tag => tag.key)
    if (keys.includes(key)) {
      let updatedState = [...tags]
      updatedState.splice(keys.indexOf(key), 1)
      this.setState({
        tags: updatedState,
      })
    } else {
      this.setState(oldState => ({
        tags: [...oldState.tags, { key, value }],
        key: '',
        value: '',
      }))
    }
  }

  onCancel = () => {
    this.setState({ tags: [] })
    this.props.onCancel()
  }

  onSave = () => {
    this.props.onSave(this.state.tags)
  }

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    })
  }

  getSuggestions = value => {
    const inputValue =
      typeof value === 'string' ? value.trim().toLowerCase() : value

    if (inputValue.length === 0) {
      return []
    }

    return this.props.tagOptions
      .map(tagObj => decodeURIComponent(tagObj.get('key')) || '')
      .filter(tag => tag.toLowerCase().includes(inputValue))
      .toJS()
  }

  getSuggestionValue = suggestion => suggestion

  renderSuggestion = suggestion => <div>{suggestion}</div>

  onChangeAutoSuggest = (event, { newValue }) => {
    this.setState({
      key: newValue,
    })
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    })
  }

  onChangeValue = event => {
    this.setState({
      value: event.target.value,
    })
  }

  handleKeyPress = e => {
    const { key, value } = this.state
    if (key.trim() !== '' && value.trim() !== '') {
      if (e.key === 'Enter') {
        this.handleChange({
          key: this.state.key,
          value: this.state.value,
        })
      }
    }
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen}>
        <ModalHeader>Add Tag(s)</ModalHeader>
        <ModalBody>
          <div style={this.styles.inputWrapper}>
            <div>
              <FormLabel required style={this.styles.inputLabel}>
                Key
              </FormLabel>
              <Autosuggest
                suggestions={this.state.suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={this.renderSuggestion}
                inputProps={{
                  value: this.state.key,
                  onChange: this.onChangeAutoSuggest,
                  onKeyPress: this.handleKeyPress,
                }}
              />
            </div>
            <div>
              <FormLabel required style={this.styles.inputLabel}>
                Value
              </FormLabel>
              <Input
                onKeyPress={this.handleKeyPress}
                onChange={this.onChangeValue}
                value={this.state.value}
                readOnly={this.state.key.trim() === ''}
              />
            </div>
            <div style={{ alignSelf: 'flex-end' }}>
              <Button
                disabled={
                  this.state.key.trim() === '' || this.state.value.trim() === ''
                }
                style={{ width: '100%' }}
                onClick={() =>
                  this.handleChange({
                    key: this.state.key,
                    value: this.state.value,
                  })
                }
                color="primary"
              >
                Add
              </Button>
            </div>
          </div>

          {!_.isEmpty(this.state.tags) &&
            this.state.tags.map((tag, i) => (
              <div key={i} style={this.styles.tag}>
                <div style={this.styles.key}>
                  <span title={tag.key} style={this.styles.tagText}>
                    {tag.key}
                  </span>
                </div>
                <div style={this.styles.val}>
                  <span title={tag.value} style={this.styles.tagText}>
                    {tag.value}
                  </span>
                  <BorderlessButton
                    style={this.styles.removeButton}
                    onClick={() => this.handleChange(tag)}
                  >
                    <Icon fa name="times" transform="shrink-4" />
                  </BorderlessButton>
                </div>
              </div>
            ))}
        </ModalBody>
        <ModalFooter>
          <TextLink color="secondary" onClick={this.onCancel}>
            Cancel
          </TextLink>
          <Button
            onClick={this.onSave}
            color="primary"
            disabled={_.isEmpty(this.state.tags)}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

AddTagsModal.propTypes = {
  theme: themeShape,
  isOpen: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  tagOptions: ImmutablePropTypes.list,
}

export default themeable(AddTagsModal)
