import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { injectIntl, intlShape } from 'react-intl'

import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import Hoverable from 'components/Hoverable'
import CloudBadge from 'components/CloudBadge'
import Icon from 'components/Icon'
import BorderlessButton from 'components/BorderlessButton'
import { getIconMapping } from 'utils/sonraiUtils'

import messages from './messages.js'

export const SubType = ({
  subtype,
  gotoResults,
  gotoBuilder,
  theme,
  nodeType,
  intl,
}) => {
  const styles = {
    subType: {
      display: 'inline-grid',
      gridTemplateColumns: 'auto 1fr 30px',
      border: `1px solid ${theme.neutralLight}`,
      padding: '0.3em 0.5em',
      backgroundColor: theme.light,
      alignContent: 'center',
      height: '35px',
      minHeight: '35px',
      overflow: 'hidden',
      margin: '0.1em 0.3em',
      gridColumnGap: '0.3em',
      alignItems: 'center',
    },
    hovered: {
      backgroundColor: theme.highlight,
    },
  }

  return (
    <Hoverable
      style={styles.subType}
      hoverStyle={{ ...styles.subType, ...styles.hovered }}
      onClick={() => gotoResults(subtype)}
      renderContent={({ hovered }) => (
        <Fragment>
          {subtype.cloud ? (
            <CloudBadge type={subtype.cloud} />
          ) : (
            <Icon fa name={getIconMapping(nodeType).faIcon} />
          )}
          {subtype.defaultDisplayName}

          {hovered ? (
            <BorderlessButton
              onClick={e => {
                e.stopPropagation()
                gotoBuilder(subtype)
              }}
              title={intl.formatMessage(messages.openInBuilder)}
            >
              <Icon fa name="tools" />
            </BorderlessButton>
          ) : (
            <span style={{ height: '24px' }} />
          )}
        </Fragment>
      )}
    />
  )
}

SubType.propTypes = {
  intl: intlShape,
  gotoBuilder: PropTypes.func.isRequired,
  gotoResults: PropTypes.func.isRequired,
  nodeType: PropTypes.string,
  subtype: PropTypes.shape({
    defaultDisplayName: PropTypes.string,
    cloud: PropTypes.string,
  }),
  theme: themeShape,
}

export default injectIntl(themeable(SubType))
